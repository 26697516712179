<template>
  <popup @close="$emit('close')">
    <p class="description">{{desc}}</p>
  </popup>
</template>

<script>
  import Popup from './popup';

  export default {
    props: ['desc'],
    components: { Popup },
    data() {
      return {
        phoneNumber: ''
      };
    },
  }
</script>

<style scoped lang="scss">
  @import "../../stylesheets/airspot";

  .description {
    font-family: $jalnan;
    font-size: 18px;
    letter-spacing: 0;
    text-align: center;
    color: $green;
    margin-top: 44px;
    margin-bottom: 0;
  }
</style>