<template>
  <div class="guide">
    <div class="title">
      <h1>{{ $t("guide.title") }}</h1>
    </div>

    <hr/>

    <h2>{{ $t('guide.subTitle1') }}</h2>
    <h2 class="lighter">{{ $t('guide.desc1') }}</h2>

    <img :src="require('images/guide/imgGuide01@2x.png')"/>

    <h2>{{ $t('guide.subTitle2') }}</h2>
    <h2 class="lighter">{{ $t('guide.desc2') }}</h2>

    <img :src="require('images/guide/imgGuide02@2x.png')"/>

    <h2>{{ $t('guide.subTitle3') }}</h2>
    <h2 class="lighter">{{ $t('guide.desc3') }}</h2>

    <img :src="require('images/guide/imgGuide03@2x.png')"/>

    <h2>{{ $t('guide.subTitle4') }}</h2>
    <h2 class="lighter">{{ $t('guide.desc4') }}</h2>

    <img :src="require('images/guide/imgGuide04@2x.png')"/>
  </div>
</template>

<script>
  import daumtools from 'lib/daumtools';

  export default {
    mounted() {
      let title = this.$t('guide.title');
      daumtools.sendDataToMobileApp(title);
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/common";

  .guide {
    text-align: center;
    font-family: 'Nanum Barun Gothic', sans-serif;

    h1 {
      text-align: center;
      font-weight: normal;
      font-size: 1.0rem;
    }

    hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #f2f2ee;
      margin: 2rem 0;
      padding: 0;
    }

    h2 {
      text-align: center;
      font-weight: normal;
      font-size: 0.9rem;

      color: #333;
      max-width: 560px;
      margin: 0 auto;
      @include respond-to(mobile) {
        text-align: left;
      }

      &.lighter {
        font-weight: lighter;
      }
    }

    img {
      margin: 20px auto 50px auto;
      max-width: 100%;

      &:last-child {
        margin-bottom: 15px;
      }
    }

    @include respond-to(mobile) {
      text-align: left;
    }
  }
</style>