<template>
  <button class="box-button" @click.prevent="$emit('click', number)">
    {{text}}
  </button>
</template>

<style scoped lang="scss">
  @import "../stylesheets/application";

  .box-button {
    background-color: $bb_ivory3;
    width: $box-button-width;
    height: 60px;
    border: 1px solid $bb_green;
    border-radius: 8px;
    display: inline-block;

    &:hover {
      background-color: rgba(45, 162, 82, 0.2);
      color: $bb_green;
    }
    &:active {
      background-color: rgba(45, 162, 82, 0.2);
      color: $bb_green;
    }
    &:focus {
      background-color: rgba(45, 162, 82, 0.2);
      color: $bb_green;
    }

    margin: 0 0 16px 16px;

    &:nth-child(2n+1) {
      margin-left: 0;
    }

    @include respond-to(tablet) {
      h1 {
        font-size: 1.2rem;
      }
      margin: 5px 0;
      .btn-text {
        font-size: 1rem;
      }
    }
    @include respond-to(mobile) {
      margin: 5px auto;
      display: block;
      width: 100%;
      h1 {
        font-size: 1.2rem;
      }

      .btn-text {
        font-size: 1rem;
      }
    }
  }
</style>

<script>
  export default {
    props: ['number', 'text'],
    data() {
      return {
        missionCompleted: false,
      }
    },
    mounted() {
    }
  }
</script>

