<template>
  <popup :show-confirm="true" @confirm="onConfirm" @close="$emit('close')">
    <p class="description">{{ desc }}</p>
    <input class="number-input" type="tel" placeholder="010-9999-9999"
           v-model="phoneNumber"
           ref="numberInput"
    >
    <toast ref="toast"></toast>
  </popup>
</template>

<script>
  import Popup from './popup';
  import Toast from './toast';

  export default {
    props: ['desc'],
    components: { Popup, Toast },
    data() {
      return {
        phoneNumber: ''
      };
    },
    methods: {
      onConfirm() {
        if (this.phoneNumber) {
          this.$emit('confirm', this.phoneNumber)
        } else {
          this.$refs.toast.show('번호를 입력하세요.');
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../stylesheets/airspot";

  .description {
    font-family: $s-core-6;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: -0.3px;
    text-align: center;
    color: $gray2;
    margin-top: 6px;
    margin-bottom: 0;
    white-space: pre-line;
  }

  .number-input {
    display: block;
    width: 263px;
    height: 47px;
    border: 1px solid $green;
    border-radius: 2px;
    margin: 13px auto 32px;
    padding: 4px 0 0 32.5px;
    line-height: 47px;
    font-size: 26px;
    letter-spacing: 1.3px;
    box-sizing: border-box;
    color: $green;
    caret-color: $green;

    @include placeholder {
      color: $gray;
    }
  }
</style>