<template>
  <div id="contact">
    <global-nav-header/>
    <div>
      <table class="contact-table">
        <tr>
          <td class="header hide-on-mobile">
            인재 영입
          </td>
          <td>
            <div class="mobile-header hide-on-pc" style="padding-bottom: 3px;">인재 영입</div>
            <div class="title hide-on-mobile">Careers</div>
            <div class="desc" style="margin-top:14px;">
              어떤 아이디어라도 적극적으로 커뮤니케이션 합니다.<br/>
              <div class="hide-on-pc" style="height:10px;"></div>
              새로운 기술에 대한 끝없는 호기심으로 남들이 가지 않은 길에 주저없이 도전합니다.<br/>
              <div class="hide-on-pc" style="height:10px;"></div>
              블록체인 기술이 바꿀 세상에 대한 긍정적인 기대와 이에 이바지 하겠다는 강한 의지를 가집니다.
            </div>
              <a class="recruiting-btn" href="https://www.rocketpunch.com/companies/rootone" target="_blank">
                채용 중인 분야 보러가기
              </a>
            <div class="divider"></div>
          </td>
        </tr>

        <tr>
          <td class="header hide-on-mobile">
            제휴 문의
          </td>
          <td>
            <div class="mobile-header hide-on-pc">제휴 문의</div>
            <div class="title hide-on-mobile">Partnership</div>
            <div class="with-btns">
              <a class="with-btn" href="mailto:with@rootone.com" target="_blank">
                제휴 문의
              </a>
              <a class="with-btn" style="margin-left:14px;" href="http://bit.ly/token_listing" target="_blank">
                리스팅 문의
              </a>
            </div>
            <div class="divider"></div>
          </td>
        </tr>

        <tr>
          <td class="header hide-on-mobile">
            고객센터
          </td>
          <td>
            <div class="mobile-header hide-on-pc" style="padding-bottom: 16px;">고객센터</div>
            <div class="desc">
              비트베리 서비스 관련 문의가 있으신가요? <br/>
              단순 기능 문의는
              <a href="/app_pages/faqs" style="color:#333333;font-weight:normal;text-decoration: underline;" target="_blank">자주 묻는 질문</a>이 더 빠를거에요 (비트베리 앱 > 내 정보 > FAQ에서 확인
              가능합니다). <br/>
              자주 묻는 질문에 없는 내용은 이메일 문의주세요.
            </div>

            <a class="email-btn" href="mailto:help@rootone.com" target="_blank">이메일 문의하기</a>
          </td>
        </tr>
      </table>
    </div>
    <home-footer/>
  </div>
</template>

<script>
  import GlobalNavHeader from "./global_nav_header";
  import HomeFooter from "./home_footer";

  export default {
    components: {GlobalNavHeader, HomeFooter},
    data() {
      return {}
    },
    mounted() {
    },
  }
</script>

<style lang="scss">
  @import '../stylesheets/common';
  @import '../stylesheets/home';

  @mixin respond-to($media) {
    @if $media == tablet {
      @media only screen and (max-width: 1024px) { @content; }
    } @else if $media == mobile {
      @media only screen and (max-width: 720px) { @content; }
    }
  }

  @mixin desktop-page {
    @media only screen and (min-width: 721px) {
      @content;
    }
  }

  @include desktop-page {
    .hide-on-pc {
      display: none !important;
    }
  }
  @include respond-to(mobile) {
    .hide-on-mobile {
      display: none !important;
    }
  }

  $black2: #333333;
  $green: #2da252;

  #contact {
    font-family: 'Nanum Barun Gothic', sans-serif;

    h1 {
      text-align: center;
    }

    .contact-table {
      padding-top: 15px;
      padding-bottom: 84px;
      max-width: 1284px;
      width: 100%;
      margin: 0 auto;

      @include respond-to(mobile) {
        margin-top: 60px;
        padding: 0 30px 60px;
      }

      td {
        padding-top: 70px;

        @include  respond-to(mobile) {
          padding-top: 40px;
        }
      }

      .mobile-header {
        font-size: 20px;
        color: $black2;
        font-weight: bold;
      }

      .header {
        font-size: 21px;
        font-weight: bold;
        color: #333333;
        vertical-align: top;
        width: 20%;
      }

      .title {
        font-weight: bold;
        font-size: 20px;
        color: $black2;
      }

      .desc {
        font-weight: lighter;
        font-size: 16px;
        color: #555555;
        line-height: 1.63;
      }

      .btn {
        display: inline-block;
        text-align: center;
        border: 1px solid $black2;
        font-size: 18px;
        color: $black2;
        padding: 15px;
      }

      .recruiting-btn {
        display: inline-block;
        text-align: center;
        border: 1px solid $black2;
        font-size: 17px;
        color: $black2;
        width: 230px;
        height: 45px;
        line-height: 46px;
        margin-top:28px;
        font-weight: 300;

        @include respond-to(mobile) {
          width: 224px;
          height: 40px;
          line-height: 40px;
          margin-top: 24px;
          font-size: 15px;
        }

        &:hover {
          background-color: #333333;
          cursor: pointer;
          color: white;
        }
      }

      .with-btns {
        display: flex;
        flex-direction: row;

        .with-btn {
          display: inline-block;
          text-align: center;
          border: 1px solid $black2;
          font-size: 17px;
          color: $black2;
          width:140px;
          height: 45px;
          line-height: 46px;
          margin-top:28px;
          font-weight: 300;

          @include respond-to(mobile) {
            /*width: 130px;*/
            flex-grow: 1;
            width: 100%;
            height: 40px;
            line-height: 40px;
            margin-top: 24px;
            font-size: 15px;
          }

          &:hover {
            background-color: #333333;
            cursor: pointer;
            color: white;
          }
        }
      }

      .email-btn {
        display: inline-block;
        text-align: center;
        border: 1px solid $black2;
        font-size: 17px;
        color: $black2;
        width:170px;
        height: 45px;
        line-height: 46px;
        margin-top:28px;
        font-weight: 300;

        @include respond-to(mobile) {
          width: 178px;
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          margin-top: 24px;
        }

        &:hover {
          background-color: #333333;
          cursor: pointer;
          color: white;
        }
      }

      .divider {
        margin-top:55px;
        margin-right: 10px;
        height:1px;
        background-color:#e1e1de;
        max-width: 820px;

        @include respond-to(mobile) {
          margin-top: 40px;
          margin-right: 0;
        }
      }
    }
  }
</style>