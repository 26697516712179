<template>
  <div id="airspot-event">
    <div class="top-nav">
      <img src="../images/airspot/img-airspot-title@2x.png" alt="에어스팟">
    </div>

    <div class="box">
      <p class="title">{{initialEvent.name}}</p>
      <p class="top-desc">
        <span>{{ $t('keypad.desc1') }}</span><br>
        <span>{{ $t('keypad.desc2') }} <span class="green-font">{{ $t('keypad.bitberry') }}</span>{{ $t('keypad.desc3') }}</span>
      </p>

      <div class="screen">
        <p class="phone-number" v-bind:class="{'initial': initial}">
          {{formattedPhoneNumber}}
        </p>
        <p class="agreement-text">
          {{ $t('keypad.agreement') }}
        </p>
      </div>

      <div class="keypad">
        <button @mousedown.prevent="pushNumber" @touchstart.prevent="pushNumber"
                v-for="n in [1,2,3,4,5,6,7,8,9,'+',0]" :data-id="n" v-html="n"></button>
        <button class="eraser" @mousedown.prevent="popNumber" @touchstart.prevent="popNumber"><img
          src="../images/airspot/ic-delete@2x.png" alt="지우개"></button>
      </div>

      <button class="airdrop-button" v-bind:class="{'can-submit': canSubmit}" @click.prevent="submit"
              @touchstart.prevent="submit">
        {{ $t('keypad.receive', {amount: amount, currencyCode: currencyCode}) }}
      </button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    props: ['currencyCode', 'amount', 'airspotUserPath', 'initialEvent', 'lang'],
    mounted() {
      // if (window.matchMedia("(orientation: portrait)").matches) {
      //   document.body.addEventListener('touchmove', function (e) {
      //     e.preventDefault();
      //   }, {passive: false});
      // }
    },
    data() {
      return {
        phoneNumber: '',
        initial: true
      }
    },
    computed: {
      formattedPhoneNumber() {
        if (this.phoneNumber === '') {
          return '010-0000-0000';
        } else {
          return this.phoneNumber;
        }
      },
      canSubmit() {
        return this.phoneNumber.length > 8;
      }
    },
    methods: {
      submit() {
        let url = this.airspotUserPath;
        console.log(url);
        axios.post(url, {airspot_user: {phone_number: this.phoneNumber}})
          .then(function (response) {
            alert("적립 완료!\n문자 메시지 또는 비트베리 앱 내 알림을 통해 내역을 확인하세요.");
          }).catch(function (error) {
          alert(error.response.data.message);
        });
        this.resetNumber();
      },
      pushNumber(event) {
        let n = event.target.dataset.id;
        if (n === '&nbsp') {
          return;
        }

        this.phoneNumber = this.phoneFormatter(this.phoneNumber.concat(n));
        if (this.phoneNumber.length > 0) {
          this.initial = false;
        }
      },
      popNumber() {
        this.phoneNumber = this.phoneFormatter(this.phoneNumber.slice(0, -1));
        if (this.phoneNumber.length === 0) {
          this.initial = true;
        }
      },
      resetNumber() {
        this.phoneNumber = '';
        this.initial = true;
      },
      phoneFormatter(phoneNumber) {
        let num = phoneNumber.replace(/-/g, '');
        let formatNum = '';

        if (num.length === 11) {
          formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        } else if (num.length === 8) {
          formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
        } else {
          if (num.indexOf('02') === 0) {
            formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
          } else {
            formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
          }
        }
        return formatNum;
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/common";
  @import "../stylesheets/airspot";

  $keypadBorderColor: #e1e1de;

  #airspot-event {
    .top-nav {
      height: 64px;
      background-color: white;
      margin: 0;
      border-bottom: solid 1px #e1e1de;

      img {
        display: block;
        width: 184px;
        height: 32px;
        margin: 0 auto 16px auto;
        padding: 16px;
      }
    }

    .box {
      font-family: "S-CoreDream-4Regular", sans-serif;
      font-size: 18px;
      font-weight: lighter;
      line-height: 28px;
      max-width: 560px;
      margin: 40px auto 0 auto;

      .title {
        text-align: center;
      }

      .top-desc {
        text-align: center;

        .green-font {
          color: #2da252;
        }
      }

      .screen {
        max-width: 560px;
        height: 150px;
        margin: 30px auto 0 auto;
        border: solid 1px #2da252;
        padding: 16px;
        text-align: center;
        background-color: white;

        .phone-number {
          font-family: "S-CoreDream-4Regular", sans-serif;
          font-size: 40px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #333333;
          border: none;

          &.initial {
            color: #e1e1de;
            font-family: "S-CoreDream-3Light", sans-serif;
          }
        }

        p {
          &.agreement-text {
            height: 15px;
            font-family: "S-CoreDream-4Regular", sans-serif;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.1px;
            text-align: center;
            color: #b6b6b6;
          }
        }
      }

      .keypad {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 560px;
        grid-gap: 0;
        border: solid 1px $keypadBorderColor;

        button {
          height: 80px;
          background-color: white;
          font-family: "S-CoreDream-3Light", sans-serif;
          border: none;
          margin: 0;
          padding: 0;
          border-bottom: solid 1px $keypadBorderColor;
          font-size: 32px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #333333;

          &:nth-child(n+1) {
            border-left: none;
          }

          &:nth-child(3n+1) {
            border-right: solid 1px $keypadBorderColor;
          }

          &:nth-child(3n+2) {
            border-right: solid 1px $keypadBorderColor;
          }

          &:nth-last-child(-n+3) {
            border-bottom: none;
          }

          &:hover {
            background-color: rgba(45, 162, 82, 0.05);
          }

          /*&:active {*/
          /*transform: translateY(3px);*/
          /*}*/

          &:focus {
            outline: none;
          }

          &.eraser > img {
            margin: 0 auto;
            text-align: center;
            width: 32px;
            height: 32px;
          }
        }
      }

      .airdrop-button {
        width: 100%;
        height: 80px;
        background-color: #e1e1de;

        font-family: "S-CoreDream-4Regular", sans-serif;
        font-size: 28px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #979797;
      }

      .can-submit {
        font-family: "S-CoreDream-4Regular", sans-serif;
        color: white;
        background-color: #2da252;

        &:active {
          transform: translateY(3px);
        }
      }
    }

  }
</style>