<template>
  <div class="faq-list-item">
    <div class="title-container">
      <span class="link-wrapper"><a :href="faq_path(faq)">{{ faq.title }}</a></span>
      <span class="date-item">{{ faq.created_at | human_date }}</span>
    </div>
    <img :src="require('images/notices/ic_arrow_right_big.svg')" class="faq-more-icon">
  </div>
</template>

<script>
  export default {
    props: ['faq'],
    methods: {
      faq_path: (faq) => {
        return `/app_pages/faqs/${faq.id}`;
      }
    }
  }
</script>
<style scoped lang="scss">
  @import '../../stylesheets/application/list_pages';

  .faq-list-item {
    @include list-item();
  }
</style>