<template>
  <div id="media-vue">
    <global-nav-header/>
    <div class="picture">
      <img class="picture-item-desktop-image" src="../images/home/im-media.png" alt="사무실에서 비트베리 팀원들"/>
    </div>

    <div class="desc-container">
      <div class="nav hide-on-mobile">
        <div class="nav-menu hide-on-mobile" id="rootone-menu" v-on:click="showArticles" :class="{ bold: menu === 'articles'}">Articles</div>
        <a class="nav-menu hide-on-mobile" id="bi-ci-menu"  href="https://medium.com/bitberry" target="_blank">Posts</a>
      </div>

      <div class="contents-container">
        <main>
          <div class="article" v-for="article in articles">
            <a :href="article.url" target="_blank" class="article-name" v-html="article.title"></a>
            <h5>{{article.media}} | {{article.date}}</h5>
          </div>
          <nav>
            <ul>
              <li>
                <img class="arrow" v-bind:style="[hasPrevPage() ? {'opacity':1.0} : {'opacity':0.3}]"
                     @mousedown.prevent="goToPrevPage" src="../images/home/ic-previous-page@3x.png">
              </li>
              <li v-for="pageNumber in currentPageSet">
                <a class="page-number"
                     :class="{ selected: parseInt(pageNumber) == parseInt(currentPage)}"
                     @mousedown.prevent="updateBitberryArticles"
                     :data-page="pageNumber" v-html="pageNumber">
                </a>
              </li>
              <li>
                <img class="arrow" v-bind:style="[hasNextPage() ? {'opacity':1.0} : {'opacity':0.3}]"
                     @mousedown.prevent="goToNextPage" src="../images/home/ic-next-page@3x.png">
              </li>
            </ul>
          </nav>
        </main>
        <div class="divider hide-on-pc"></div>
        <div class="post-container hide-on-pc">
          <h1>Posts</h1>
          <p>미디엄에서 다양한 비트베리 소식을 알려드립니다.</p>
          <a class="bitberry-story-btn" href="https://medium.com/bitberry" target="_blank">
            비트베리 소식 보러가기
            <img class="arrow-b" src="../images/home/ic-arrow-right@3x.png"/>
            <img class="arrow-w" src="../images/home/ic-arrow-right-w@3x.png"/>
          </a>
        </div>
      </div>
    </div>

    <home-footer/>
  </div>
</template>

<script>
  import GlobalNavHeader from "./global_nav_header.vue";
  import HomeFooter from "./home_footer";

  export default {
    props: ['initialMenu', 'initialCategory'],
    components: {GlobalNavHeader, HomeFooter},
    data() {
      return {
        menu: this.initialMenu,
        articles: null,
        per: 7,
        totalPages: null,
        maxPage: 5,
        nextPage: null,
        prevPage: null,
        currentPage: null,
        currentPageSet: null,
      }
    },
    mounted() {
      this.getBitberryArticles(1, this.per, this.initialCategory);
    },
    beforeUpdate() {
      let count = Math.ceil(this.totalPages / this.maxPage);
      let i, startNum = 1;

      for (i = 1; i <= count; i++) {
        let endNum = Math.min(i * this.maxPage, this.totalPages);
        if (startNum <= this.currentPage && this.currentPage <= endNum) {
          this.currentPageSet = [];
          for (startNum; startNum <= endNum; startNum++) {
            this.currentPageSet.push(startNum);
          }
          return;
        } else {
          startNum = endNum + 1;
        }
      }
    },
    methods: {
      hasPrevPage() {
        return (this.currentPageSet !== null) && (this.currentPage > this.maxPage);
      },
      hasNextPage() {
        return (this.currentPageSet !== null) && (this.currentPageSet[this.currentPageSet.length - 1] < this.totalPages);
      },
      goToPrevPage() {
        if (this.hasPrevPage()) {
          this.getBitberryArticles(this.currentPageSet[0] - this.maxPage, this.per, this.partner_category || 'all');
        }
      },
      goToNextPage() {
        if (this.hasNextPage()) {
          this.getBitberryArticles(this.currentPageSet[this.currentPageSet.length - 1] + 1, this.per, this.partner_category || 'all');
        }
      },
      showArticles() {
        window.location.href = "/home/media?menu=articles";
      },
      // showPosts() {
      //   window.location.href = "/home/media?menu=posts";
      // },
      getBitberryArticles(page, per, category) {
        let data = null;
        let that = this;
        axios.get('/bitberry_articles', {
          params: {
            page: page,
            per: per,
            category: category,
          }
        }).then(response => {
          let data = response.data;
          that.articles = data.articles;
          that.totalPages = data.total_pages;
          that.nextPage = data.next_page;
          that.prevPage = data.prev_page;
          that.currentPage = page;
        }).catch(error => {
          console.log(error);
        });
        return data;
      },
      updateBitberryArticles(event) {
        let page = event.target.dataset.page;
        this.getBitberryArticles(page, this.per, this.partner_category);
      },
    }
  }
</script>

<style lang="scss">
  @import '../stylesheets/common';
  @import '../stylesheets/home';

  #media-vue {
    margin-top: 57px;

    .picture {
      width: 100%;
      margin: 0 auto;
      max-width: $body-max-width;
      max-height: 700px;

      img {
        width: 100%;
      }
    }

    .mobile-tab {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-around;
        li {
          display: inline-block;
          vertical-align: center;
          font-family: "Nanum Barun Gothic", sans-serif;
          font-size: 16px;
          font-weight: bold;
          color: rgba(51, 51, 51, 0.3);
          margin: 0;
          list-style-type: none;
          width: 50%;
          height: 44px;
          line-height: 44px;
          text-align: center;
          border-bottom: solid 1px rgba(51, 51, 51, 0.3);
          cursor: pointer;

          &.active {
            color: rgb(51, 51, 51);
            border-bottom: solid 1px rgb(51, 51, 51);
          }
        }
      }
    }

    .desc-container {
      display: flex;
      margin: 0 auto;
      padding-top: 50px;
      max-width: $body-max-width;
      width: 100%;

      @include respond-to(mobile) {
        padding-top: 34px;
      }

      .nav {
        /*flex-grow: 1.2;*/
        /*vertical-align: top;*/
        width: 19%;
        padding-left: 6px;

        @include respond-to(mobile) {
          display: none;
        }

        .nav-menu {
          font-size: 18px;
          font-family: "Nanum Barun Gothic", sans-serif;
          line-height: 38px;
          color: $black2;
          cursor: pointer;
          font-weight: 300;
        }

        .nav-menu:hover {
          font-weight: bold;
        }

        .nav-menu:active {
          font-weight: bold;
        }

        .bold {
          font-weight: bold;
        }
      }

      .contents-container {
        /*flex-grow: 3;*/
        display: inline-block;
        vertical-align: top;
        /*padding: 7px 0 50px 10px;*/
        /*flex: 1;*/

        main {
          display: flex;
          position: relative;
          flex-direction: column;
          max-width: 808px;
          padding-left: 14px;
          padding-right: 14px;
          margin-bottom: 80px;

          @include respond-to(mobile) {
            /*margin: 0 10px;*/
            margin: 0 10px 30px 10px;
            display: block;
          }

          .article {
            margin-bottom: 48px;

            .article-name {
              text-decoration: none;
              font-family: "Nanum Barun Gothic", sans-serif;
              font-size: 20px;
              font-weight: bold;
              color: #333333;

              @include respond-to(mobile) {
                font-size: 18px;
              }
            }

            h5 {
              margin-top: 10px;
              color: #979797;
              font-size: 14px;
              font-weight: normal;
            }

            &:hover {
              padding-left: 5px;
              transition: all 0.25s;

              .article-name {
                color: $green;
              }
            }
          }

          nav {
            /*margin: 0 auto;*/
            margin-top: 10px;

            ul {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              @include respond-to(mobile) {
                justify-content: center;
              }

              li {
                list-style-type: none;

                a {
                  padding: 10px 15px;
                  &:hover {
                    cursor: pointer;
                  }
                }

                .selected {
                  color: green;
                  font-weight: bold;
                }
              }
            }
          }
          .arrow {
            width: 10px;
            height: 16px;
            padding: 10px 15px 13px 15px;
          }
        }

        @include respond-to(mobile) {
          padding-left: 0;
          padding-right: 0;
          padding-top: 0;
        }

        .divider {
          width: 100%;
          height: 10px;
          background-color: #f2f2ee;
        }

        .post-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 50px 30px;

          h1 {
            font-size: 18px;
            color: $black2;
            font-weight: bold;
            font-family: "Nanum Barun Gothic", sans-serif;
          }

          p {
            font-size: 18px;
            color: #555555;
            font-weight: 300;
            margin-top: 12px;
            margin-bottom: 20px;

            @include respond-to(mobile) {
              text-align: center;
            }
          }

          .bitberry-story-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $black2;
            width: 200px;
            height: 40px;
            padding-left: 16px;
            padding-right: 16px;
            float: left;
            color: $black2;
            font-size: 15px;
            line-height: 40px;
            text-align: left;

            .arrow-b {
              width: 13px;
              height: 12px;
            }

            .arrow-w {
              width: 13px;
              height: 12px;
              display: none;
            }

            &:hover {
              background-color: #333333;
              cursor: pointer;
              color: white;

              .arrow-b {
                display: none;
              }

              .arrow-w {
                display: inline;
              }
            }
          }
        }

        .rootone {
          @include respond-to(mobile) {
            padding-left: 20px;
            padding-right: 20px;
          }
        }

        .rootone-title {
          color: $black2;
          font-size: 24px;
          line-height: 1.42;
          font-weight: bold;

          @include respond-to(mobile) {
            font-size: 20px;
            line-height: 1.2;
          }
        }
        .content-title {
          font-size: 22px;
          color: $black2;
          margin-top: 40px;
          font-weight: bold;

          @include respond-to(mobile) {
            font-size: 16px;
            margin-top: 32px;
          }
        }
        .mission-desc {
          font-size: 16px;
          font-weight: lighter;
          color: #555555;
          line-height: 1.63;
          letter-spacing: -0.2px;
          margin-top: 12px;

          span {
            display: block;
          }

          @include respond-to(mobile) {
            font-size: 16px;
            line-height: 1.44;
            letter-spacing: -0.2px;
            width: 100%;

            span {
              display: inline;
            }
          }
        }

        .desktop-divider {
          width: 100%;
          max-width:760px;
          height:1px;
          background-color:#e1e1de;
        }

        .mission-detail-container {
          display: flex;
          flex-direction: row;
          margin-top:20px;
          max-width:750px;
          width: 100%;

          @include respond-to(mobile) {
            flex-direction: column;
            padding: 24px 38px;
            background-color: #fbfbf8;
            margin-top: 18px;
            box-sizing: border-box;
          }

          .mission-detail-item {
            flex: 1;

            @include respond-to(mobile) {
              display: flex;
              flex-direction: row;
            }

            .mission-icon-container {
              position:relative;
              height:100px;

              @include respond-to(mobile) {
                display: inline-block;
                width: 40px;
                height: 40px;
              }

              .mission-icon {
                position:absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              .lock {
                width: 35px;
                height: 40px;

                @include respond-to(mobile) {
                  width: 17px;
                  height: 20px;
                }
              }

              .mobile {
                width:28px;
                height:46px;

                @include respond-to(mobile) {
                  width: 15px;
                  height: 16px;
                }
              }

              .swap {
                width:36px;
                height:33px;

                @include respond-to(mobile) {
                  width: 17px;
                  height: 15px;
                }
              }

              .card {
                width:38px;
                height:29px;

                @include respond-to(mobile) {
                  width: 20px;
                  height: 15px;
                }
              }

              .ppt {
                width:37px;
                height:40px;

                @include respond-to(mobile) {
                  width: 18px;
                  height: 20px;
                }
              }

            }

            .mission-detail-desc {
              font-size: 16px;
              font-weight: lighter;
              color: #555555;
              line-height: 1.44;
              letter-spacing: -0.2px;
              text-align: center;

              span {
                display: block;
              }

              @include respond-to(mobile) {
                display: inline-block;
                line-height: 40px;
                margin-left: 10px;

                span {
                  display: inline;
                }
              }
            }
          }
        }

        .history-container {
          display: grid;
          grid-template-columns: 1.5fr 1fr 5fr;
          margin-top: 24px;
          margin-bottom: 100px;
          border-top: 1px solid $black2;
          border-bottom: 1px solid $black2;
          padding-top: 26px;
          padding-bottom: 26px;
          max-width: 760px;

          @include respond-to(mobile) {
            margin-top: 12px;
            margin-bottom: 32px;
          }

          .line {
            grid-column-start: 1;
            grid-column-end: 4;
            background-color: #e1e1de;
            height: 1px;
            width: 100%;
            margin-top: 26px;
            margin-bottom: 26px;

            @include respond-to(mobile) {
              margin-top: 18px;
              margin-bottom: 18px;
            }
          }

          .year {
            font-size: 16px;
            color: $black2;
            font-weight: bold;
            margin-left: 5px;
          }

          .month {
            font-size: 16px;
            color: $black2;
            font-weight: bold;
            text-align: center;
          }

          .desc {
            font-size: 16px;
            color: $black2;
            margin-right: 5px;
            font-weight: lighter;
            text-align: left;
          }
        }

        .mobile-divider {
          height: 10px;
          width: 100%;
          background-color: #e1e1de;
        }

        .bi-ci-desktop {
          margin-bottom:100px;

          .title {
            color: $black2;
            font-size: 24px;
            line-height: 1.42;
            font-weight: 900;
            font-family: 'Apple SD Gothic Neo', sans-serif;
          }

          .bi-btn {
            position: relative;
            display: inline-block;
            border: 1px solid $green;
            min-width: 100px;
            height: 38px;
          }

          .bi-btn-text {
            color: $green;
            font-size: 16px;
            margin-left: 16px;
            margin-right: 30px;
            line-height: 40px;
          }

          .download {
            position: absolute;
            width: 8px;
            height: 12px;
            right: 12px;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            top: 50%;
          }

          .ci-btn {
            position: relative;
            display: inline-block;
            border: 1px solid $black2;
            min-width: 100px;
            height: 38px;
          }

          .ci-btn-text {
            color: $black2;
            font-size: 16px;
            margin-left: 16px;
            margin-right: 30px;
            line-height: 40px;
          }
        }

        .bi-ci-mobile {
          padding: 32px 20px;
          width: 320px;

          .bi-title {
            font-size: 16px;
            font-weight: 900;
            color: $green;
            font-family: 'Apple SD Gothic Neo', sans-serif;
          }

          .ci-title {
            font-size: 16px;
            font-weight: 900;
            color: $black2;
            font-family: 'Apple SD Gothic Neo', sans-serif;
            margin-top: 24px;
          }

          .logo {
            width: 320px;
            height: 96px;
            margin-top: 10px;
          }

          .desc {
            color: #555555;
            font-size: 14px;
            font-weight: 300;
            margin-top: 16px;
          }
        }

        .service-api {
          margin-bottom: 100px;

          @include respond-to(mobile) {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 32px;
            margin-bottom: 50px;
          }

          .title {
            font-size: 22px;
            font-weight: bold;
            color: $black2;

            @include respond-to(mobile) {
              font-size: 16px;
            }
          }

          .service-btn {
            position: relative;
            display: inline-block;
            border: 1px solid $black2;
            width: 184px;
            height: 38px;

            @include respond-to(mobile) {
              width: 130px;
              height: 40px;
            }
          }

          .btn-text {
            color: $black2;
            font-size: 15px;
            margin-left: 16px;
            margin-right: 20px;
            line-height: 40px;
          }

          .download {
            position: absolute;
            width: 8px;
            height: 12px;
            right: 12px;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            top: 50%;
          }

          .api-btn {
            position: relative;
            display: inline-block;
            border: 1px solid $black2;
            width: 166px;
            height: 38px;

            @include respond-to(mobile) {
              width: 130px;
              height: 40px;
            }
          }

          .desktop-divider {
            margin-top: 50px;
            margin-bottom: 50px;

            @include respond-to(mobile) {
              margin-top: 32px;
              margin-bottom: 32px;
            }
          }
        }
      }
    }

    @include desktop-page {
      .hide-on-pc {
        display: none !important;
      }
    }
    @include mobile-page {
      .hide-on-mobile {
        display: none !important;
      }
    }
  }
</style>