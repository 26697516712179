<template>
  <div id="event">
    <div class="wrapper">
      <a href="https://bitberry.app/airspot/events">
        <img class="bitberry-logo" src="../images/airspot/img-logo-airdrop@2x.png" alt="logo image">
      </a>
      <hr>

      <div class="event-status" v-bind:style="eventStatusStyle">{{eventStatusMessage}}</div>

      <h1 class="event-title" v-text="event.name"></h1>
      <p>{{ $t('airspot.joinAirdropEvent') }}</p>
      <iframe v-if="!isImage" width="560" height="315" frameborder="0" :src="introImageUrl"
              allow="autoplay; encrypted-media" allowfullscreen></iframe>

      <img v-if="isImage" class="intro-image" :src="introImageUrl">
      <section class="quiz">
        <div v-if="quizSheet">
          <h1 class="event-title">{{quizSheet.question}}</h1>
          <div class="button-wrapper">
            <box-button v-for="o in options" v-bind:key="o.number" :number="o.number" :text="o.text"
                        @click="onButtonClicked">
            </box-button>
          </div>
        </div>

        <div>
          <h3>{{ $t('airspot.needAgreement') }}</h3>
        </div>
        <div class="agreement">
          <div class="privacy">
            <h4>•{{ $t('airspot.privacyPolicy') }}</h4>
            <ul>
              <li>- {{ $t('airspot.eventPurpose') }}</li>
              <li>- {{ $t('airspot.itemPhoneNumber') }}</li>
              <li>- {{ $t('airspot.retention') }}</li>
            </ul>
          </div>

          <div class="privacy">
            <h4>•{{ $t('airspot.disclosure') }}</h4>
            <ul>
              <li>- {{ $t('airspot.provideTo') }}</li>
              <li>- {{ $t('airspot.purposeOfUse') }}</li>
              <li>- {{ $t('airspot.itemPhoneNumber') }}</li>
              <li>- {{ $t('airspot.retention6') }}</li>
            </ul>
          </div>
        </div>
      </section>

      <hr class="one">

      <p v-if="event.open_chat_url">
        <strong>{{currencyCode}} 오픈채팅방에 참여하고 코인에 대한 정보와 다양한 이벤트 정보를 실시간으로 받아보세요.💰</strong><br>
        <a class="goto_open_chat" :href="event.open_chat_url"
           @click="sendToGa">오픈채팅방 바로가기></a>
      </p>

      <form action="/airspot_users" accept-charset="UTF-8"
            v-on:submit.prevent="submit">
        <input type="tel" name="airspot_user[phone_number]" :placeholder="$t('airspot.inputPhoneNumber')"
               v-model="airspotUser.phone_number" ref="phone_number" :disabled="!eventAvailable">
        <button class="drop-btn" :disabled=!eventAvailable>{{ $t('airspot.agreeAndGetAirdrop') }}</button>
      </form>

      <div class="warning">
        <h6>{{ $t('airspot.checkYourPhoneNumber') }}</h6>
        <h6><strong>Examples</strong></h6>
        <h6>PH: +63XXXXXXXXXX</h6>
        <h6>VN: +84XXXXXXXXX</h6>
        <h6>US: +1XXXXXXXXXX</h6>
      </div>
    </div>
    <footer>
      <div class="footer-wrapper">
        <div>
          <h3>{{ $t('airspot.dataPolicy') }}</h3>
          <p>
            <a href="https://bitberry.app/app_pages/terms">{{ $t('airspot.termOfService') }}</a> |
            <a href="https://bitberry.app/app_pages/privacy">{{ $t('airspot.privacyPolicy') }}</a>
          </p>
        </div>
        <div>
          <h3>{{ $t('airspot.helpCenter') }}</h3>
          <p>
            Tel : <a href="tel:02-2051-6930">02-2051-6930</a> | <a href="mailto:with@rootone.com">{{ $t('airspot.contactEmail')
            }}</a>
          </p>
        </div>
        <div>
          <h3>RootOne</h3>
          <p>
            <a href="https://rootone.com">{{ $t('airspot.companyName') }}</a> | 사업자 등록번호: 811-87-00338 | 대표 : 김동희<br>
            강남구 논현로63길 71층, 2층
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import axios from 'axios'
  import Vue from 'vue';
  import Toasted from 'vue-toasted';
  import BoxButton from "./box_button.vue";

  Vue.use(Toasted);

  export default {
    props: ['initialAirspotUser', 'initialEvent',
      'introImageUrl', 'logoImageUrl', 'quizSheet', 'eventSoldOut', 'eventEnded', 'isImage',
      'currencyCode', 'lang', 'eventHashId'],
    components: {BoxButton},
    data() {
      return {
        airspotUser: {...this.initialAirspotUser},
        event: {...this.initialEvent},
        missionCompleted: false,
        options: [
          {number: 1, text: this.quizSheet && this.quizSheet.option1},
          {number: 2, text: this.quizSheet && this.quizSheet.option2},
          {number: 3, text: this.quizSheet && this.quizSheet.option3},
          {number: 4, text: this.quizSheet && this.quizSheet.option4},
        ],
        eventStatusMessage: '',
        eventStatusStyle: 'display: none',
        eventAvailable: true,
      }
    },
    methods: {
      sendToGa() {
        gtag('event', 'ClickOpenChat', {'event_label': this.initialEvent.name});
      },
      updateEventStatus() {
        if (Date.now() > Date.parse(this.event.end_at)) {
          this.eventStatusMessage = this.$t('airspot.eventEnded');
          this.eventStatusStyle = 'background-color: #666666';
          this.eventAvailable = false;
        } else if (this.eventSoldOut) {
          this.eventStatusMessage = this.$t('airspot.eventSoldOut');
          this.eventStatusStyle = 'background-color: #c85e53';
          this.eventAvailable = false;
        } else if (Date.now() < Date.parse(this.event.start_at)) {
          this.eventStatusMessage = `${this.remainTime()}`;
          this.eventStatusStyle = 'background-color: #2da252';
          this.eventAvailable = false;
        } else {
        }
      },
      remainTime() {
        let seconds = (Date.parse(this.event.start_at) - Date.now()) / 1000;
        let pad = function (x) {
          return (x < 10) ? "0" + x : x;
        };
        if (this.lang === 'ko') {
          return pad(parseInt(seconds / (60 * 60))) + "시간" +
            pad(parseInt(seconds / 60 % 60)) + "분" +
            pad(Math.ceil(seconds % 60)) + '초 후 시작'
        } else {
          return 'Start in ' + pad(parseInt(seconds / (60 * 60))) + ":" +
            pad(parseInt(seconds / 60 % 60)) + ":" +
            pad(Math.ceil(seconds % 60))
        }
      },
      onButtonClicked(number) {
        let url = `/airspot/quizzes/${this.quizSheet.id}/solve.json`;
        let that = this;

        axios.get(url, {
          params: {'airspot_quiz_sheet[answer]': number},
          headers: {
            'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content,
            'Content-Type': 'application/json',
          }
        }).then(function (response) {
          console.log(response.data);

          if (response.data.solved) {
            that.$toasted.success(
              that.$t('airspot.answerIsRight', {
                personalAmount: that.event.personal_amount,
                currencyCode: that.currencyCode
              }), {
                theme: "outline",
                position: "bottom-center",
                duration: 2000,
                fullWidth: true,
              });

            that.$refs.phone_number.focus();
          } else {
            that.$toasted.show(that.$t('airspot.answerIsWrong'), {
              theme: "bubble",
              position: "bottom-center",
              duration: 2000,
              fullWidth: true,
            });
          }
          that.missionCompleted = response.data.solved;
        }).catch(function (error) {
          console.log("ERROR: " + error);
        });
      },
      submit() {
        if (this.quizSheet && !this.missionCompleted) {
          this.$toasted.show(this.$t('airspot.answerQuiz'), {
            theme: "bubble",
            position: "bottom-center",
            duration: 2000,
            fullWidth: true,
          });
          return false;
        }

        let url = `/airspot/events/${this.eventHashId}/airspot_users.json`;
        axios.post(url, {airspot_user: this.airspotUser}).then(
          function (response) {
            alert("적립 완료!\n문자 메시지 또는 비트베리 앱 내 알림을 통해 내역을 확인하세요.");

          }).catch(function (error) {
          alert(error.response.data.message);
        });
        return false;
      }
    },
    created() {
      this.updateEventStatus();
    },
    mounted() {
      setInterval(this.updateEventStatus, 1000);
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/application";

  $page-width: 960px;
  $section-width: 832px;

  #event {
    background-color: $bb_ivory3;
    font-weight: lighter;

    h1, h3, h4 {
      font-weight: normal;
    }
    h2, h5, h6 {
      font-weight: lighter;
    }

    .wrapper {
      text-align: center;
      font-family: "Nanum Barun Gothic", sans-serif;
      color: #212529;
      font-weight: lighter;
      padding: 20px;
      max-width: $page-width;
      margin: 0 auto;

      .intro-image, iframe {
        width: 100%;
      }

      img.bitberry-logo {
        width: 180px;
        object-fit: contain;
      }

      hr {
        width: 100%;
        height: 0.5px;
        background-color: #2da252;
        margin-bottom: 64px;

        @include respond-to(mobile) {
          margin-bottom: 36px;
        }
      }

      .event-status {
        margin-top: -40px;
        background-color: #666666;
        font-size: 22px;
        height: 60px;
        border-radius: 9px;
        line-height: 60px;
        text-align: center;
        font-weight: normal;
        color: white;
        @include respond-to(mobile) {
          margin-top: 0;
        }
      }

      .event-title {
        margin-top: 50px;
        margin-bottom: 10px;
        word-break: keep-all;
        @include respond-to(mobile) {
          margin-top: 32px;
          font-size: 1.3rem;
        }
      }

      section {
        width: $section-width;
        display: inline-block;
        margin-bottom: 10px;

        h1.question {
          margin-top: 80px;
          margin-bottom: 15px;
          font-weight: lighter;

          @include respond-to(mobile) {
            margin-top: 40px;
            min-width: 320px;
          }
        }

        .button-wrapper {
          margin-top: 20px;
          font-size: 1.1rem;
          font-weight: lighter;
          @include respond-to(mobile) {
            width: 100%;
          }
        }

        h3 {
          margin: 15px 10px;
          font-size: 0.9rem;
          text-align: left;
          @include respond-to(mobile) {
            margin-top: 20px;
          }
        }

        .agreement {
          text-align: left;
          font-size: 0.9rem;

          .privacy {
            vertical-align: top;
            display: inline-block;
            width: $box-button-width;
            font-size: 0.8rem;
            text-align: left;

            &:nth-child(2) {
              margin-left: 10px;
            }

            li {
              list-style-type: none;
            }

            ul {
              padding: 0;
              margin: 0;
            }

            h4 {
              text-align: left;
              font-size: 0.9rem;
            }
          }
        }

        @include respond-to(tablet) {
          width: $box-button-width;

          .agreement {
            .privacy {
              &:nth-child(2) {
                margin: 10px 0;
              }
            }
          }
        }
        @include respond-to(mobile) {
          width: 100%;
        }
      }

      hr.one {
        height: 0.5px;
        max-width: $page-width;
        text-align: center;
        margin: 64px auto;
        width: 100%;
        @include respond-to(mobile) {
          margin: 48px 0;
        }
      }

      .custom-message {
        font-weight: bolder;
      }

      .goto_open_chat {
        display: block;
        margin-top: 10px;
        margin-bottom: 20px;
        color: $bb_green;
        font-weight: bold;
        text-decoration: underline;
      }

      form {
        display: flex;
        margin-bottom: 10px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        input {
          background-color: white;
          width: $box-button-width;
          height: 60px;
          text-align: center;
          border-radius: 8px;
          border: 1px solid $bb_green;
          font-size: 1.2rem;
          font-family: "Nanum Gothic", sans-serif;
          margin: 8px;
          font-weight: lighter;

          &:disabled, &[disabled] {
            color: #b6b6b6;
            border: solid 1px #b6b6b6;
          }

          &::placeholder {
            color: #cccccc;
          }

          @include respond-to(mobile) {
            text-align: center;
            height: 48px;
            width: 80%;
            margin-bottom: 4px;
            font-size: 1rem;
          }
        }

        .drop-btn {
          margin: 6px;
          width: $box-button-width;
          height: 60px;
          border-radius: 8px;
          border: none;
          background-color: $bb_green;
          color: white;
          font-size: 1.2rem;
          font-weight: lighter;

          &:disabled, &[disabled] {
            background-color: #e1e1de;
            color: #b6b6b6;
          }

          @include respond-to(mobile) {
            border: none;
            text-align: center;
            height: 48px;
            width: 80%;
            margin-bottom: 4px;
            font-size: 1rem;
          }
        }
      }
    }

    .warning {
      margin-bottom: 64px;
      color: #979797;
      h6 {
        font-size: 0.9rem;
        color: #979797;
        margin: 10px;
        display: block;
        strong {
          color: black;
        }
      }

      @include respond-to(mobile) {
        h6 {
          font-size: 0.8rem;
        }
      }
    }

    footer {
      width: 100vw;
      background-color: #3f3f3f;

      .footer-wrapper {
        color: white;
        text-align: left;
        font-weight: lighter;
        padding: 50px;
        margin: 0 auto;
        max-width: $page-width;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      h3 {
        font-size: 0.8rem;
        font-weight: lighter;
      }

      & > div {
        margin: 0 30px;
      }

      p {
        font-size: 0.8rem;
      }

      @include respond-to(mobile) {
        padding: 40px 10px;
      }

      a {
        color: white;
      }
    }
  }
</style>