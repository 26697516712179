<template>
  <div class="notice-list-item">
    <div class="title-container">
      <span class="link-wrapper"><a :href="notice_path(notice)">{{ notice.title }}</a></span>
      <span class="date-item">{{ notice.created_at | human_date }}</span>
    </div>
    <img :src="require('images/notices/ic_arrow_right_big.svg')" class="notice-more-icon">
  </div>
</template>

<script>
  export default {
    props: ['notice'],
    methods: {
      notice_path: (notice) => {
        return `/app_pages/notices/${notice.id}`;
      }
    }
  }
</script>
<style scoped lang="scss">
  @import '../../stylesheets/application/list_pages';

  .notice-list-item {
    @include list-item();
  }
</style>