import common from '../lib/common';
import NoticeShow from '../app_pages/notices/notice_show.vue'
import NoticeIndex from '../app_pages/notices/notice_index.vue'
import FaqShow from '../app_pages/faqs/faq_show.vue'
import FaqIndex from '../app_pages/faqs/faq_index.vue'
import InviteShow from '../app_pages/invites/invite_show'
import EscrowShow from '../escrows/escrow_show'
import AirspotKeypad from '../airspot/airspot_keypad'
import AirspotQuiz from '../airspot/airspot_quiz'
import AirspotQuizBox from '../airspot/airspot_quiz_box'
import Privacy from '../app_pages/privacy'
import PrivacyV1 from '../app_pages/privacy_v1'
import ServiceOperatingPolicy from '../app_pages/service_operating_policy'
import PrivacyTermsV1 from '../app_pages/privacy_terms_v1'
import PrivacyTermsV2 from '../app_pages/privacy_terms_v2'
import Limit from '../app_pages/limit'
import Fee from '../app_pages/fee'
import TermsV1 from '../app_pages/terms_v1'
import TermsV2 from '../app_pages/terms_v2'
import TermsV3 from '../app_pages/terms_v3'
import Guide from '../app_pages/guide'
// import Team from '../home/team'
import Contact from '../home/contact'
import AboutUs from '../home/about_us'
import HomeFooter from '../home/home_footer'
import Business from '../home/business'
import Media from '../home/media'
import HomeIndex from '../home/index'
import Event from '../home/event'

window.$ = window.jQuery = require('jquery/dist/jquery');
window._ = require('lodash');
window.common = common;
window.axios = require('axios');
window.daumtools = require('../lib/web2app-standalone-1.1.0.min');
window.Kakao = require('../lib/kakao.min');
Kakao.init('47aa0f487539e9b2095f71c09e1f59ef');

require('normalize.css/normalize.css');
require('../stylesheets/application.scss');
require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');

common.setupAxios(axios);

document.addEventListener('DOMContentLoaded', () => {
  common.mountComponent('#notice_show', NoticeShow);
  common.mountComponent('#notice_index', NoticeIndex);
  common.mountComponent('#faq_show', FaqShow);
  common.mountComponent('#faq_index', FaqIndex);
  common.mountComponent('#invite_show', InviteShow);
  common.mountComponent('#escrow_show', EscrowShow);
  common.mountComponent('#privacy', Privacy);
  common.mountComponent('#privacy_v1', PrivacyV1);
  common.mountComponent('#privacy_terms_v1', PrivacyTermsV1);
  common.mountComponent('#privacy_terms_v2', PrivacyTermsV2);
  common.mountComponent('#limit', Limit);
  common.mountComponent('#fee', Fee);
  common.mountComponent('#terms_v1', TermsV1);
  common.mountComponent('#terms_v2', TermsV2);
  common.mountComponent('#terms_v3', TermsV3);
  common.mountComponent('#guide', Guide);
  common.mountComponent('#service_operating_policy', ServiceOperatingPolicy);
  common.mountComponent('#airspot-keypad', AirspotKeypad);
  common.mountComponent('#airspot-quiz', AirspotQuiz);
  common.mountComponent('#airspot-quiz-box', AirspotQuizBox);
  // common.mountComponent('#team', Team);
  common.mountComponent('#contact', Contact);
  common.mountComponent('#about_us', AboutUs);
  common.mountComponent('#home_footer', HomeFooter);
  common.mountComponent('#business', Business);
  common.mountComponent('#media', Media);
  common.mountComponent('#index', HomeIndex);
  common.mountComponent('#event', Event);
});