import Vue from 'vue'
import VueI18n from 'vue-i18n';
import locales from './locale'
import Promise from 'promise-polyfill';
import moment from 'moment'

Vue.use(VueI18n);

function isObject (value) {
  return value === Object(value)
}

function isArray (value) {
  return Array.isArray(value)
}

function isFile (value) {
  return value instanceof File
}

function humanNumber(number) {
  if (number) {
    let parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else {
    return null;
  }
}

function humanDatetime(datetime) {
  return moment(datetime).format('YYYY.MM.DD HH:mm:ss');
}

export default {
  mountComponent(selector, component, store) {
    const elements = document.querySelectorAll(selector);
    elements.forEach(function (el) {
      const props = JSON.parse(el.getAttribute('data'));

      const i18n = new VueI18n({
        locale: window.languageCode,
        fallbackLocale: 'ko',
        messages: locales
      });

      new Vue({
        i18n,
        el,
        store,
        render: h => h(component, {
          props
        })
      });
    });
  },
  setupAxios(axios) {
    axios.defaults.headers.common['Accept'] = 'application/json';

    const csrfToken = document.querySelector("meta[name=csrf-token]").content;
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (_.get(error, 'response.status') === 401) {
        let redirect_url = _.get(error, 'response.data.redirect_to');

        if (redirect_url) {
          window.location.replace(redirect_url);
        }
      }

      return Promise.reject(error);
    });
  },
  objectToFormData(obj, fd, pre) {
    fd = fd || new FormData();
    let that = this;

    Object.keys(obj).forEach(function (prop) {
      let key = pre ? (pre + '[' + prop + ']') : prop;

      if (isObject(obj[prop]) && !isArray(obj[prop]) && !isFile(obj[prop])) {
        that.objectToFormData(obj[prop], fd, key)
      } else if (isArray(obj[prop])) {
        obj[prop].forEach(function (value, index) {
          if (isObject(value) && !isFile(value)) {
            let objectKey = key + '[' + index + ']';
            that.objectToFormData(value, fd, objectKey)
          } else {
            let arrayKey = key + '[]';
            fd.append(arrayKey, value)
          }
        })
      } else {
        fd.append(key, obj[prop])
      }
    });

    return fd
  },
  queryParams() {
    return document.location.search.replace(/(^\?)/,'').split("&").map(function(n){return n = n.split("="),this[n[0]] = decodeURIComponent(n[1]),this}.bind({}))[0];
  },
  humanDatetime
}

Vue.filter('human_date', function (date) {
  let d = new Date(date);
  return `${d.getFullYear()}.${d.getMonth() + 1}.${d.getDate()}`;
});

Vue.filter('human_number', function (number) {
  return humanNumber(number);
});

Vue.filter('human_datetime', function (datetime) {
  return humanDatetime(datetime);
});
