<template>
  <div id="horizontal-bar">
    <hr>
    <span>{{text}}</span>
    <hr>
  </div>
</template>

<script>
  export default {
    props: ['text'],
    data() {
      return {}
    },
    mounted() {
    }
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/airspot";

  #horizontal-bar {
    display: flex;
    align-items: center;
    hr {
      width: 100%;
      height: 1px;
      background-color: $green;
      border: none;
      flex-shrink: 1;
    }

    span {
      margin: 0 7px;
      flex-grow: 1;
      flex-shrink: 0;
      font-family: $s-core-5, sans-serif;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0;
      text-align: center;
      color: $green;
    }
  }

</style>