<template>
  <transition name="fade">
    <div class="toast-mask" v-if="showToast">
      <div class="toast-container">
        <div class="toast" v-bind:style="{color: this.color, 'border-color': this.color}">
          <div class="description">{{ desc }}</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    props: [],
    components: { },
    data() {
      return {
        desc: '',
        color: '#00ff79',
        showToast: false
      };
    },
    methods: {
      show(desc, color) {
        this.showToast = true;
        this.desc = desc;

        if (color !== '') {
          this.color = color;
        } else {
          this.color = '#00ff79';
        }

        setTimeout(() => {
          this.showToast = false;
        }, 1000)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../stylesheets/airspot";

  .toast-mask {
    @include mask(transparent);

    .toast-container {
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      .toast {
        position: relative;
        width: 287px;
        height: 51px;
        margin: 0 auto;
        background-color: $darkgreen;
        opacity: 0.8;
        border-radius: 25.5px;
        border: solid 2px $lightgreen;
        text-align: center;
        font-family: $jalnan;
        font-size: 20px;
        line-height: 51px;
        color: $lightgreen;
        box-sizing: border-box;

        .accent {
          color: $lightgreen;
        }

        .desc {

        }
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>