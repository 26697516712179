<template>
  <div>
    <h1>{{ $t('fee.heading') }}</h1>

    <table>
      <tr>
        <th>{{ $t('fee.coin') }}</th>
        <th>{{ $t('fee.fee') }}</th>
      </tr>
      <tr>
        <td>BTC</td>
        <td>0.0009 BTC</td>
      </tr>
      <tr>
        <td>ETH</td>
        <td>메인넷 가스비 시세 반영</td>
      </tr>
      <tr>
        <td>ERC20</td>
        <td>메인넷 가스비 시세 반영</td>
      </tr>
      <tr>
        <td>XRP</td>
        <td>서비스 지원 종료</td>
      </tr>
      <tr>
        <td>KLAY</td>
        <td>2 KLAY</td>
      </tr>
      <tr>
        <td>LMT</td>
        <td>서비스 지원 종료</td>
      </tr>

    </table>

    <p>{{ $t('fee.description') }}</p>
  </div>
</template>

<script>
  import daumtools from 'lib/daumtools';
  export default {
    mounted() {
      daumtools.sendDataToMobileApp(this.$t('fee.title'));
    }
  }
</script>

<style scoped lang="scss">
  table {
    th {
      white-space: pre;
    }
  }
  h1, th {
    font: {
      weight: normal;
    }
  }
  div p {
    color: #979797;
    white-space: pre-line;
  }
</style>