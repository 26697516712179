<template>
  <div class="nav-wrapper" v-bind:class="{transparent: !scrolled && transparentOnTop}">
    <nav id="global-nav-header" >
      <ul>
        <a href="/home/index">
          <img class="brand" v-if="!scrolled && transparentOnTop" src="../images/home/im-bitberry-2@2x.png" />
          <img class="brand" v-else src="../images/home/im-bitberry-green@2x.png" />
        </a>

        <img id="hamburger-menu" class="hamburger-menu hide-on-pc" v-on:click="toggleMenuHamburger"
             src="../images/intro/ic-menu@3x.png" v-show="!menuExpanded" />
        <img class="close-menu hide-on-pc" v-on:click="toggleMenuHamburger"
             src="../images/intro/ic-close@3x.png" v-show="menuExpanded" />
        <ul class="right-desktop-menu hide-on-mobile">
          <li v-for="menu in menus">
            <a :class="{transparent: !scrolled && transparentOnTop, active: menu.href === currentMenu}" @click="setCurrentMenu" :data-href="menu.href" :href="menu.href" v-html="menu.html"></a>
          </li>
        </ul>
      </ul>

      <div v-if="menuExpanded" class="hide-on-pc menu-expanded" style="margin-bottom: 17px;">
        <a href="/home/about_us">About us</a>
        <a href="/home/business">Business</a>
        <a href="/home/contact">Contact</a>
        <a href="/home/event">Event</a>
      </div>
    </nav>

    <div class="divider hide-on-pc"></div>
  </div>
</template>

<script>
  export default {
    props: ['transparentOnTop'],
    components: {},
    data() {
      return {
        scrolled: false,
        menuExpanded: false,
        currentMenu: '/home/about_us',
        menus: [
          {
            href: '/home/about_us',
            html: 'About Us',
          },
          {
            href: '/home/business',
            html: 'Business',
          },
          {
            href: '/home/contact',
            html: 'Contact',
          },
          {
            href: '/home/event',
            html: 'Event',
          },
        ],
      }
    },
    beforeMount() {
      window.removeEventListener('click', this.onClick);
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      setCurrentMenu(event) {
        this.currentMenu = event.target.dataset.href;
      },
      toggleMenuHamburger() {
        console.log(this.menuExpanded);
        this.menuExpanded = !this.menuExpanded;
      },
      handleScroll: function (event) {
        this.menuExpanded = false;
        this.scrolled = ((window.pageYOffset || window.scrollTop) - (window.clientTop || 0) > 20);
      },
      onClick: function (event) {
        if (event.target.id != 'hamburger-menu') {
          if (this.menuExpanded) {
            this.menuExpanded = false
          }
        }
      }
    },
    mounted() {
      window.addEventListener('click', this.onClick);
      window.addEventListener('scroll', this.handleScroll);
      this.currentMenu = window.location.pathname;
    },
  }
</script>

<style lang="scss">
  @import '../stylesheets/common';
  @import '../stylesheets/home';

  %nav-shared {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: white;
    opacity: 1;
    box-sizing: border-box;
    display: block;
  }

  .nav-wrapper {
    width: 100%;
    @extend %nav-shared;
    padding-left: 3px;
    padding-right: 3px;

    &.transparent {
      background-color: transparent;
    }

    .divider {
      height: 1px;
      width: 100%;
      background-color: #f2f2ee;
    }

    @include mobile-page {
      &.transparent {
        background-color: transparent;
        display: none;
        box-shadow: none;
        transform: translate3d(0px, -2px, 0px);
        color: $green;
      }
    }

    #global-nav-header {
      margin: 0 auto;
      max-width: $body-max-width;

      ul {
        margin: 0;
        padding: 0;
        height: 56px;
        line-height: 56px;
        display: flex;
        justify-content: space-between;

        @include mobile-page {
          width: 100%;
        }

        .brand {
          width: 96px;
          height: 15px;
          padding: 21px 0;

          @include mobile-page {
            margin: 0 20px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        .hamburger-menu {
          width: 18px;
          height: 12px;
          padding: 21px 20px 21px 40px;

          &:hover {
            cursor: pointer;
          }
        }

        .close-menu {
          width: 14px;
          height: 14px;
          padding: 21px 20px 21px 40px;
        }

        li {
          list-style-type: none;
        }

        .right-desktop-menu {
          display: flex;
          justify-content: flex-end;
          flex-grow: 1;
          color: #333333;
          font-family: "Nanum Barun Gothic", sans-serif;
          font-size: 15px;
          font-weight: 300;

          li {
            margin-left: 36px;
          }

          a {
            text-decoration: none;

            &:visited {
              color: #333333;
            }
            &:link {
              color: #333333;
            }
            &.active {
              font-weight: bold;
            }

            &.transparent {
              color: white;
            }
          }
        }
      }

      .menu-expanded {
        display: flex;
        flex-direction: column;
        background-color: white;
        align-items: center;

        a {
          text-align: center;
          width: 100%;
          height: 32px;
          line-height: 32px;
          color: #333333;
          text-decoration: none;
          font-size: 15px;
          font-weight: 300;
          &.active {
            color: $green;
          }
          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }
</style>