<template>
  <div class="popup-mask">
    <div class="popup-container">
      <div class="popup">
        <a href="#" class="close-btn" @click.prevent="$emit('close')"></a>

        <slot></slot>

        <button class="confirm-btn" v-if="showConfirm" @click.prevent="$emit('confirm')">
          확인
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['showConfirm'],
    components: { },
    data() {
      return {};
    }
  }
</script>

<style scoped lang="scss">
  @import "../../stylesheets/airspot";

  .popup-mask {
    @include mask(rgba(2, 2, 2, .6));

    .popup-container {
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      .popup {
        position: relative;
        width: 303px;
        min-height: 100px;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 4px;
        padding-bottom: 46px;
        padding-top: 38px;

        .close-btn {
          display: inline-block;
          position: absolute;
          right: 18px;
          top: 18px;
          width: 20px;
          height: 20px;
          background-image: url('../../images/airspot/lucky_box/ic-cancel@2x.png');
          background-size: 20px 20px;
        }

        .confirm-btn {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: $green;
          border: none;
          height: 46px;
          color: $white;
          font-family: $s-core-7;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          outline: none;
          
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
</style>