<template>
  <div class="service-operating-policy">
    <h1>서비스 운영 정책</h1>
    <p>
      본 운영 정책은 (주)루트원이 제공하는 '비트베리' 서비스를 운영함에 있어, 서비스 내에서 발생할 수 있는 문제에 대해 일관성 있게 대처하기 위하여 서비스 운영의 기준과 고객 여러분이 지켜주셔야 할
      세부적인 사항이 규정되어 있습니다. <br>
      서비스를 이용하면서 운영정책을 지키지 않을 경우 아래의 불이익을 당할 수 있으니 주의 깊게 읽어주시기 바랍니다.<br>
      <br>
      운영정책은 변경 추가 될 수 있으며 최소 7일 전 서비스 공지사항을 통해 공지하도록 하겠습니다.
    </p>

    <div class="paragraph">
      <ol>
        <li>
          이용제한 사유에 해당하는 활동
          <ul>
            <li>
              1) 서비스에서 금지하는 활동<br>
              - 정상적인 서비스 이용으로 볼 수 없는 다량의 계정 생성 및 서비스 가입/탈퇴, 반복적 유사 활동<br>
              - 실 사용이 불가능한 휴대폰번호로 가입하는 행위 (가상 휴대폰번호를 생성해 가입 (textPlus 등)<br>
              - 비트베리의 공식 지원 환경인 안드로이드, 애플 OS 기반의 스마트폰이 아닌 다른 환경에서 앱을 이용하는 행위 (에뮬레이터 등을 통해 서비스 이용)<br>
              - 서비스 명칭 또는 서비스와 관련한 운영진을 사칭하여 다른 고객을 속이거나 이득을 취하는 등 피해와 혼란을 주는 행위<br>
            </li>
            <li>
              2) 계정 거래/양도/대리/교환 활동<br>
              - 계정 및 계정 내 정보(코인, 컨텐츠 등)를 타인에게 판매, 양도, 대여하거나, 타인에게 그 이용을 허락 또는 이를 시도하는 행위<br>
              - 타인의 계정 및 계정 내 정보(코인, 컨텐츠 등)를 취득하기 위해 구매, 양수, 교환을 시도하거나, 이를 타인에게 알선하는 활동<br>
              - 타인을 기망하여 타인의 계정 및 계정 내 정보(코인, 컨텐츠 등)를 탈취하는 행위<br>
            </li>
          </ul>
        </li>
        <li>
          이용제한의 종류<br>
          <ul>
            <li>
              1) 계정 제한<br>
              - 모든 서비스의 이용이 한시적, 영구적으로 제한됩니다.<br>
            </li>
            <li>
              2) 송금 기능 제한<br>
              - 보유한 코인 잔고의 회원 간 이체, 외부 출금 등 송금 거래 기능이 한시적, 영구적으로 제한됩니다.<br>
            </li>
            <li>
              3) 혜택 소멸<br>
              - 이벤트를 통해 받은 코인(토큰) 지급이 취소되는 등 서비스를 통해 획득한 모든 혜택이 소멸됩니다.
            </li>
          </ul>
        </li>
      </ol>

    </div>

  </div>
</template>

<style scoped lang="scss">
</style>

<script>
  import daumtools from 'lib/daumtools';

  export default {
    props: [],
    data() {
      return {}
    },
    mounted() {
      daumtools.sendDataToMobileApp('비트베리 서비스 운영 정책');
    }
  }
</script>