<template>
  <div id="index">
    <global-nav-header transparent-on-top="true"/>

    <a href="https://bitberry.page.link/landing">
      <div id="mobile-appdown-button" class="bottom-app-download hide-on-pc" v-bind:class="{ scrolled: scrolled }">
        앱 다운로드
      </div>
    </a>

    <div class="cover">
      <div class="content">
        <h1>쉽고 안전한 지갑</h1>
        <img class="bitberry-logo" src="../images/intro/im-logo-bitberry@3x.png"/>

        <div class="store-buttons hide-on-mobile" style="margin-left: 5px">
          <a class="store-btn" href="https://play.google.com/store/apps/details?id=com.rootone.wallet" target="_blank">
            <img class="google-logo" :src="require('../images/intro/im-play-store@3x.png')"/>
            <span>Google Play</span>
          </a>
          <a class="store-btn" href="https://itunes.apple.com/kr/app/id1411817291?l=ko&ls=1&mt=8" target="_blank">
            <img class="apple-logo" :src="require('../images/intro/im-app-store@3x.png')"/>
            <span>App Store</span>
          </a>
        </div>
      </div>
    </div>

    <div class="service-feature-container">
      <div class="phone-number-transfer-wrapper">
        <div class="image">
          <img class="device-inner-image" src="../images/intro/send_by_number.gif"/>
        </div>

        <div class="content">
          <h2>
            전화번호만 알면<br/>
            이체도 간편하게
          </h2>

          <p class="hide-on-mobile">
            친구에게 송금할 땐 간편하고 쉽게 전화번호로 보내세요.<br/>
            전화번호를 기반으로 고유 주소를 발급해 안심하고<br>
            안심하고 보낼 수 있습니다.
          </p>
          <p class="hide-on-pc">
            친구에게 송금할 땐 간편하고 쉽게 전화번호로 보내세요. 전화번호를 기반으로 고유 주소를 발급해 안심하고 보낼 수 있습니다.
          </p>
        </div>
      </div>
    </div>

    <div class="service-feature-container">
      <div class="page-easy-login-auth-wrapper">
        <div class="content">
          <h2>
            로그인도 쉽게,<br/>
            인증도 쉽게!
          </h2>
          <p class="hide-on-mobile">
            송금앱은 어려워야하나요? 로그인부터 송금 인증까지 <br>시작부터 끝까지 편리하게 사용하세요.
          </p>
          <p class="hide-on-pc">
            송금앱은 어려워야하나요? 로그인부터 송금 인증까지 시작부터 끝까지 편리하게 사용하세요.
          </p>

          <div class="select-desc">
            <ul>
              <li v-for="feature in features" v-on:click="markAsActiveFeature(feature)" v-text="feature.name"
                  :class="{ active: feature.active }"/>
            </ul>

            <p class="feature-desc">
              {{featureDesc}}
            </p>
          </div>
        </div>

        <div class="image">
          <div class="single-item device-inner-image">
            <img v-for="feature in features" :src="feature.src"/>
          </div>
        </div>
      </div>
    </div>

    <div class="service-feature-container">
      <div class="security-wrapper">
        <div class="image">
          <img class="device-inner-image" src="../images/intro/im-security@2x.png">
        </div>
        <div class="content">
          <h2>
            최고 수준의 보안으로<br>
            자산을 안전하게
          </h2>

          <p class="hide-on-mobile">
            철저한 관리적 보안 체계 운영과 높은 비율의 콜드월렛 백업<br>
            거래량, 보안 수준 국내 1위 ‘업비트’의 지갑 노하우 적용<br>
            세계 1위 수준 화이트해커 ‘티오리’사의 보안 검수
          </p>
          <p class="hide-on-pc">
            철저한 관리적 보안 체계 운영과<br/> 높은 비율의 콜드월렛 백업<br/>-<br/>
            거래량, 보안 수준 국내 1위<br/> ‘업비트’의 지갑 노하우 적용<br/>-<br/>
            세계 1위 수준 화이트해커<br/> ‘티오리’사의 보안 검수
          </p>
        </div>
      </div>
    </div>

    <div class="service-feature-container">
      <a class="service-intro-download hide-on-mobile" href="/home/about_us/service_api">
        더 자세히 알고 싶다면 서비스 소개서 다운받기
        <img class="arrow-w" src="../images/home/ic-go-w@2x.png" width="13" height="20">
        <img class="arrow-g" src="../images/home/ic-go-g@2x.png" width="13" height="20">
      </a>

      <a class="service-intro-mobile-download hide-on-pc" href="/home/about_us/service_api">
        서비스 소개서 다운받기
        <img src="../images/home/ic-arrow-link@2x.png" width="24" height="16">
      </a>
    </div>

    <div class="bitberry-business-container">
      <div class="bitberry-business-title-container">
        <img src="../images/home/im-bitberry-biz@2x.png"/>
<!--        <h1>BUSINESS</h1>-->
      </div>
      <p>비트베리는 서비스를 운영하는 기업에게 적합한 솔루션을 제공합니다. <br>지갑은 비트베리에 맡기고 서비스의 본질에 집중하세요.</p>
    </div>

    <div class="page-gradient-wrapper">
      <!-- 혜택1 -->
      <div class="page-business-partner-account">
        <div class="wrapper">
          <div class="content">
            <div class="transparent-btn">혜택 1</div>
            <h2>
              파트너사만을 위한<br/>
              기업계정 노출
            </h2>

            <p>
              고객 연락처에 기업 계정이 노출됩니다.<br/>
              고객이 번호를 잘못 입력해 오송금하는 일을 막아줍니다.
            </p>
          </div>
          <img src="../images/home/group-18@2x.png"/>
        </div>
      </div>

      <!-- 혜택2 -->
      <div class="page-airspot-service">
        <div class="wrapper">
          <img src="../images/home/im-airdrop@2x.png"/>
          <div class="content">
            <div class="transparent-btn">혜택 2</div>
            <h2>
              고객의 전화번호만으로<br/>
              에어드랍을 편리하게
            </h2>
            <p>
              고객 지갑주소 없이 전화번호만으로 에어드랍이 가능하고,<br/>
              실시간 자동 지급되어 운영비용과 고객 불편이 크게 줄어듭니다. 다양한 서비스에 폭넓게 활용할 수 있도록 API와 어드민 페이지를 제공합니다.
            </p>
            <p class="airdrop-desc">(월 2회 무료 제공)</p>
          </div>
        </div>
      </div>

      <!-- 혜택3 -->
      <div class="page-v2-api">
        <div class="wrapper">
          <div class="content">
            <div class="transparent-btn">혜택 3</div>
            <h2>
              인증/에어드랍/결제를<br>
              손쉽게 적용해보세요.
            </h2>
            <p>
              고객의 잔고 조회, 고객의 활동에 맞는 즉각적인 에어드랍,<br>
              온오프라인에서 손쉽게 적용가능한 결제 솔루션을 제공합니다.
            </p>
          </div>
          <img src="../images/home/group-10@2x.png"/>
        </div>
      </div>

      <!-- 혜택4 -->
      <div class="page-alliance">
        <div class="wrapper">
          <img src="../images/home/im-partner-admin@2x.png"/>
          <div class="content">
            <button class="transparent-btn">혜택 4</button>
            <h2>
              비트베리 얼라이언스 프로그램
            </h2>
            <p>
              공동 마케팅, 보안 상담, 온오프라인 사용처 확보, 파트너사간 네트워킹 등 다양한 혜택을 제공합니다.
            </p>
          </div>
        </div>
      </div>

      <div class="business-partner-btn-container">
        <a class="business-partner-btn hide-on-mobile" href="/home/business?menu=alliance">
          더 자세한 이야기를 보고 싶다면, 비즈니스 페이지로 이동하기
          <img class="arrow-w" src="../images/home/ic-go-w@2x.png" width="13" height="20">
          <img class="arrow-b" src="../images/home/ic-go@2x.png" width="13" height="20">
        </a>

        <a class="partner-page-mobile-btn hide-on-pc" href="/home/business?menu=alliance">
          비즈니스로 이동하기
          <img src="../images/home/ic-arrow-link-w@3x.png">
        </a>
      </div>
    </div>
    <!-- 파트너들 -->
    <div class="page-partners" id="goto-contact">
      <div class="wrapper">
        <div class="left-content">
          <h1>Partners</h1>
          <p>비트베리는 다양한 파트너들과 함께 실제 사용 사례를 만들어가고 있습니다.</p>

          <img class="hide-on-mobile" src="../images/home/ic-partners-logos@2x.png"/>
          <img class="hide-on-pc mobile-logos" src="../images/home/ic-partners-logos-m@2x.png"/>
        </div>

        <div class="right-content hide-on-mobile">
          <img src="../images/home/im-review.png">

          <a class="partner-interview-btn" href="/home/business?menu=partner_story&category=all">
            파트너 후기 보러가기
            <img class="arrow-w" src="../images/home/ic-go-w@2x.png" width="13" height="20">
            <img class="arrow-g" src="../images/home/ic-go-g@2x.png" width="13" height="20">
          </a>
        </div>

        <a class="partner-interview-mobile-btn hide-on-pc" href="/home/business?menu=partner_story&category=all">
          파트너 후기 보러가기
          <img src="../images/home/ic-arrow-link@2x.png" width="24" height="16">
        </a>
      </div>
    </div>

    <!-- 비트베리 지금 사용해보세요 -->
    <div class="page page-use-it-now hide-on-mobile">
      <div class="wrapper">
        <h3>비트베리, 지금 사용해보세요.</h3>
        <div class="store-buttons">
          <a href="https://play.google.com/store/apps/details?id=com.rootone.wallet">
            <div class="store-btn">
                <div class="button-liner">
                  <img class="google-logo" :src="require('../images/intro/im-play-store@3x.png')"/>
                  <span>Google Play</span>
                </div>
            </div>
          </a>
          <a href="https://itunes.apple.com/kr/app/id1411817291?l=ko&ls=1&mt=8">
            <div class="store-btn">
                <div class="button-liner">
                  <img class="apple-logo" :src="require('../images/intro/im-app-store@3x.png')"/>
                  <span>App Store</span>
                </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <home-footer style="padding-bottom: 20px;"/>
  </div>
</template>

<script>
  import 'slick-carousel';
  import GlobalNavHeader from "./global_nav_header";
  import HomeFooter from "./home_footer";
  export default {
    components: {HomeFooter, GlobalNavHeader},
    props: [''],
    data() {
      return {
        scrolled: false,
        logoImageUrl: require('../images/intro/im-logo-bitberry@3x.png'),
        menuExpanded: false,
        menuImageUrl: require('../images/intro/ic-menu@3x.png'),
        serviceIntroPdfUrl: 'https://s3.ap-northeast-2.amazonaws.com/bitberry/shared/bitberry_service_intro_190226.pdf',
        features: [
          {
            name: '카카오톡',
            active: true,
            src: require('../images/intro/im-login@2x.png'),
            index: 0,
            desc: "카카오톡 계정으로 간편하게 원탭 로그인이 가능합니다."
          },
          {
            name: '지문인식',
            active: false,
            src: require('../images/intro/im-fingerprint@2x.png'),
            index: 1,
            desc: '지문인식으로 간편하고 안전하게 송금이 가능합니다. (iOS 얼굴인식 가능)'
          },
          {
            name: '카카오페이',
            active: false,
            src: require('../images/intro/im-kakaopay@2x.png'),
            index: 2,
            desc: '한 번의 인증으로 간편하고 안전하게 2FA를 충족합니다.'
          },
        ],
        featureDesc: '',
      }
    },
    computed: {},
    mounted: function () {
      window.addEventListener('scroll', this.handleScroll);

      this.featureDesc = this.features[0].desc;
      $('.single-item').slick(
        {
          dots: false,
          arrows: false,
        }
      );
    },
    beforeMount: function () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      markAsActiveFeature: function (item) {
        this.features.forEach((x) => {
          if (x.name === item.name) {
            $('.single-item').slick('slickGoTo', x.index);
            x.active = true;
            this.featureDesc = x.desc;
          } else {
            x.active = false;
          }
        })
      },
      handleScroll: function (event) {
        this.scrolled = ((window.pageYOffset || window.scrollTop) - (window.clientTop || 0) > 20);
      },
      toggleMenu() {
        this.menuExpanded = !this.menuExpanded;
        if (this.menuExpanded) {
          this.menuImageUrl = require('../images/intro/ic-x@3x.png');
        } else {
          this.menuImageUrl = require('../images/intro/ic-menu@3x.png');
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  @import '../stylesheets/common';
  @import '../stylesheets/home';

  #index {

    * {
      box-sizing: border-box;
      overflow: hidden;
    }

    $wrapper-max-width: 1080px;
    $font-apple-gothic: "Apple SD Gothic Neo", sans-serif;

    font-family: 'Nanum Barun Gothic', sans-serif;
    font-weight: lighter;

    @mixin device-image {
      .image {
        display: inline-block;
        padding: 30px;

        .device-inner-image {
          width: 253px;
          height: 420px;
          box-shadow: 0px 10px 15px rgba(222, 222, 222, 0.6);
        }

        @include mobile-page {
          order: 1;

          .device-inner-image {
            width: 285px;
            height: auto;
          }
        }
      }
    }

    @mixin wrapper-attr {
      margin: 0 auto;
      max-width: $wrapper-max-width;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      word-break: keep-all;

      @include mobile-page {
        /*padding: 30px 0;*/
        /*max-width: 340px;*/
      }
    }

    %content-desc-style {
      color: #555555;
      text-align: left;
      line-height: 1.6rem;
      margin-top: 20px;
      font-weight: 300;
    }

    @mixin mobile-h2 {
      text-align: center;
      font-size: 28px;
      line-height: 1.36em;
    }

    @mixin mobile-arrow-btn {
      display: flex;
      width: 100%;
      margin-top: 60px;
      justify-content: space-between;
      align-items: center;

      a {
        color: $green;
        font-weight: 300;
        text-decoration: none;
      }

      background-color: transparent;
      border: 1px $green solid;
      height: 50px;
    }

    @mixin mobile-border-btn($font-color, $border-color) {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      color: $font-color;
      font-weight: 300;
      text-decoration: none;
      background-color: transparent;
      border: 1px $border-color solid;
      height: 50px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .bottom-app-download {
      position: fixed;
      bottom: 54px;
      left: 10%;
      right: 10%;
      border-radius: 42px;
      border: solid 3px #ffffff;
      color: white;
      z-index: 100;
      background-color: $green;
      font-size: 18px;
      padding: 18px 0;
      font-weight: bold;
      text-align: center;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;

      &.scrolled {
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0;
        background-color: rgba($green, 1);
        border: none;
      }
    }

    .page-benefits, button {
      a {
        color: white;
      }
    }

    button {
      a {
        text-decoration: none;
      }
    }

    *::selection {
      color: white;
      background-color: rgba(45, 162, 82, 0.8);
    }

    %nav-shared {
      position: fixed;
      height: 80px;
      width: 100%;
      z-index: 10;
      background-color: white;
      opacity: 1;
      box-sizing: border-box;
      display: block;
      vertical-align: middle;
      line-height: 80px;
    }
    nav {
      @extend %nav-shared;

      &.top {
        background-color: transparent;
        color: white;
      }

      @include mobile-page {
        &.top {
          background-color: transparent;
          display: none;
          box-shadow: none;
          transform: translate3d(0px, -2px, 0px);
          color: $green;
        }
      }

      a {
        display: inline-block;
        text-align: left;
        margin-left: 46px;
        font-weight: normal;

        @include mobile-page {
          margin-left: 20px;
        }

        img {
          vertical-align: middle;
          width: 121px;
          height: 19px;

          @include mobile-page {
            width: 97px;
            height: 15px;
          }
        }
      }

      .menu-expanded {
        display: flex;
        flex-direction: column;
        background-color: white;
        align-items: center;

        a {
          margin: 4px 0;
          text-align: center;
          min-width: 195px;
          height: 38px;
          line-height: 38px;
          color: $green;
          text-decoration: none;
        }

        &:last-child {
          padding-bottom: 15px;
        }
      }

      #menu {
        float: right;
        margin: 0 50px 0 0;

        @include mobile-page {
          margin: 0 22px 0 0;
        }

        .item {
          font-size: 15px;
          list-style: none;
          display: inline-block;
          margin-left: 18px;
          cursor: pointer;
          line-height: 16px;
          color: white;

          @include mobile-page {
            font-size: 14px;
            margin: 0;
          }

          a {
            text-decoration: none;
            color: $green;
            font-weight: 500;

            &.transparent {
              color: white;
            }
            @include mobile-page {
              margin-left: 16px;
            }
          }
        }
      }
    }

    .mt70 {
      margin-top: 50px;
    }

    .cover {
      position: relative;
      width: 100%;
      height: 900px;
      background-size: cover;
      background: $green url('../images/intro/im-main.png') no-repeat 50% 50%;

      @include mobile-page {
        height: 860px;
        background: $green url('../images/intro/im-bitberry-m.png') no-repeat bottom;
      }

      .content {
        position: relative;
        max-width: $body-max-width;
        margin: 0 auto;
        top: 440px;
        left: 89px;

        @include mobile-page {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          top: 88px;
          left: 0px;
        }

        h1 {
          color: white;
          font-size: 38px;
          font-weight: lighter;
          margin-bottom: 15px;

          @include mobile-page {
            font-size: 29px;
            max-width: 214px;
            margin-bottom: 11px;
          }
        }

        .bitberry-logo {
          width: 231px;
          height: 36px;
          margin-left: 5px;
          margin-bottom: 50px;

          @include mobile-page {
            margin: 0;
            width: 206px;
            height: 32px;
          }
        }
      }

      p {
        position: absolute;
        bottom: 19px;
        right: 20px;
        color: #C0E3CB;
        font-size: 12px;
        font-family: $font-apple-gothic;
        font-weight: 300;
        padding-right: 100px;

        @include mobile-page {
          display: none !important;
        }
      }
    }

    .store-buttons {
      display: flex;

      .store-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 0.7px #ffffff;
        width: 224px;
        height: 59px;
        font-family: $font-apple-gothic;
        font-size: 22px;
        font-weight: lighter;
        letter-spacing: 1.8px;
        color: white;
        background-color: rgba(0, 0, 0, 0.0);
        margin-right: 20px;

        span {
          text-align: center;
          line-height: 21px;
          margin-left: 2px;
        }

        .google-logo, .apple-logo {
          width: 20px;
          height: 21px;
          margin-right: 12px;
        }

        .google-logo {
          margin-bottom: 1px;
        }

        .apple-logo {
          margin-bottom: 3px;
        }
      }
    }

    .page {
      overflow: hidden;
    }

    .page-gradient-wrapper {
      background-image: linear-gradient(to bottom, $black2, #474747);
    }

    .bitberry-text {
      color: white;
      font-size: 52px;
      font-weight: bold;
    }

    .page-easy-login-auth, .page-security {
      ul {
        margin: 50px 0 0 0;
        padding: 0;

        li {
          width: 122px;
          list-style-type: none;
          color: rgba(74, 74, 74, 0.4);
          display: inline-block;
          padding: 15px 0;
          border-bottom: solid 1px #b7b7b7;
          cursor: pointer;
          @include mobile-page {
            width: 100px;
          }
        }
      }

      li.active {
        font-weight: normal;
        padding-bottom: 15px;
        color: $green;
        border-bottom: 1px solid $green;
      }
    }

    .page-easy-login-auth {
      padding-top: 110px;
      text-align: center;
      background-color: $beige;

      @include mobile-page {
        padding-top: 0px;
      }

      .wrapper {
        @include wrapper-attr;
        @include device-image;

        .right-panel {
          margin-left: 30px;

          @include mobile-page {
            order: 0;
            margin-right: 0px;
          }
          width: 366px;

          h2 {
            text-align: left;
            font-size: 40px;
            font-weight: lighter;
            line-height: 56px;
            color: #333333;

            @include mobile-page {
              @include mobile-h2;
            }
          }

          .feature-desc {
            height: 50px;
          }

          p {
            @extend %content-desc-style;

            @include mobile-page {
              margin-bottom: 42px;
              text-align: center;
            }
          }
        }
      }
    }

    @mixin service-feature-fonts {
      h2 {
        font-size: 40px;
        font-weight: lighter;
        line-height: 56px;
        color: #333333;
        margin-bottom: 20px;
      }

      p {
        color: #555555;
        line-height: 1.6rem;
        font-weight: 300;
      }
    }

    @mixin service-feature-wrapper {
      margin-left: 3px;
      margin-right: 3px;
      padding-top: 120px;
    }

    .service-feature-container {
      display: flex;
      justify-content: center;
      background-color: $beige;
    }

    .phone-number-transfer-wrapper {
      @include service-feature-wrapper;
      @include device-image;

      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: $body-max-width - 180;

      @include mobile-page {
        padding-top: 90px;
        flex-direction: column;
        align-items: center;

        img {
          order: 1;
          margin-top: 23px;
        }
      }

      img {
        flex-grow: 1;
      }

      .content {
        @include service-feature-fonts;
        display: flex;
        max-width: 386px;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;

        @include mobile-page {
          text-align: center;
          padding-left: 30px;
          padding-right: 30px;
          /*width: 290px;*/

          h2 {
            @include mobile-h2;
            margin-bottom: 20px;
          }
        }
      }
    }

    .page-easy-login-auth-wrapper {
      @include service-feature-wrapper;
      @include device-image;

      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: $body-max-width - 200;

      @include mobile-page {
        padding-top: 90px;
        flex-direction: column;
        align-items: center;

        .image {
          order: 1;
          margin-top: 23px;
        }
      }

      .content {
        @include service-feature-fonts;
        display: flex;
        padding-top: 50px;
        flex-direction: column;

        @include mobile-page {
          text-align: center;
          justify-content: center;
          align-items: center;
          padding-top: 0px;
          padding-left: 30px;
          padding-right: 30px;

          h2 {
            @include mobile-h2;
          }
        }

        .select-desc {
          max-width: 340px;

          ul {
            display: flex;
            margin-top: 40px;
            padding: 0;

            li {
              flex-grow: 1;
              list-style-type: none;
              color: rgba(74, 74, 74, 0.4);
              display: inline-block;
              padding: 15px 0;
              border-bottom: solid 1px #b7b7b7;
              cursor: pointer;
              text-align: center;

              @include mobile-page {
                /*max-width: 100px;*/
              }
            }
          }

          li.active {
            font-weight: normal;
            padding-bottom: 15px;
            color: $green;
            border-bottom: 1px solid $green;
          }

          .feature-desc {
            padding-top: 20px;
          }
        }
      }
    }

    .security-wrapper {
      @include service-feature-wrapper;
      @include device-image;

      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: $body-max-width - 180;

      @include mobile-page {
        padding-top: 90px;
        flex-direction: column;
        align-items: center;

        img {
          order: 1;
          margin-top: 23px;
        }
      }

      .content {
        @include service-feature-fonts;
        display: flex;
        max-width: 386px;
        flex-direction: column;
        justify-content: center;

        @include mobile-page {
          text-align: center;
          width: 290px;

          h2 {
            @include mobile-h2;
            margin-bottom: 20px;
          }
        }
      }
    }

    .service-intro-download {
      display: flex;
      width: 1080px;
      height: 64px;
      background-color: $green;
      justify-content: center;
      align-items: center;
      line-height: 63px;
      font-family: "Nanum Barun Gothic", sans-serif;
      font-size: 20px;
      color: white;
      text-decoration: none;
      margin: 130px 3px 130px 3px;

      &:hover {
        background-color: transparent;
        border: 2px solid $green;
        cursor: pointer;
        color: $green;
        font-weight: 300;
      }

      .arrow-w {
        margin-left: 10px;
        /*vertical-align: middle;*/
      }

      .arrow-g {
        display: none;
        /*vertical-align: middle;*/
      }

      &:hover .arrow-w {
        display: none;
      }

      &:hover .arrow-g {
        display: inline;
        margin-left: 10px;
      }
    }

    .service-intro-mobile-download {
      @include mobile-border-btn($green, $green);
      width: 340px;
      margin: 40px 30px 90px;
    }

    .page-security {
      .wrapper {
        @include wrapper-attr;
        padding-top: 110px;
        /*padding-left: 60px;*/

        .content {
          width: 386px;
          display: inline-block;
          justify-self: center;
          align-self: center;

          h2 {
            text-align: left;
            font-size: 40px;
            font-weight: lighter;
            line-height: 56px;
            color: #333333;

            @include mobile-page {
              @include mobile-h2;
            }
          }

          p {
            @extend %content-desc-style;

            @include mobile-page {
              text-align: center;
              margin-bottom: 42px;
            }
          }
        }

        @include device-image;

        .device-image {
          .image {
            /*padding-left: 60px;*/
          }
        }
      }
    }

    .page-benefits {
      .wrapper {
        @include wrapper-attr;

        .content {
          width: 400px;
          display: inline-block;
          justify-self: center;
          align-self: center;

          @include mobile-page {
            order: 0;
          }

          h2 {
            margin-top: 30px;
            color: white;
            font-size: 40px;
            line-height: 53px;
            font-weight: lighter;

            @include mobile-page {
              @include mobile-h2;
            }
          }

          p {
            color: white;
            font-size: 15px;
            font-weight: 300;

            br {
              @include mobile-page {
                display: none;
              }
            }
            @include mobile-page {
              /*width: 309px;*/
              display: block;
              //margin: 0 auto 30px auto;
              text-align: center;
            }
          }

          .airdrop-desc {
            color: #979797;
          }
        }

        @include device-image;
      }
    }

    .bitberry-business-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: $black2;
      padding: 110px 30px;

      p {
        color: #ffffff;
        line-height: 1.6rem;
        font-size: 16px;
        font-weight: lighter;
        text-align: center;
        margin-top: 20px;
      }

      @include mobile-page {
        padding: 70px 40px 60px;


        br {
          display: none;
        }

        p {
          line-height: 25px;
        }
      }

      .bitberry-business-title-container {
        display: flex;
        justify-content: center;;

        img {
          width: 50%;
          height: 100%;
          /*padding: 4px;*/

          @include mobile-page {
            /*width: 160px;*/
            width: 70%;
            height: 100%;
          }
        }
      }
    }

    .page-bitberry-business {
      background-color: $black2;
      padding-top: 120px;
      padding-bottom: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile-page {
        padding-top: 70px;
        padding-bottom: 30px;
      }

      h1, p {
        font-weight: lighter;
        strong {
          font-weight: bolder;
        }
        font-size: 40px;
        color: white;
        text-align: center;

        img {
          height: 32px;
          padding: 2.5px;
        }
      }

      p {
        color: #ffffff;
        line-height: 1.6rem;
        margin-top: 20px;
        font-size: 16px;
        font-weight: lighter;

        &.desc {
          margin: 0 30px;
        }
      }

      @include mobile-page {
        height: 180px;

        br {
          display: none;
        }

        .wrapper {
          img {
            height: 23px;
          }

          h1 {
            font-size: 30px;
          }

          p {
            margin: 16px 20px;
            line-height: 25px;
          }
        }
      }
    }

    .transparent-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px white;
      width: 63px;
      height: 34px;
      border-radius: 0px;
      color: white;
      font-size: 16px;
      line-height: normal;
      letter-spacing: normal;
      font-weight: 300;
      background-color: rgba(0, 0, 0, 0);

      @include mobile-page {
        margin: 0 auto;
        width: 60px;
        height: 32px;
        font-size: 14px;
      }
    }

    .page-business-partner-account {
      .wrapper {
        @include wrapper-attr;
        max-width: $body-max-width - 300;

        @include mobile-page {
          padding-left: 30px;
          padding-right: 30px;
        }

        img {
          width: 50%;
          height: auto;
          max-width: 304px;

          @include mobile-page {
            width: 100%;
          }
        }

        .content {
          max-width: 400px;
          /*display: inline-block;*/
          /*padding-left: 70px;*/

          h2 {
            margin-top: 30px;
            color: white;
            font-size: 40px;
            line-height: 53px;
            font-weight: lighter;

            @include mobile-page {
              @include mobile-h2;
            }
          }

          p {
            color: white;
            font-size: 15px;
            line-height: 1.6rem;
            font-weight: lighter;
            margin-top: 16px;

            br {
              @include mobile-page {
                display: none;
              }
            }

            @include mobile-page {
              /*width: 309px;*/
              display: block;
              text-align: center;
            }
          }

          @include mobile-page {
            p {
              text-align: center;
              margin-bottom: 30px;
            }
          }
        }
      }
    }

    .page-airspot-service {
      .wrapper {
        @include wrapper-attr;
        max-width: $body-max-width - 100;
        margin-top: 110px;
        flex-wrap: nowrap;

        @include mobile-page {
          margin-top: 70px;
          padding-left: 30px;
          padding-right: 30px;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-around;
        }

        img {
          width: 33%;
          height: auto;
          max-width: 522px;
          margin-left: 30px;
          margin-right: 10px;

          @include mobile-page {
            order: 1;
            width: 100%;
            height: 100%;
            margin-left: 50px;
            margin-top: 30px;
          }
        }

        .content {
          max-width: 430px;
          display: flex;
          flex-direction: column;

          @include mobile-page {
            order: 0;
            margin: 0;

            p {
              text-align: center;
            }
          }

          h2 {
            margin-top: 30px;
            color: white;
            font-size: 40px;
            line-height: 53px;
            font-weight: lighter;

            @include mobile-page {
              @include mobile-h2;
            }
          }

          p {
            color: white;
            font-size: 15px;
            line-height: 1.6rem;
            font-weight: lighter;
            margin-top: 16px;

            br {
              @include mobile-page {
                display: none;
              }
            }

            @include mobile-page {
              /*width: 309px;*/
              display: block;
              text-align: center;
            }
          }

          .airdrop-desc {
            color: #979797;
          }
        }
      }
    }

    .page-v2-api {
      .wrapper {
        @include wrapper-attr;
        max-width: $body-max-width - 100;
        margin-top: 110px;
        flex-wrap: nowrap;

        @include mobile-page {
          margin-top: 80px;
          padding-left: 30px;
          padding-right: 30px;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-around;
        }

        img {
          width: 30%;
          height: auto;
          max-width: 401px;
          margin-right: 10px;

          @include mobile-page {
            width: 86%;
            height: auto;
            margin-right: 0px;
            margin-top: 10px;
          }
        }

        .content {
          width: 400px;
          margin-left: 24px;
          /*padding-left: 70px;*/
          /*margin-right: 10px;*/

          @include mobile-page {
            padding: 0;
            margin-left: 0px;
          }

          h2 {
            margin-top: 30px;
            color: white;
            font-size: 40px;
            line-height: 53px;
            font-weight: lighter;

            @include mobile-page {
              @include mobile-h2;
            }
          }

          p {
            color: white;
            font-size: 15px;
            line-height: 1.6rem;
            font-weight: lighter;
            margin-top: 16px;

            br {
              @include mobile-page {
                display: none;
              }
            }

            @include mobile-page {
              /*width: 309px;*/
              display: block;
              text-align: center;
            }
          }

          @include mobile-page {
            p {
              text-align: center;
              margin-bottom: 30px;
            }
          }
        }
      }
    }

    .page-alliance {
      .wrapper {
        @include wrapper-attr;
        max-width: $body-max-width - 40;
        margin-top: 110px;
        flex-wrap: nowrap;

        @include mobile-page {
          margin-top: 80px;
          padding-right: 30px;
          padding-left: 30px;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-around;
        }

        img {
          width: 40%;
          height: auto;
          max-width: 550px;
          margin-left: 28px;
          margin-right: 18px;
          /*margin-right: 70px;*/
          /*margin-bottom: 30px;*/

          @include mobile-page {
            width: 100%;
            height: 100%;
            margin: 25px 0 0px 0px;
            order: 1;
          }
        }

        .content {
          max-width: 400px;
          margin-right: 4px;

          @include mobile-page {
            margin: 0;
          }

          h2 {
            margin-top: 30px;
            color: white;
            font-size: 40px;
            line-height: 53px;
            font-weight: lighter;

            @include mobile-page {
              @include mobile-h2;
            }
          }

          p {
            color: white;
            font-size: 15px;
            line-height: 1.6rem;
            font-weight: lighter;
            margin-top: 16px;

            br {
              @include mobile-page {
                display: none;
              }
            }

            @include mobile-page {
              /*width: 309px;*/
              display: block;
              text-align: center;
            }
          }
        }
      }
    }

    .business-partner-btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .business-partner-btn {
      display: flex;
      width: 1080px;
      height: 64px;
      background-color: $black2;
      border: 2px solid $black2;
      justify-content: center;
      align-items: center;
      line-height: 63px;
      font-family: "Nanum Barun Gothic", sans-serif;
      font-size: 20px;
      color: white;
      text-decoration: none;
      margin: 130px 3px 130px 3px;

      &:hover {
        background-color: white;
        cursor: pointer;
        color: $black2;
        font-weight: 300;
      }

      .arrow-w {
        margin-left: 10px;
        margin-bottom: 2px;
      }

      .arrow-b {
        display: none;
      }

      &:hover .arrow-w {
        display: none;
      }

      &:hover .arrow-b {
        display: flex;
        margin-left: 10px;
        margin-bottom: 2px;
      }
    }

    .partner-page-mobile-btn {
      @include mobile-border-btn(white, white);
      width: 340px;
      margin: 50px 33px 70px;

      img {
        width: 24px;
        height: 16px;
      }
    }

    .page-partners {
      display: flex;
      font-family: 'Nanum Barun Gothic', sans-serif;
      background-color: $beige;
      justify-content: center;

      @include mobile-page {
        padding-bottom: 60px;
      }

      .wrapper {
        width: $body-max-width;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 90px 10px 90px 3px;

        @include mobile-page {
          flex-direction: column;
          padding-top: 0px;
          padding-bottom: 0px;
          margin-top: 60px;
          margin-left: 30px;
          margin-right: 30px;
        }

        .left-content {
          display: flex;
          /*flex-wrap: wrap;*/
          flex-grow: 1;
          flex-direction: column;
          margin-right: 20px;
          max-width: 600px;

          @include mobile-page {
            margin: 0 auto;
            text-align: center;
            justify-content: center;
            align-items: center;
          }

          h1 {
            font-size: 40px;
            font-weight: 300;
            color: #333333;
            text-align: left;
            margin-bottom: 18px;

            @include mobile-page {
              font-size: 35px;
              text-align: center;
              margin-bottom: 4px;
            }
          }

          p {
            font-weight: lighter;
            margin-bottom: 25px;
            color: #555555;

            @include mobile-page {
              line-height: 1.5;
              margin: 15px auto 20px;
            }
          }

          img {
            margin-top: 20px;
            margin-bottom: 30px;
            width: 70%;
            height: 100%;

            @include mobile-page {
              width: 80%;
              height: 100%;
              margin-top: 10px;
              margin-bottom: 40px;
            }
          }
        }

        .right-content {
          display: flex;
          position: relative;

          img {
            width: 100%;
            height: auto;
          }

          .partner-interview-btn {
            display: flex;
            position: absolute;
            margin: 0 auto;
            width: 95%;
            left: 0;
            right: 0;
            bottom: 22px;
            background-color: $green;
            border: solid 2px #2da252;
            font-family: 'Nanum Barun Gothic', sans-serif;
            font-size: 18px;
            color: black;
            text-align: center;
            color: white;
            font-weight: 300;
            text-align: center;
            justify-content: center;
            padding-top: 2.9%;
            padding-bottom: 2.9%;

            &:hover {
              background-color: white;
              border: 2px solid white;
              cursor: pointer;
              color: $green;
              font-weight: bold;
            }

            .arrow-w {
              width: 13px;
              height: 20px;
              margin-left: 10px;
            }

            .arrow-g {
              width: 13px;
              height: 20px;
              display: none;
            }

            &:hover .arrow-w {
              display: none;
            }

            &:hover .arrow-g {
              display: inline;
              margin-left: 10px;
            }
          }
        }

        .partner-interview-mobile-btn {
          @include mobile-arrow-btn;

          font-weight: 300;
          text-decoration: none;
          padding-left: 16px;
          padding-right: 16px;
          max-width: 340px;
          color: $green;
          font-weight: 300;
          text-decoration: none;
          margin: 0 auto;
        }
      }
    }

    .page-use-it-now {
      background-color: $green;

      .wrapper {
        max-width: $body-max-width;
        margin: 0 auto;
        height: 140px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 3px;
        padding-right: 3px;

        h3 {
          color: white;
          font-family: 'Nanum Barun Gothic', sans-serif;
          font-size: 1.2rem;
          font-weight: 300;
        }

        .store-btn {
          width: 200px;
          height: 56px;
          font-size: 18px;
          font-weight: normal;

          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .page .wrapper {
      @include mobile-page {
        height: auto;
      }
    }

    @include desktop-page {
      .hide-on-pc {

      }
    }
    @include mobile-page {
      .hide-on-mobile {
        display: none !important;
      }
    }
  }
</style>