<template>
  <popup @close="$emit('close')">
    <p class="description">{{ desc }}</p>
    <ul class="share-items">
      <li class="share-item copy-btn" :data-clipboard-text="shareUrl">
        <img src="../../images/airspot/lucky_box/ic-link-copy@2x.png"
             alt="copy"><span>링크복사</span>
      </li>
      <li class="share-item" @click.prevent="kakaotalk">
        <img src="../../images/airspot/lucky_box/ic-kakaotalk@2x.png"
             alt="kakaotalk"><span>카카오톡</span>
      </li>
      <li class="share-item" @click.prevent="facebook">
        <img src="../../images/airspot/lucky_box/ic-facebook@2x.png"
             alt="facebook"><span>페이스북</span>
      </li>
    </ul>
    <toast ref="toast"></toast>
  </popup>
</template>

<script>
  import ClipboardJS from 'clipboard';
  import Popup from './popup';
  import Toast from './toast';

  export default {
    props: ['desc', 'shareUrl', 'shareTitle', 'shareDesc', 'shareImageUrl', 'shareKakaoImageUrl'],
    components: { Popup, Toast },
    data() {
      return {
        phoneNumber: ''
      };
    },
    methods: {
      kakaotalk() {
        Kakao.Link.sendDefault({
          objectType: 'feed',
          content: {
            title: this.shareTitle,
            description: this.shareDesc,
            imageUrl: this.shareKakaoImageUrl,
            link: {
              webUrl: this.shareUrl,
              mobileWebUrl: this.shareUrl,
            }
          },
          buttons: [
            {
              title: '에어스팟 열기',
              link: {
                webUrl: this.shareUrl,
                mobileWebUrl: this.shareUrl,
              }
            },
          ]
        });
      },
      facebook() {
        FB.ui({
          method: 'share',
          href: this.shareUrl,
        }, function(response){});
      }
    },
    mounted() {
      let that = this;

      let clipboard = new ClipboardJS('.copy-btn');
      clipboard.on('success', function(e) {
        e.clearSelection();

        that.$refs.toast.show('복사 완료');
      });
    }
  }
</script>

<style scoped lang="scss">
  @import "../../stylesheets/airspot";

  .description {
    font-family: $s-core-6;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: -0.3px;
    text-align: center;
    color: $gray2;
    margin-top: 14px;
    margin-bottom: 0;
    white-space: pre-line;
  }

  .share-items {
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 20px 0 -10px;

    .share-item {
      display: inline-block;
      margin-left: 24px;

      &:hover {
        cursor: pointer;
      }

      &:first-child {
        margin-left: 0;
      }

      img {
        width: 54px;
        height: 54px;
        display: block;
        margin: 0 auto;
      }

      span {
        font-size: 13px;
        font-weight: bold;
        color: $green;
        letter-spacing: -0.3px;
        margin-top: 6px;
        display: inline-block;
      }
    }
  }
</style>