<template>
  <div class="notice-index">
    <notice-list-item
      v-for="notice in initial_notices"
      :key="notice.id"
      :notice="notice"
    >
    </notice-list-item>
  </div>
</template>

<script>
  import NoticeListItem from "./notice_list_item";

  export default {
    props: ['initial_notices'],
    data() {
      return {
        notices: this.initial_notices
      }
    },
    components: {NoticeListItem},
  }
</script>