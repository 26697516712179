<template>
  <main>
    <article class="notice-article">
      <h1 class="title">{{ notice.title }}</h1>
      <p class="date-item">{{ notice.created_at | human_date }}</p>
      <hr>
      <div class="content" v-html="notice.content"></div>
      <div>
        <img :src="notice.image_url" class="image"/>
      </div>
    </article>
  </main>
</template>

<script>
  import daumtools from 'lib/daumtools';

  export default {
    props: ['initial_notice', 'notice_category'],
    data() {
      return {
        notice: {...this.initial_notice}
      }
    },
    mounted() {
      daumtools.sendDataToMobileApp(this.notice_category);
    }
  }
</script>
<style scoped lang="scss">
  @import '../../stylesheets/application/list_pages';

  .notice-article {
    @include list-detail();
  }
</style>