<template>
  <div class="footer-container">
    <footer class="footer-main mobile-container">
      <div class="footer-left">
        <img class="footer-logo" src="../images/home/im-logo-rootone@2x.png"/>
        <p>(주)루트원소프트&nbsp;&nbsp;|&nbsp;&nbsp;대표 : 김동희</p>
        <p>사업자 등록번호: 811-87-00338</p>
        <p>강남구 논현로63길 71층, 2층</p>
      </div>

      <div class="footer-right-desktop hide-on-mobile">
        <div>
          <h4>Company</h4>
          <a href="/home/about_us">About us</a>
          <a href="/home/about_us/bi_ci">BI / CI</a>
          <a href="/home/about_us/service_api">Document</a>
          <a href="/home/business">Business</a>
          <!-- <a href="/home/team">Team</a> -->
          <!-- <a href="/home/media?menu=articles">Press release</a> -->
          <a href="https://medium.com/bitberry" target="_blank">Blog posts</a>
          <a href="/home/contact">Careers</a>
        </div>

        <div>
          <h4>Legal</h4>
          <a href="/app_pages/terms" target="_blank">User Agreement</a>
          <a href="/app_pages/privacy" target="_blank">Privacy Policy</a>
        </div>

        <div>
          <h4>Support</h4>
          <a href="/home/contact">Help Center</a>
          <!-- <a href="https://biz.sodawallet.co.kr/fbbr/fbbr_guide.pdf">fBBR 가이드</a> -->
          <!-- <a href="https://biz.sodawallet.co.kr/fbbr/bitberry-real-release_201225.apk">비트베리 설치파일</a> -->
        </div>

        <div>
          <h4>Social</h4>
          <a href="https://medium.com/bitberry" target="_blank">Medium</a>
          <a href="https://www.youtube.com/watch?v=QKyxagwqTQo" target="_blank">YouTube</a>
          <!-- <a href="https://www.facebook.com/rootone1" target="_blank">Facebook</a> -->
        </div>
      </div>

      <div class="footer-right-mobile show-on-mobile">
        <div>
          <h4>Company</h4>
          <a href="/home/about_us">About us</a>
          <a href="/home/about_us/bi_ci">BI / CI</a>
          <a href="/home/about_us/service_api">Document</a>
          <a href="/home/business">Business</a>
          <a href="/home/team">Team</a>
          <a href="/home/media?menu=articles">Press release</a>
          <a href="https://medium.com/bitberry" target="_blank">Blog posts</a>
          <a href="/home/contact">Careers</a>
        </div>

        <div class="footer-right-wrap">
          <div>
            <h4>Legal</h4>
            <a href="../app_pages/terms" target="_blank">User Agreement</a>
            <a href="../app_pages/privacy" target="_blank">Privacy Policy</a>
          </div>

          <div style="margin-top:18px;">
            <h4>Support</h4>
            <a href="/home/contact">Help Center</a>
            <a href="https://biz.sodawallet.co.kr/fbbr/fbbr_guide.pdf">fBBR 가이드</a>
            <!-- <a href="https://biz.sodawallet.co.kr/fbbr/bitberry-real-release_201225.apk">비트베리 설치파일</a> -->
          </div>
        </div>

        <div>
          <h4>Social</h4>
          <a href="https://medium.com/bitberry" target="_blank">Medium</a>
          <a href="https://www.youtube.com/watch?v=QKyxagwqTQo" target="_blank">YouTube</a>
          <a href="https://www.facebook.com/rootone1" target="_blank">Facebook</a>
        </div>
      </div>

    </footer>
  </div>
</template>

<script>
  export default {
    props: [],
    data() {
      return {}
    },
  }
</script>

<style lang="scss">
  @import '../stylesheets/home';

  %title-shared {
    color: #ffffff;
  }

  %menu-shared {
    color: #b6b6b6;
    font-weight: normal;
    text-decoration: none;
  }

  .footer-container {
    width: 100%;
    background-color: #3f3f3f;
    font-family: 'AppleSDGothicNeo-Regular', sans-serif;

    .footer-logo {
      width: 69px;
      margin-bottom: 16px;
    }

    .footer-main {
      margin: 0 auto;
      max-width: $body-max-width + 100;
      display: flex;
      box-sizing: border-box;
      text-align: left;
      padding: 50px 50px 50px 50px;

      @include mobile-page {
        flex-direction: column;
        padding: 54px 24px 54px 24px;
      }

      h4 {
        @extend %title-shared;
        font-size: 15px;
        margin-bottom: 16px;

        @include mobile-page {
          font-size: 16px;
          margin-bottom: 12px;
        }
      }

      .footer-left {
        @extend %menu-shared;
        vertical-align: top;
        font-size: 13px;
        width: 50%;

        p {
          margin-bottom: 8px;
        }

        @include mobile-page {
          width: 100%;
          font-size: 12px;
          margin-bottom: 10px;
        };
      }

      .footer-right-desktop {
        display: flex;
        justify-content: space-between;
        vertical-align: top;
        width: 50%;

        a {
          @extend %menu-shared;
          display: block;
          font-size: 15px;
          margin: 0 0 8px 0;
        }
      }

      .footer-right-mobile {
        display: none;
        justify-content: space-between;
        vertical-align: top;
        width: 100%;
        font-size: 14px;
        margin-top: 44px;

        h4 {
          @extend %title-shared;
          font-size: 15px;
          margin-bottom: 8px;
        }

        a {
          @extend %menu-shared;
          display: block;
          margin: 0 0 8px 0;
        }

        .footer-right-wrap {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  @include mobile-page {

    .hide-on-mobile {
      display: none !important;
    }

    .show-on-mobile {
      display: flex !important;
    }
  }
</style>