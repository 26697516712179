<template>
  <div class="faq-index">
    <faq-list-item
      v-for="faq in initial_faqs"
      :key="faq.id"
      :faq="faq"
    >
    </faq-list-item>
  </div>
</template>

<script>
  import FaqListItem from "./faq_list_item";

  export default {
    props: ['initial_faqs'],
    data() {
      return {
        faqs: this.initial_faqs
      }
    },
    components: {FaqListItem},
  }
</script>