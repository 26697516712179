<template>
  <div class="wrapper">
    <div class="box-container">
      <img class="brand" src="../images/home/im-bitberry-2@2x.png"/>
      <div class="box">
        <img class="logo" :src="require('images/bitberryAppIcon@2x.png')" alt="">
        <div class="title">
          {{ $t('escrow.title')}}
        </div>
        <div class="sell-desc">
          {{ $t('escrow.selling_desc', {seller_name: seller_name}) }}
        </div>

        <div class="selling-info">
          {{ selling_cryptocurrency_name }}<br/>
          <div style="margin-top: 6px;">{{selling_amount | human_number}} {{selling_currency_code}}</div>
        </div>

        <div class="start-link" v-on:click="startLink">
          <div class="buy">{{ $t('escrow.buy')}}</div>
          <div class="buy-info">{{ $t('escrow.need_token_amount')}} {{ buying_total_amount | human_number }} {{buying_currency_code}}</div>
        </div>
      </div>

      <div class="more-link-container">
        <a class="more-link" href="https://bitberry.app">{{ $t('escrow.more_bitberry')}}</a>
        <img class="right-arrow" :src="require('images/icArrow16@2x.png')" alt="비트베리 더 알아보기">
      </div>

      <div class="update-info">
        {{ $t('escrow.need_latest_version')}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['escrow', 'phase'],
    data() {
      return {
        id: this.escrow.id,
        seller_name: this.escrow.seller_name,
        status: this.escrow.status,
        selling_cryptocurrency_name: this.escrow.selling_cryptocurrency_name,
        selling_currency_code: this.escrow.selling_currency_code,
        selling_amount: this.escrow.selling_amount,
        buying_cryptocurrency_name: this.escrow.buying_cryptocurrency_name,
        buying_currency_code: this.escrow.buying_currency_code,
        buying_total_amount: this.escrow.buying_total_amount,
      }
    },
    methods: {
      startLink() {
        let escrowId = this.id;
        let intentUri;

        if (this.phase === "production") {
          intentUri = "intent://escrow?id=" + escrowId + "#Intent;scheme=bitberry;package=com.rootone.wallet;end;"
        } else {
          intentUri = "intent://escrow?id=" + escrowId + "#Intent;scheme=bitberry;package=com.rootone.wallet.alpha;end;"
        }

        daumtools.web2app({
          urlScheme: "bitberry://escrow?id=" + escrowId,				// iphone : custom scheme
          intentURI: intentUri,	// android : intent URI
          appName: 'Bitberry', 								// application Name (ex. facebook, twitter, daum)
          storeURL: 'https://bitberry.app',									// app store URL
          willInvokeApp: function () {
          },					// function for logging
          onAppMissing: function () {
          },					// fallback function (default. move to appstore)
          onUnsupportedEnvironment: function () {
            alert('모바일에서 확인해주세요.')
          }		// fallback function
        });
      }
    },
    mounted() {
    }
  }
</script>
<style scoped lang="scss">
  @import '../stylesheets/admin/variables';

  .wrapper {
    font-family: 'Nanum Barun Gothic', sans-serif;
    background-color: $green;
    height: 100vh;
    text-align: center;

    .box-container {
      display: inline-block;
      text-align: left;
      max-width: 360px;
      min-width: 312px;

      .brand {
        width: 86px;
        height: 14px;
        display: block;
        padding-top: 21px;
        padding-bottom: 21px;
      }

      .box {
        position: relative;
        background-color: rgb(249, 249, 246);
        border-radius: 4px;
        padding-top: 44px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);

        .logo {
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 20px;
          width: 66px;
          height: 66px;
        }

        .title {
          text-align: center;
          font-size: 18px;
          color: $green;
          line-height: 1.3;
          font-weight: bold;
        }

        .sell-desc {
          text-align: center;
          margin-top: 20px;
          color: $gray2;
          font-size: 13px;
        }

        .selling-info {
          text-align: center;
          color: $black2;
          font-size: 15px;
          font-weight: 600;
          margin-left: 28px;
          margin-right: 28px;
          margin-top: 12px;
          background-color: $ivory2;
          border-radius: 5px;
          border: 1px $ivory solid;
          padding-top: 18px;
          padding-bottom: 18px;
        }

        .start-link {
          cursor: pointer;
          background-color: white;
          display: inline-block;
          width: 100%;
          text-align: center;
          margin-top: 26px;
          padding: 18px 0 15px 0;
          border-top: 1px solid rgb(225, 225, 222);
          border-radius: 0 0 4px 4px;
          text-decoration: none;

          .buy {
            font-weight: bold;
            color: $green;
            font-size: 16px;
          }

          .buy-info {
            color: $gray;
            font-size: 12px;
            font-weight: 300;
            margin-top: 7px;
          }
        }

        .desc {
          color: white;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: -0.1px;
          margin: 0 32px 16px 32px;
          font-weight: lighter;
        }
      }

      .more-link-container {
        text-align: center;
        margin-top: 34px;
        transform: translateX(7px);

        .more-link {
          color: white;
          font-size: 13px;
          line-height: 17px;
          letter-spacing: 0.2px;
          font-weight: 300;
          opacity: 0.8;
        }

        .right-arrow {
          vertical-align: middle;
          width: 16px;
          height: 16px;
          margin-left: -3px;
        }
      }

      .update-info {
        font-size: 13px;
        color: white;
        letter-spacing: 0.2px;
        font-weight: 300;
        margin-top: 28px;
        text-align: center;

      }
    }
  }
</style>