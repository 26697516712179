<template>
  <div class="privacy">
    <h1>개인정보 처리방침</h1>
    <p>
      루트원소프트(주)(이하 “회사”)는 이용자의 개인정보 보호를 매우 중요하게 생각하며 이용자가
      회사의 서비스 (암호화폐 지갑 서비스)를 이용하기 위해 회사에 제공한 개인정보 보호에 최선을
      다하고 있습니다. 이에 회사는 “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 및 “개인정보
      보호법” 등 개인정보 보호와 관련된 법령을 준수하고 있습니다.
    </p>

    <p>
      회사는 본 개인정보처리방침을 서비스 내에서 이용자가 언제든지 쉽게 확인할 수 있도록 하고
      있습니다. 본 개인정보처리방침은 관계 법령 및 회사의 내부 방침에 따라 변경될 수 있으며 개정
      시 비트베리 앱 통하여 개정 사항을 확인할 수 있습니다.
    </p>

    <h2>제1조 (개인정보 수집)</h2>
    <p>
      회사에서 수집하는 개인정보 항목은 다음과 같습니다.<br><br>
      1. 회원가입 또는 서비스 이용 시 수집하는 개인정보 항목<br>
      ① 회원가입 : 카카오 계정(이메일, 닉네임, 프로필 사진), 닉네임<br>
      ② 휴대전화번호 점유 인증: 휴대전화번호<br>
      ③ 본인 인증 : 이름, 휴대전화번호, 생년월일, 성별, 내/외국인 정보, 가입한 통신사,
      본인 확인 정보(CI, DI)
      (본인 인증 시 본인확인기관 등에 의한 별도의 동의절차가 진행됩니다.)<br>
      ④ 비트베리 친구 검색: 회원 휴대전화의 연락처에 저장된 휴대전화번호, 이름,
      비트베리 이용 여부<br>
      ⑤ 광고/마케팅(선택): 휴대전화번호, 이메일 주소
      (광고/마케팅 정보 수신은 선택적으로 동의 여부를 정할 수 있습니다.)<br><br>

      2. 서비스 이용 중 자동으로 수집되는 정보<br/>
      서비스 안정성 확보, 안전한 서비스 제공, 법률 및 서비스 이용약관 위반 행위 제한 등의 목적으로 서비스를 이용하는 과정에서 정보가 자동으로 생성 또는 수집될 수 있습니다.<br/>

      ① 서비스 이용 기록, 접속 로그, 거래기록, IP 정보, 쿠키, 불량 및 부정 이용 기록,
      모바일 기기 정보 (모델명, 이동통신사 정보, OS 정보, 화면 사이즈, 언어 및
      국가정보, 광고 ID, 디바이스 식별정보 등)<br>
      ② 서비스 및 서비스 어플리케이션에 대한 불법/부정 접근 행위 및 관련 기록,
      서비스 어플리케이션에 대한 접근 시도 기록, 서비스 및 서비스 어플리케이션의
      안전한 동작 환경 확인에 필요한 정보<br><br>

      3. 고객 상담을 위해 추가 수집되는 정보<br>
      ① 공통 : 휴대전화 번호, 카카오 계정<br>
      ② 휴대전화번호 변경 : 이동통신사 증빙 자료<br><br>

      4. 개인정보 수집 방법<br>
      ① 모바일 앱, 이메일, 고객센터, 전화 등을 통한 수집<br>
      ② 타 서비스에서 제 3 자 제공 동의를 통한 수집<br>
      ③ 자동 수집 장치를 통한 수집<br>
    </p>

    <h2>제 2 조 (개인정보의 처리 목적)</h2>
    <p>
      회사는 다음의 목적으로 이용자의 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의
      목적 이 외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법
      제 18 조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.<br><br>

      1. 이용자 정보 관리<br>
      ① 이용자 식별, 이용자 정보 관리, 각종 고지사항 전달<br>
      ② 신규서비스 개발, 다양한 서비스 제공<br>
      ③ 이용자 상담 및 민원처리, 고객 피해보상<br>
      ④ 비대면 본인 인증을 통한 탈퇴 처리, 개인정보 변경 및 송금 비밀번호 초기화 등<br><br>

      2. 서비스 제공<br>
      ① 본인 확인 및 암호화폐 송금 내역 확인<br>
      ② 암호화폐 입출금 처리 등 서비스 관리 전반에 관한 사항<br>
      ③ 거래소 및 타 지갑 연동 시 거래 건에 대한 정산 및 대사<br><br>

      3. 이벤트 정보 안내<br>
      ① 각종 이벤트 및 광고성 정보 제공<br>
      ② 신규 서비스 및 맞춤형 서비스 제공 등<br>
    </p>

    <h2>제 3 조 (개인정보의 보유 및 이용기간)</h2>
    <p>
      회사는 법령에 따른 개인정보 보유.이용기간 또는 이용자로부터 개인정보의 수집 시에 동의 받은
      개인정보 보유. 이용기간 내에서 개인정보를 처리합니다. 각각의 개인정보 보유 및 이용기간은
      다음과 같습니다.<br><br>

      1. 개인정보 보유 및 이용기간
    </p>
    <table>
      <th>
        구분
      </th>
      <th>
        보유 사유
      </th>
      <th>
        이용기간
      </th>
      <tr>
        <td>회원 가입</td>
        <td>회원 관리, 이용자 상담 및 민원 처리</td>
        <td>탈퇴 시</td>
      </tr>
      <tr>
        <td>
          추가 인증
        </td>
        <td>
          회원 탈퇴 처리, 휴대폰번호 변경 및 송금 비밀번호 초기화 등
        </td>
        <td>탈퇴 후 5 년</td>
      </tr>
      <tr>
        <td>암호 화폐 입출금</td>
        <td>회사가 제공하는 서비스 이용에 따른 본인 확인, 암호화폐 송금 이용 시 출금 처리 정보관리, 거래관계 여부 판단</td>
        <td>탈퇴 후 5 년</td>
      </tr>
    </table>
    <p>
      다만, 관계 법령 위반에 따른 수사.조사 등이 진행 중인 경우에는 해당 수사.조사 종료
      시까지 보유 및 이용합니다.<br><br>
      2. 서비스 제공에 따른 법률에 의한 보존<br>
      ① 이용자의 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성되면 지체없이
      파기합니다. 단, 서비스 부정 이용에 따른 분쟁을 방지하기 위한 내부 방침에 따라
      부정이용기록은 1 년간 보존할 수 있습니다.<br>

      ② 회사는 법령 및 ‘개인정보 유효 기간제’에 따라 1 년간 서비스를 이용하지 않는 장기 미
      이용 회원의 개인정보를 별도로 분리 저장/관리 또는 파기하고 있습니다. 분리 보관된
      개인정보는 4 년간 보관 후 지체없이 파기 합니다. 단, 법령에서 일정 기간 정보 보관
      의무를 부과하는 경우에는 해당 기간 동안 개인 정보를 안전하게 보관합니다.<br>
    </p>

    <table>
      <th>
        구분
      </th>
      <th>
        관련 법률
      </th>
      <th>
        이용기간
      </th>
      <tr>
        <td>계약 또는 청약철회 등에 관한 기록</td>
        <td rowspan="4">전자상거래 등에서의 소비자보호에 관련한 법률</td>
        <td>5년</td>
      </tr>
      <tr>
        <td>
          소비자의 불만 또는 분쟁 처리에 관한 기록
        </td>
        <td>3년</td>
      </tr>
      <tr>
        <td>표시. 광고에 관한 기록</td>
        <td>6개월</td>
      </tr>
      <tr>
        <td>오 입금에 관한 기록</td>
        <td>5년</td>
      </tr>
      <tr>
        <td>본인확인에 관한 기록</td>
        <td>정보통신망 이용촉진 및 정보보호 등에 관한 법률</td>
        <td>6개월</td>
      </tr>
      <tr>
        <td>로그인 기록</td>
        <td>통신비밀보호법</td>
        <td>3개월</td>
      </tr>
    </table>

    <h2>제 4 조 (개인정보처리의 위탁)</h2>
    <p>
      회사는 서비스 제공에 있어 필요한 업무 중 일부를 외부 업체가 수행하도록 개인 정보를
      위탁하고 있습니다. 그리고 위탁 받은 업체가 관계 법령을 위반하지 않도록 관리.감독하고
      있습니다. 회사가 개인정보 처리를 위탁한 업체는 다음과 같습니다.<br><br>

      1. 개인정보처리 수탁 업체
    </p>
    <table>
      <th>
        수탁 업체
      </th>
      <th>
        위탁 목적
      </th>
      <th>
        이용기간
      </th>
      <tr>
        <td>(주)인포뱅크</td>
        <td>휴대폰 번호 인증</td>
        <td rowspan="5">탈퇴 시 혹은 위탁 계약 종료시 까지</td>
      </tr>
      <tr>
        <td>(주)다날</td>
        <td>휴대폰 본인 인증 서비스</td>
      </tr>
      <tr>
        <td>
          (주)KCB
        </td>
        <td>휴대폰 본인 인증 서비스</td>
      </tr>
      <tr>
        <td>
          (주)두나무
        </td>
        <td>2 채널 추가 인증 (카카오페이 인증) 서비스</td>
      </tr>
      <tr>
        <td>
          (주)KT Hitel
        </td>
        <td>카카오 알림톡 발송 대행</td>
      </tr>
    </table>

    <h2>제 5 조 (이용자 및 법정대리인의 권리.의무와 행사 방법)</h2>

    <p>
      1. 이용자는 회사에 대해 언제든지 개인정보 열람.정정.삭제.처리 정지 요구 등의 권리를
      행사할 수 있습니다. 다만, 개인정보 보호법 제 35 조 제 4 항, 제 36 조 제 1 항, 제 37 조
      제 2 항 등 관계 법령에서 정하는 바에 따라 이용자의 개인정보 열람.정정.삭제.처리 정지
      요구 등의 권리 행사가 제한될 수 있습니다.<br>

      2. 이용자의 권리 행사는 개인정보 보호법 시행령 제 41 조 제 1 항에 따라 서면
      전자우편, FAX 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이
      조치하겠습니다.<br>

      3. 제 1 항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을
      통하여서 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제 11 호 서식에
      따른 위임장을 제출하여야 합니다.<br>

      4. 개인정보의 정정 및 삭제 요구 시 다른 법령에서 그 개인정보가 수집 대상으로 명시되어
      있는 경우에는 그 삭제를 요구할 수 없습니다.<br>

      5. 회사는 이용자 이용 권리에 따른 열람의 요구, 정정.삭제의 요구, 처리 정지의 요구 시
      열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br>
    </p>

    <h2> 제 6 조 (개인정보의 파기)</h2>

    <p>
      1. 회사는 개인정보 보유 기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을
      때는 지체없이 해당 개인정보를 파기합니다.<br>
      2. 이용자로부터 동의 받은 개인정보 보유 기간이 지나거나 처리 목적이 달성되었음에도
      불구하고 법령에 따라 개인정보를 계속 보조하여야 하는 경우에는, 해당 개인 정보를
      별도의 데이터베이스(DB) 로 옮기거나 보관장소를 달리하여 보존합니다.<br>
      3. 개인정보 파기 방법은 다음과 같습니다.<br>
      ① 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없도록 영구 삭제<br>
      ② 종이 문서에 기록.저장된 개인정보는 분쇄기로 분쇄하거나 소각
    </p>

    <h2>제 7 조 (개인정보의 기술적.관리적 보호 대책)</h2>
    <p>
      회사는 이용자들의 개인정보를 처리하면서 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지
      않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 마련하고 있습니다.<br><br>

      1. 내부관리계획 수립<br/>
      회사는 회사가 처리하는 개인정보의 안전한 관리를 위하여 내부 관리 계획을 수립하여
      시행하고 있습니다.<br>

      2. 이용자의 개인정보 암호화<br/>
      회사는 이용자의 비밀번호 및 은행 계좌번호 등의 개인정보를 안전한 암호알고리즘에
      의해 암호화하여 저장 및 관리하고 있습니다.<br>

      3. 해킹 등에 대비한 대책<br/>

      회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는
      것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로
      백업하고 있고, 최신 백신 프로그램을 이용하여 이용자들의 개인정보나 자료가
      누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서
      개인정보를 안전하게 전송할 수 있도록 하고 있습니다.<br>

      4. 개인정보 취급자의 최소화 및 교육<br/>
      회사는 개인정보 취급자를 업무 수행에 필요한 최소한으로 제한하며, 개인정보 취급자에
      대한 교육 등 관리적 조치를 통해 개인정보보호의 중요성을 인식시키고 있습니다.
    </p>

    <h2>제 8 조 (개인정보 자동수집 장치의 설치.운영 및 그 거부에 관한 사항)</h2>
    <p>
      회사는 이용자의 서비스 편의를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는
      쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트가 고객의 컴퓨터 브라우저(인터넷 익스플로러 등)에
      전송하는 소량의 정보입니다.<br><br>

      1. 쿠키의 사용 목적<br>
      쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 더욱 빠른 웹 환경을
      지원하며, 편리한 이용을 위해 서비스 개선에 활용합니다. 이를 통해 이용자는 더욱
      손쉽게 서비스를 이용할 수 있게 됩니다.<br><br>

      2. 쿠키의 설치.운영 및 거부<br>
      이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 언제든지 이러한 쿠키의 저장을
      거부하거나 삭제할 수 있습니다.<br><br>

      3. 쿠키 설정 거부 방법<br>
      ① Internet Explorer : 도구 메뉴 선택 > 인터넷 옵션 선택 > 개인정보 탭 클릭 > 고급 개인정보 설정 > 쿠키 수준 설정<br>
      ② Chrome : 설정 메뉴 선택 > 고급 설정 표시 선택 > 개인정보 및 보안 > 콘텐츠 설정 선택 > 쿠키 수준 설정<br>
      ③ Safari : 환경설정 메뉴 선택 > 개인정보 탭 선택 > 쿠키 및 웹 사이트 데이터 수준 설정<br>
    </p>

    <h2>제 9 조 (개인정보 보호 책임자 및 담당 부서)</h2>
    <p>
      1. 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를 지정하고 있습니다.<br>
      (개인정보 보호 책임자)<br>
      - 이름 : 손지훈<br>
      - 직책 : 개인정보 보호 책임자<br>
      - 전화번호 : 1522-9746<br>
      - 이메일 : : privacy@rootone.com<br>
      2. 이용자가 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원은 개인정보
      보호 책임자 및 담당 부서로 문의하실 수 있습니다. 회사는 이용자의 문의 사항에 대해
      답변 및 처리해드릴 것입니다.
    </p>

    <h2>제 10 조 (권익침해 구제방법)</h2>

    <p>
      개인정보 침해에 대한 피해구제, 상담 등이 필요할 경우 다음 기관에 문의하실 수 있습니다.<br/><br>
      개인정보 침해신고센터 (한국인터넷진흥원 운영)<br>
      - 홈페이지 : privacy.kisa.or.kr<br>
      - 전화 : (국번없이) 118<br><br>

      개인정보 분쟁조정위원회<br>
      - 홈페이지 : www.kopico.go.kr<br>
      - 전화 : (국번없이) 1833-6972<br><br>

      대검찰청 사이버범죄수사단<br>
      - 홈페이지 : www.spo.go.kr<br>
      - 전화 : 02-3480-3573<br><br>

      경찰청 사이버안전국<br>
      - 홈페이지 : cyberbureau.police.go.kr<br>
      - 전화 : (국번없이) 182<br><br>
    </p>

    <h2>제 11 조 (링크 사이트에 대한 책임)</h2>
    <p>
      회사는 이용자에게 다른 외부사이트로 연결되는 링크를 제공할 수 있습니다. 이 경우 회사는
      외부사이트에 대한 통제권이 없으므로 이용자가 외부사이트로부터 제공받은 서비스나 자료의
      유용성, 진실성, 적법성에 대해 책임 및 보증할 수 없으며, 링크된 외부사이트의
      개인정보처리방침은 회사와 무관하므로 해당 외부사이트의 정책을 확인하시기 바랍니다.
    </p>

    <h2>제 12 조 (개인정보처리방침 변경)</h2>
    <p>
      현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7 일 이전부터
      ‘공지사항’을 통해 알릴 것입니다. 다만, 개인정보의 수집 및 활용, 제 3 자 제공 등과 같이 이용자
      권리의 중요한 변경이 있을 때는 최소 30 일 이전에 알립니다.
    </p>

    <h2>부칙</h2>
    <p>
      본 개인정보처리방침 V.1.0.0 은 2018 년 8 월 20 일부터 적용됩니다.
    </p>

  </div>
</template>

<style scoped lang="scss">
</style>

<script>
  import daumtools from 'lib/daumtools';
  export default {
    props: [],
    data() {
      return {
      }
    },
    mounted() {
      daumtools.sendDataToMobileApp('비트베리 개인정보 처리방침');
    }
  }
</script>