<template>
  <div>
    <p>
      루트원소프트(주)는 비트베리 서비스 관련하여 아래의 목적을 개인정보를 수집 및 이용하며,
      회원의 개인정보를 안전하게 취급하는데 최선을 다합니다.<br><br>

      [필수 수집 항목]
    </p>

    <table>
      <th>
        목적
      </th>
      <th>
        항목
      </th>
      <th>
        보유기간
      </th>
      <tr>
        <td>서비스 내 이용자 식별 및 회원 관리</td>
        <td>카카오 계정(이메일, 닉네임, 프로필사진), 휴대폰 번호, 닉네임</td>
        <td rowspan="3">회원 탈퇴 후 즉시 삭제</td>
      </tr>
      <tr>
        <td>
          추가 인증 (본인 인증)
        </td>
        <td>
          이름, 휴대폰 번호, 생년월일, 성별, 내/외국인 정보, 가입한 통신사
        </td>
      </tr>
      <tr>
        <td>비트베리 친구 찾기 (송금 이용)</td>
        <td>휴대폰 연락처 목록</td>
      </tr>
    </table>

    <p>
      더 자세한 내용에 대해서는 <a :href="app_pages_privacy_url">개인정보처리방침</a>을 참고하시기 바랍니다.
    </p>
  </div>
</template>

<script>
  import daumtools from 'lib/daumtools';
  export default {
    props: ['app_pages_privacy_url'],
    data() {
      return {
      }
    },
    mounted() {
      daumtools.sendDataToMobileApp('개인 정보 수집 및 이용');
    }
  }
</script>