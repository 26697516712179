<template>
  <div>
    <h1>{{ $t('limit.heading') }}</h1>

    <table>
      <tbody>
      <tr>
        <th>{{ $t('limit.level') }}</th>
        <th>{{ $t('limit.limit') }}</th>
      </tr>
      <tr>
        <td>{{ $t('limit.level') + ' 1' }}</td>
        <td>{{ $t('limit.level1Amount') }}</td>
      </tr>
      <tr>
        <td>{{ $t('limit.level') + ' 2' }}</td>
        <td>{{ $t('limit.level2Amount') }}</td>
      </tr>
      <tr>
        <td>{{ $t('limit.level') + ' 3' }}</td>
        <td>{{ $t('limit.level3Amount') }}</td>
      </tr>
      </tbody>
    </table>

    <p>{{ $t('limit.description') }}</p>
  </div>
</template>

<script>
  import daumtools from 'lib/daumtools';

  export default {
    mounted() {
      daumtools.sendDataToMobileApp(this.$t('limit.title'));
    }
  }
</script>

<style scoped lang="scss">
  h1, th {
    font: {
      weight: normal;
    }
  }
  div p {
    color: #979797;
    white-space: pre-line;
  }
</style>