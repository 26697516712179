<template>
  <div class="wrapper">
    <div class="box-container">
      <h1 class="logo">
        Bitberry
      </h1>
      <div class="box">
        <img class="logo" width="80" height="80" :src="require('images/bitberryAppIcon@2x.png')" alt="">
        <p class="message">
          <strong>{{ from_user_nickname }}</strong>님이<br>
          <strong>{{ displayAmount }}</strong>를 보냈습니다.
        </p>
        <a class="receive-coin-link" href="https://bitberry.page.link/invite">코인 받기</a>
      </div>

      <p class="desc">
        코인 받기를 누르시면 다운로드 페이지로 이동합니다.<br>
        지금 비트베리를 다운받아 코인을 확인하세요.
      </p>

      <a class="more-link" href="https://bitberry.app">
        비트베리 더 알아보기
      </a>
      <img class="right-arrow" width="16" height="16" :src="require('images/icArrow16@2x.png')" alt="비트베리 더 알아보기">

      <div class="footer">
        <p>
          *가입시 해당 링크를 받은 휴대폰번호가 아닌 다른 번호를 입력하면 코인을 받을 수 없으니 입력에 유의하세요.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['invite'],
    data() {
      return {
        from_user_nickname: this.invite.from_user_nickname,
        amount: this.invite.amount,
        currency_code: this.invite.currency_code,
      }
    },
    computed: {
      displayAmount() {
        return `${this.amount} ${this.currency_code}`
      }
    },
    methods: {
    }
  }
</script>
<style scoped lang="scss">
  .wrapper {
    text-align: center;
    font-family: 'Nanum Barun Gothic', sans-serif;

    .box-container {
      display: inline-block;
      height: 100vh;
      width: 100%;
      position: relative;
      max-width: 600px;
      min-height: 600px;
      background-color: rgb(45, 162, 82);

      .logo {
        text-align: left;
        color: white;
        font-size: 18px;
        margin: 20px 24px;
        font-weight: bold;
        letter-spacing: 1px;
      }

      .box {
        background-color: rgb(249, 249, 246);
        margin: 0 24px 32px 24px;
        border-radius: 4px;
        padding-top: 48px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);

        .logo {
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 12px;
        }

        .message {
          text-align: center;
        }

        .receive-coin-link {
          background-color: white;
          display: inline-block;
          width: 100%;
          text-align: center;
          font-size: 17px;
          color: rgb(45, 162, 82);
          margin-top: 48px;
          padding: 20px 0 17px 0;
          font-weight: bold;
          border-top: 1px solid rgb(225, 225, 222);
          border-radius: 0 0 4px 4px;
          text-decoration: none;
        }
      }

      .desc {
        color: white;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.1px;
        margin: 0 32px 16px 32px;
        font-weight: lighter;
      }

      .more-link {
        color: white;
        text-decoration: underline;
        font-size: 13px;
        line-height: 17px;
        letter-spacing: 0.2px;
        font-weight: lighter;
        opacity: 0.8;
      }

      .right-arrow {
        vertical-align: middle;
      }

      .footer {
        background-color: rgb(33, 118, 57);
        padding: 23px 32px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        p {
          color: rgb(225, 225, 222);
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0;
          align-content: center;
          margin: 0;
          font-weight: lighter;
          opacity: 0.8;
        }
      }
    }
  }
</style>