<template>
  <div id="business">
    <global-nav-header/>
    <div class="picture-container">
      <img src="../images/home/im-business.png" class="hide-on-mobile picture" v-show="(menu === 'alliance' && windowWidth > 720)" />
      <img src="../images/home/im-business-m@3x.png" class="hide-on-pc picture" />
      <div class="video-container" v-show="(menu === 'partner_story' && windowWidth > 720)">
        <iframe :src="videos[0].url"
                allow="encrypted-media" allowfullscreen
                class="hide-on-mobile video-iframe"
        ></iframe>
      </div>
    </div>

    <div class="desc-container">
      <div class="nav">
        <div class="nav-alliance" v-on:click="show_alliance" :class="{ bold: menu === 'alliance'}">
          <div>
            Bitberry Business<br/>
            Platform
          </div>
        </div>
        <div class="nav-partner" v-on:click="show_partner_story" :class="{ bold: menu === 'partner_story'}">
          비즈니스 이야기
        </div>
      </div>

      <div class="contents-container">
        <div id="alliance" class="alliance-container" v-show="(menu === 'alliance' && windowWidth > 720) || windowWidth <= 720">
          <div class="alliance-title">
            지갑은 비트베리에 맡기고,<br/>
            비즈니스에 집중하세요.
          </div>

          <div class="hide-on-pc alliance-btn-container">
            <a class="alliance-btn" href="mailto:with@rootone.com" target="_blank">
              제휴 문의
            </a>
            <a class="alliance-btn" style="margin-left:8px;" href="http://bit.ly/token_listing" target="_blank">
              리스팅 신청
            </a>
          </div>

          <div class="mobile-divider hide-on-pc" style="margin-top:40px;margin-bottom:40px;"></div>

          <div class="alliance-sub-container">
            <div class="sub_title">
              Bitberry Business Platform
            </div>

            <div class="sub_desc">
              <span>비트베리 비즈니스 플랫폼은 비트베리와 함께하는 비즈니스를 위한 기업 서비스입니다. 고객사/파트너사들의 기술, 사업, 마케팅 니즈에 맞는 서비스와 컨설팅을 제공합니다.</span>
            </div>

            <div class="programs-container">
              <div class="program-container" v-for="program in programs">
                <div class="title">
                  {{program.title}}
                </div>
                <div class="desc" v-html="program.desc">
                </div>
                <div class="btn-container">
                  <a class="btn" v-bind:href=program.link_1 target="_blank">
                    <label class="btn-desc">{{program.btn_desc_1}}</label>
                    <img src="../images/home/ic-arrow-right@3x.png" class="arrow"/>
                    <img src="../images/home/ic-arrow-right-w@3x.png" class="arrow-w"/>
                  </a>
                  <a class="btn" v-bind:href=program.link_2 target="_blank" style="margin-left:14px;">
                    <label class="btn-desc">{{program.btn_desc_2}}</label>
                    <img src="../images/home/ic-arrow-right@3x.png" class="arrow"/>
                    <img src="../images/home/ic-arrow-right-w@3x.png" class="arrow-w"/>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="divider hide-on-mobile" style="margin-top: 68px; margin-bottom: 60px;"></div>
          <div class="mobile-divider hide-on-pc" style="margin-top: 48px; margin-bottom: 48px;"></div>

          <div class="partners-container">
            <div class="title">
              Partners
            </div>
            <div class="desc">
              비트베리는 다양한 고객사/파트너들과 함께 실제 사용 사례를 만들어가고 있습니다.
            </div>

            <div class="logos-container">
              <div class="logo-container" v-for="logo in partner_logos">
                <img :src="logo.url" :class="logo.name"/>
                <label class="category">{{logo.category}}</label>
              </div>
            </div>
          </div>
        </div>

        <div id="partner" class="partner-story-desktop-container hide-on-mobile" v-show="(menu === 'partner_story' && windowWidth > 720)">
          <div class="title">
            비즈니스 이야기
          </div>
          <div class="category-container">
            <label v-on:click="show_partner_all" :class="{ selected: partner_category === 'all'}" class="category-item">All</label>
            <label>/</label>
            <label v-on:click="show_partner_bitberries" :class="{ selected: partner_category === 'bitberries'}" class="category-item">Bitberries</label>
            <label>/</label>
            <label v-on:click="show_partner_interviews" :class="{ selected: partner_category === 'interviews'}" class="category-item">Interviews</label>
            <label>/</label>
            <label v-on:click="show_partner_talks" :class="{ selected: partner_category === 'talks'}" class="category-item">Talks</label>
          </div>

          <div class="video-container">
            <div class="video-item" v-for="video in videos">
              <iframe width="280" height="160" frameborder="0" :src="video.url"
                      allow="encrypted-media" allowfullscreen></iframe>
              <div class="title">{{video.title}}</div>
            </div>
          </div>

          <div class="paging-container">
            <div class="arrow-container">
              <img class="arrow left" @mousedown.prevent="goToPrevPage" src="../images/home/ic-previous-page@3x.png">
            </div>
            <div class="page-index" v-for="pageNumber in totalPagesRange">
              <label>
                <a class="get-videos-link"
                   :class="{ selected: parseInt(pageNumber) == parseInt(currentPage)}"
                   @mousedown.prevent="updateVideos"
                   :data-page="pageNumber">{{pageNumber}}
                </a>
              </label>
            </div>
            <div class="arrow-container">
              <img class="arrow right" @mousedown.prevent="goToNextPage" src="../images/home/ic-next-page@3x.png">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-divider hide-on-pc" style="margin-top: 48px;margin-bottom:48px;"></div>

    <div class="partner-story-mobile-container hide-on-pc">
      <div class="title-container">
        <div class="title">
          비즈니스 이야기
        </div>
        <a class="more-story" href="https://www.youtube.com/channel/UCh_IYxVUYxaKfXWP2gk55bA" target="_blank">
          더 많은 이야기 보러가기 >
        </a>
      </div>

      <div class="video-container">
        <iframe :src="videos[0].url"
                allow="encrypted-media" allowfullscreen
                class="video-iframe">

        </iframe>
      </div>
    </div>

    <div class="become-partner-container">
      <div class="title">
        비트베리 파트너가 되어보세요!
      </div>
      <div class="title2">
        파트너 신청하기
      </div>

      <div class="btn-container">
        <a class="btn" href="mailto:with@rootone.com" target="_blank">
          제휴 문의
        </a>
      </div>
    </div>
    <home-footer/>
  </div>
</template>

<script>
  import GlobalNavHeader from "./global_nav_header";
  import HomeFooter from "./home_footer";

  export default {
    components: {GlobalNavHeader, HomeFooter},
    props: ['initialMenu', 'initialCategory', 'pdfUrls'],
    data() {
      return {
        per: 12,
        totalPages: null,
        nextPage: null,
        prevPage: null,
        currentPage: null,
        windowWidth: window.innerWidth,
        menu: this.initialMenu,
        partner_category: this.initialCategory,
        programs: [
          {
            title: '1. 비트베리 지갑 리스팅',
            desc: '카카오/구글 계정 로그인, 전화번호 기반 송금을 지원하는 비트베리 지갑에 리스팅을 지원합니다.\n',
            btn_desc_1: '상품 소개서',
            btn_desc_2: '신청서 바로가기',
            link_1: this.pdfUrls[1],
            link_2: 'http://bit.ly/token_listing',
          },
          {
            title: '2. 비트베리 API 솔루션',
            desc: '토큰 발행부터 결제까지, 필요한 모든 솔루션을 \n' +
                    '올인원으로 제공하고 지속적으로 관리합니다.\n',
            btn_desc_1: 'API 소개서',
            btn_desc_2: '문의하기',
            link_1: this.pdfUrls[2],
            link_2: 'mailto:with@rootone.com',
          },
          {
            title: '3. 비트베리 간편스왑 솔루션',
            desc: '노드 운영 노하우를 바탕으로 안전하고, 시간/비용 효율적인 실시간 메인넷 자동 스왑 솔루션을 지원합니다.\n',
            btn_desc_1: '간편스왑 소개서',
            btn_desc_2: '문의하기',
            link_1: this.pdfUrls[3],
            link_2: 'mailto:with@rootone.com',
          },
          {
            title: '4. 비트베리 마케팅/광고 솔루션',
            desc: '국내 최대 규모의 지갑 플랫폼, 비트베리에서 진행할 수 있는 에어드랍 솔루션 및 광고 상품 Mix를 지원합니다.\n',
            btn_desc_1: '광고 상품 소개서',
            btn_desc_2: '문의하기',
            link_1: this.pdfUrls[4],
            link_2: 'mailto:with@rootone.com',
          },
          {
            title: '5. 비트베리 기업용 지갑 서비스',
            desc: '국내 1위 지갑 서비스 운영 및 업비트 노하우를 바탕으로 법인/기관용 커스터디 서비스를 지원합니다.\n',
            btn_desc_1: '비즈니스 월렛 소개서',
            btn_desc_2: '문의하기',
            link_1: this.pdfUrls[5],
            link_2: 'mailto:with@rootone.com',
          },
        ],
        partner_logos: [
          {
            url: require('../images/home/ic-logo-upbit@3x.png'),
            name: 'upbit',
            category: '거래소'
          },
          {
            url: require('../images/home/ic-logo-luniverse@3x.png'),
            name: 'luniverse',
            category: 'BaaS'
          },
          {
            url: require('../images/home/ic-klayton@3x.png'),
            name: 'klayton',
            category: '메인넷'
          },
          {
            url: require('../images/home/ic-logo-terra@3x.png'),
            name: 'terra',
            category: '메인넷'
          },
          {
            url: require('../images/home/ic-logo-theori-copy@3x.png'),
            name: 'theori',
            category: '보안'
          },
          {
            url: require('../images/home/img-sentinel@3x.png'),
            name: 'sentinel',
            category: '보안'
          },
          {
            url: require('../images/home/ic-logo-aha@3x.png'),
            name: 'aha',
            category: '컨텐츠'
          },
          {
            url: require('../images/home/ic-logo-riiid@3x.png'),
            name: 'riiid',
            category: '컨텐츠'
          },
          {
            url: require('../images/home/ic-logo-cp@3x.png'),
            name: 'cpt',
            category: '컨텐츠'
          },
          {
            url: require('../images/home/ic-logo-hint@3x.png'),
            name: 'hint',
            category: '컨텐츠'
          },
          {
            url: require('../images/home/ic-logo-mossland@3x.png'),
            name: 'mossland',
            category: '게임'
          },
          {
            url: require('../images/home/ic-logo-skypeople@3x.png'),
            name: 'skypeople',
            category: '게임'
          },
          {
            url: require('../images/home/ic-logo-spin@3x.png'),
            name: 'spin',
            category: '커머스'
          },
          {
            url: require('../images/home/ic-logo-temco@3x.png'),
            name: 'temco',
            category: '커머스'
          },
          {
            url: require('../images/home/ic-logo-soda@3x.png'),
            name: 'soda',
            category: '금융'
          },
          {
            url: require('../images/home/ic-logo-mach@3x.png'),
            name: 'mach',
            category: '금융'
          },
          {
            url: require('../images/home/ic-logo-gana@3x.png'),
            name: 'gana',
            category: '금융'
          },
          {
            url: require('../images/home/ic-logo-heybit@3x.png'),
            name: 'heybit',
            category: '금융'
          },
          {
            url: require('../images/home/ic-logo-nabi@3x.png'),
            name: 'nabi',
            category: '라이프스타일'
          },
          {
            url: require('../images/home/ic-logo-culturelab@3x.png'),
            name: 'culturelab',
            category: '라이프스타일'
          },
          {
            url: require('../images/home/ic-logo-blacklink@3x.png'),
            name: 'blacklink',
            category: '라이프스타일'
          },
          {
            url: require('../images/home/ic-logo-insureum@3x.png'),
            name: 'insureum',
            category: '라이프스타일'
          },
          {
            url: require('../images/home/ic-logo-piction@3x.png'),
            name: 'piction',
            category: '라이프스타일'
          },
          {
            url: require('../images/home/ic-logo-cosmo@3x.png'),
            name: 'cosmo',
            category: '라이프스타일'
          },
          {
            url: require('../images/home/ic-logo-hexlant@3x.png'),
            name: 'hexlant',
            category: '솔루션'
          },
          {
            url: require('../images/home/ic-logo-address@3x.png'),
            name: 'address',
            category: '솔루션'
          },
          {
            url: require('../images/home/ic-logo-lyze@3x.png'),
            name: 'lyze',
            category: '솔루션'
          },
          {
            url: require('../images/home/ic-logo-stasia@3x.png'),
            name: 'stasia',
            category: '엔터테인먼트'
          },
          {
            url: require('../images/home/ic-logo-becrypto@3x.png'),
            name: 'becrypto',
            category: '컨설팅'
          },
        ],
        videos: [
          {
            url: 'https://youtu.be/iKhenE8OeHc',
          }
        ],
      }
    },
    computed: {
      totalPagesRange() {
        return _.range(1, this.totalPages);
      },
    },
    methods: {
      goToPrevPage() {
        if (this.prevPage) {
          this.getVideos(this.prevPage, this.per, this.partner_category || 'all');
        }
      },
      goToNextPage() {
        if (this.nextPage) {
          this.getVideos(this.nextPage, this.per, this.partner_category || 'all');
        }
      },
      show_alliance() {
        window.location.href = "/home/business?menu=alliance"
      },
      show_partner_story() {
        window.location.href = "/home/business?menu=partner_story&category=all"
      },
      show_partner_all() {
        this.show_partner_story();
      },
      show_bitberry_story() {
        window.location.href = "/home/business?menu=partner_story&category=bitberry"
      },
      show_partner_interviews() {
        window.location.href = "/home/business?menu=partner_story&category=interviews"
      },
      show_partner_talks() {
        window.location.href = "/home/business?menu=partner_story&category=talks"
      },
      show_partner_bitberries() {
        window.location.href = "/home/business?menu=partner_story&category=bitberries"
      },
      getVideos(page, per, category) {
        let data = null;
        let that = this;
        this.partner_category = category;
        axios.get('/partner_videos', {
          params: {
            page: page,
            per: per,
            category: category,
          }
        }).then(response => {
          let data = response.data;
          that.videos = data.videos;
          that.totalPages = data.total_pages;
          that.nextPage = data.next_page;
          that.prevPage = data.prev_page;
          that.currentPage = page;
        }).catch(error => {
          console.log(error);
        });
        return data;
      },
      updateVideos(event) {
        let page = event.target.dataset.page;
        this.getVideos(page, this.per, this.partner_category);
      },
    },
    mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth;
      };
      this.getVideos(1, this.per, this.initialCategory);
    },
  }
</script>

<style lang="scss">
  @import '../stylesheets/common';
  @import '../stylesheets/home';

  @mixin respond-to($media) {
    @if $media == tablet {
      @media only screen and (max-width: 1024px) { @content; }
    } @else if $media == mobile {
      @media only screen and (max-width: 720px) { @content; }
    }
  }

  @mixin desktop-page {
    @media only screen and (min-width: 721px) {
      @content;
    }
  }

  @include desktop-page {
    .hide-on-pc {
      display: none !important;
    }
  }
  @include respond-to(mobile) {
    .hide-on-mobile {
      display: none !important;
    }
  }

  body {
    overflow-y: scroll;
  }

  img {
    vertical-align: top;
  }

  #business {
    margin-top: 57px;
    font-family: 'Nanum Barun Gothic', sans-serif;

    .picture-container {
      background-color:#f2f2ee;
      width: 100%;
      margin: 0 auto;
      max-width: 1284px;
      max-height: 600px;

      .picture {
        width: 100%;
      }

      .video-container {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;

        .video-iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0;
          max-height: 600px;
        }
      }
    }

    .mobile-divider {
      height: 10px;
      width: 100%;
      background-color: #f2f2ee;
    }

    .desc-container {
      display: flex;
      margin: 0 auto;
      padding-top: 50px;
      max-width: 1284px;

      @include respond-to(mobile) {
        padding-top: 32px;
      }

      .nav {
        padding-left: 3px;

        @include respond-to(mobile) {
          display: none;
        }

        .bold {
          font-weight: bold;
        }

        .nav-alliance {
          font-size: 18px;
          color: $black2;
          line-height: 1.33;
          width: 160px;
          height: 64px;
          cursor: pointer;
          position: relative;

          div {
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }

        .nav-alliance:hover {
          font-weight: bold;
        }

        .nav-partner {
          font-size: 18px;
          color: $black2;
          line-height: 36px;
          width: 160px;
          height: 36px;
          font-weight: 300;
          cursor: pointer;
        }

        .nav-partner:hover {
          font-weight: bold;
        }
      }

      .contents-container {
        padding-left: 102px;

        @include respond-to(mobile) {
          padding-left: 0;
        }

        .alliance-container {
          margin-top: 10px;

          .alliance-title {
            color: $green;
            font-size: 28px;
            line-height: 1.29;
            font-weight: 800;
            font-family: 'Apple SD Gothic Neo', sans-serif;

            @include respond-to(mobile) {
              font-size: 20px;
              text-align: center;
              line-height: 1.2;
              font-weight: bold;
              font-family: 'Nanum Barun Gothic', sans-serif;
            }
          }

          .alliance-btn-container {
            margin-top: 20px;
            text-align: center;

            .alliance-btn {
              display: inline-block;
              border: 1px solid $green;
              font-size: 15px;
              color: $green;
              width: 130px;
              height: 40px;
              box-sizing: border-box;
              position: relative;
              text-align: center;
              line-height: 40px;
            }
          }

          .alliance-sub-container {
            @include respond-to(mobile) {
              padding-left: 30px;
              padding-right: 30px;
            }
          }

          .sub_title {
            color: $black2;
            font-size: 24px;
            font-weight: 800;
            font-family: 'Apple SD Gothic Neo', sans-serif;
            margin-top: 54px;

            @include respond-to(mobile) {
              font-size: 18px;
              font-weight: bold;
              font-family: 'Nanum Barun Gothic', sans-serif;
            }
          }

          .sub_desc {
            width: 78%;
            color: #555555;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.2px;
            font-weight: 300;
            margin-top: 10px;
            margin-bottom: 2px;

            @include respond-to(mobile) {
              width: auto;
              font-size: 15px;
              line-height: 1.44;
            }
          }

          .sub_desc > span {
            display: block;

            @include respond-to(mobile) {
              display: inline;
            }
          }

          .programs-container {
            display: flex;
            flex-wrap: wrap;
            margin-top: 2px;
            max-width: 880px;
            margin-left: -90px;

            @include respond-to(mobile) {
              display: block;
              margin-top: 0;
              margin-left: 0;
            }

            .program-container {
              width: 100%;
              margin-left: 90px;
              margin-top: 40px;

              @include desktop-page {
                max-width: 350px;
                white-space: pre-line;
              }

              @include respond-to(mobile) {
                margin-left: 0;
              }

              .title {
                color: $black2;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0.2px;
                font-family: 'Apple SD Gothic Neo', sans-serif;

                @include respond-to(mobile) {
                  font-size: 16px;
                  font-family: 'Nanum Barun Gothic', sans-serif;
                  letter-spacing: normal;
                }
              }

              .desc {
                color: #555555;
                font-size: 16px;
                letter-spacing: -0.2px;
                font-weight: 300;
                line-height: 1.63;
                margin-top: 10px;

                @include respond-to(mobile) {
                  line-height: 1.44;
                  font-size: 15px;
                  margin-top: 9px;
                }
              }

              .desc > span {
                display: block;

                @include respond-to(mobile) {
                  display: inline;
                }
              }

              .btn-container {
                display: flex;
                flex-wrap: wrap;
                margin-top: 16px;

                .btn {
                  display: flex;
                  border: 1px solid $black2;
                  width: 47%;
                  height: 40px;
                  box-sizing: border-box;
                  padding: 12px 12px 11px 16px;
                  justify-content: space-between;
                  /*position: relative;*/

                  .btn-desc {
                    font-size: 14px;
                    color: $black2;
                  }

                  .arrow {
                    width: 13px;
                    height: 13px;
                  }

                  .arrow-w {
                    width: 13px;
                    height: 13px;
                    display: none;
                  }

                  &:hover {
                    background-color: #333333;
                    cursor: pointer;

                    label {
                      color: #ffffff;
                    }

                    .arrow {
                      display: none;
                    }

                    .arrow-w {
                      display: inline;
                    }
                  }
                }
              }
            }
          }

          .divider {
            max-width: 852px;
            height: 1px;
            background-color: #e1e1de;
            margin-right: 10px;
          }

          .partners-container {
            @include respond-to(mobile) {
              padding-left: 30px;
              padding-right: 30px;
            }

            .title {
              font-size: 24px;
              color: $black2;
              font-weight: 800;
              font-family: 'Apple SD Gothic Neo', sans-serif;

              @include respond-to(mobile) {
                font-size: 18px;
                font-weight: bold;
                font-family: 'Nanum Barun Gothic', sans-serif;
              }
            }

            .desc {
              font-size: 16px;
              color: #555555;
              letter-spacing: -0.2px;
              font-weight: 300;
              margin-top: 10px;
              margin-bottom: 36px;

              @include respond-to(mobile) {
                line-height: 1.44;
                margin-top: 9px;
                margin-bottom: 22px;
              }
            }

            .logos-container > * {
              margin: 2px 2px;

              @include respond-to(mobile) {
                margin: 1px 1px;
              }
            }

            .logos-container {
              display: flex;
              max-width: 1018px;
              flex-wrap: wrap;
              margin-top: 4px;
              margin-left: -2px;
              margin-right: -2px;

              @include respond-to(mobile) {
                margin-left: -1px;
                margin-right: -1px;
              }

              .logo-container {
                background-color: #fbfbf8;
                width: 210px;
                height: 106px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @include respond-to(mobile) {
                  width: calc(50% - 2px);
                  height: 82px;
                  flex-grow: 1;
                  flex-basis: calc(50% - 2px);

                  &:last-child {
                    flex-grow: 0;
                  }
                }

                .category {
                  color: #555555;
                  font-size: 11px;
                  font-weight: 300;
                  letter-spacing: -0.1px;
                  margin-top: 8px;

                  @include respond-to(mobile) {
                    margin-top: 9px;
                    font-family: 'Apple SD Gothic Neo', sans-serif;
                    font-weight: 300;
                  }
                }

                .upbit {
                  width: 80px;
                  height: 24px;

                  @include respond-to(mobile) {
                    width: 58px;
                    height: 17px;
                  }
                }

                .luniverse {
                  width: 109px;
                  height: 27px;

                  @include respond-to(mobile) {
                    width: 82px;
                    height: 20px;
                  }
                }

                .klayton {
                  width: 89px;
                  height: 27px;

                  @include respond-to(mobile) {
                    width: 62px;
                    height: 19px;
                  }
                }

                .theori {
                  width: 116px;
                  height: 28px;

                  @include respond-to(mobile) {
                    width: 88px;
                    height: 21px;
                  }
                }

                .sentinel {
                  width: 90px;
                  height: 40px;

                  @include respond-to(mobile) {
                    width: 56px;
                    height: 25px;
                  }
                }

                .hint {
                  width: 111px;
                  height: 25px;

                  @include respond-to(mobile) {
                    width: 88px;
                    height: 20px;
                  }
                }

                .mossland {
                  width: 119px;
                  height: 19px;

                  @include respond-to(mobile) {
                    width: 88px;
                    height: 18px;
                  }
                }

                .skypeople {
                  width: 120px;
                  height: 23px;

                  @include respond-to(mobile) {
                    width: 96px;
                    height: 18px;
                  }
                }

                .spin {
                  width: 119px;
                  height: 16px;

                  @include respond-to(mobile) {
                    width: 95px;
                    height: 13px;
                  }
                }

                .temco {
                  width: 91px;
                  height: 25px;

                  @include respond-to(mobile) {
                    width: 73px;
                    height: 20px;
                  }
                }

                .gana {
                  width: 77px;
                  height: 15px;

                  @include respond-to(mobile) {
                    width: 62px;
                    height: 12px;
                  }
                }

                .nabi {
                  width: 109px;
                  height: 21px;

                  @include respond-to(mobile) {
                    width: 87px;
                    height: 17px;
                  }
                }

                .culturelab {
                  width: 107px;
                  height: 25px;

                  @include respond-to(mobile) {
                    width: 86px;
                    height: 20px;
                  }
                }

                .blacklink {
                  width: 109px;
                  height: 15px;

                  @include respond-to(mobile) {
                    width: 87px;
                    height: 12px;
                  }
                }

                .insureum {
                  width: 101px;
                  height: 12px;

                  @include respond-to(mobile) {
                    width: 80px;
                    height: 10px;
                  }
                }

                .piction {
                  width: 86px;
                  height: 22px;

                  @include respond-to(mobile) {
                    width: 69px;
                    height: 18px;
                  }
                }

                .cosmo {
                  width: 116px;
                  height: 22px;

                  @include respond-to(mobile) {
                    width: 93px;
                    height: 18px;
                  }
                }

                .hexlant {
                  width: 76px;
                  height: 17px;

                  @include respond-to(mobile) {
                    width: 61px;
                    height: 14px;
                  }
                }

                .lyze {
                  width: 77px;
                  height: 22px;

                  @include respond-to(mobile) {
                    width: 62px;
                    height: 18px;
                  }
                }

                .aha {
                  width: 49px;
                  height: 29px;

                  @include respond-to(mobile) {
                    width: 38px;
                    height: 23px;
                  }
                }

                .riiid {
                  width: 44px;
                  height: 26px;

                  @include respond-to(mobile) {
                    width: 35px;
                    height: 21px;
                  }
                }

                .cpt {
                  width: 114px;
                  height: 32px;

                  @include respond-to(mobile) {
                    width: 86px;
                    height: 24px;
                  }
                }

                .soda {
                  width: 75px;
                  height: 28px;

                  @include respond-to(mobile) {
                    width: 60px;
                    height: 22px;
                  }
                }

                .stasia {
                  width:57px;
                  height:22px;

                  @include respond-to(mobile) {
                    width: 48px;
                    height: 19px;
                  }
                }

                .becrypto {
                  width:72px;
                  height:24px;

                  @include respond-to(mobile) {
                    width: 60px;
                    height: 20px;
                  }
                }

                .mach {
                  width:77px;
                  height:21px;

                  @include respond-to(mobile) {
                    width: 62px;
                    height: 17px;
                  }
                }

                .terra {
                  width:80px;
                  height:20px;

                  @include respond-to(mobile) {
                    width: 62px;
                    height: 17px;
                  }
                }

                .heybit {
                  width: 95px;
                  height:29px;

                  @include respond-to(mobile) {
                    width: 75px;
                    height: 23px;
                  }
                }

                .address {
                  width: 78px;
                  height:16px;

                  @include respond-to(mobile) {
                    width: 65px;
                    height: 13px;
                  }
                }
              }
            }
          }
        }

        .partner-story-desktop-container {
          margin-top: 4px;

          .title {
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 0.2px;
          }

          .category-container {
            position: relative;
            display: inline-block;

            margin-top: 14px;
            margin-bottom: 40px;
            color: #555555;
            font-size: 16px;

            .selected {
              text-decoration: underline;
              color: $green;
            }

            .category-item {
              cursor: pointer;
            }

            label {
              &:hover {
                color: $green;
                cursor: pointer;
              }
            }
          }

          .video-container > * {
            margin: 25px 7.5px;
          }

          .video-container {
            max-width: 1018px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: -25px -7.5px;

            .video-item {
              width: 280px;

              .preview {
                width: 280px;
                height: 160px;
                background-color: #979797;
              }

              .title {
                font-size: 16px;
                color: $black2;
                margin-top: 16px;
                letter-spacing: -0.1px;
                line-height: 1.38;
              }
            }
          }

          .paging-container {
            margin-top: 40px;

            .arrow-container {
              display: inline-block;
              width: 28px;
              height: 40px;
              position: relative;

              &:hover {
                cursor: pointer;
              }

              .arrow {
                width: 10px;
                height: 16px;
                position: absolute;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                top: 50%;
              }

              .left {
                left: 0;
              }

              .right {
                right: 0;
              }
            }

            .page-index {
              display: inline-block;
              width: 40px;
              height: 40px;
              position: relative;

              label {
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;

                .selected {
                  color: green;
                  font-weight: bold;
                }

                a {
                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }

    .partner-story-mobile-container {
      padding-left: 30px;
      padding-right: 30px;

      .title-container {
        position: relative;
        width: 100%;

        .title {
          display: inline-block;
          font-size: 16px;
          color: $black2;
          font-weight: bold;
        }

        .more-story {
          display: inline-block;
          font-size: 15px;
          color: $green;
          line-height: 1.33;
          letter-spacing: -0.2px;
          text-decoration: underline;
          position: absolute;
          right: 0;
        }
      }

      .video-container {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
        margin-top: 20px;

        .video-iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0;
          max-height: 400px;
        }
      }
    }

    .become-partner-container {
      background-color: $green;
      margin-top: 100px;
      padding-top: 114px;
      padding-bottom: 96px;
      text-align: center;

      @include respond-to(mobile) {
        padding-top: 48px;
        padding-bottom: 48px;
        margin-top: 48px;
      }

      .title {
        font-size: 20px;
        color: #ffffff;
        font-weight: lighter;

        @include respond-to(mobile) {
          font-size: 16px;
          letter-spacing: -0.2px;
          line-height: 1.44;
        }
      }

      .title2 {
        font-size: 32px;
        color: #ffffff;
        font-weight: bold;
        margin-top: 13px;

        @include respond-to(mobile) {
          font-size: 20px;
          letter-spacing: -0.2px;
          line-height: 1.15;
          margin-top: 4px;
        }
      }

      .btn-container > :last-child {
        margin-left: 22px;

        @include respond-to(mobile) {
          margin-left: 12px;
        }
      }

      .btn-container {
        margin-top: 44px;

        @include respond-to(mobile) {
          margin-top: 27px;
        }

        .btn {
          display: inline-block;
          border: 1px solid #ffffff;
          width: 164px;
          height: 48px;
          box-sizing: border-box;
          position: relative;
          line-height: 47px;
          color: #ffffff;
          font-size: 18px;
          font-weight: 300;

          @include respond-to(mobile) {
            width: 130px;
            height: 40px;
            font-size: 15px;
            line-height: 40px;
          }

          &:hover {
            background: white;
            color: $green;
          }
        }
      }
    }
  }
</style>