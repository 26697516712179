<template>
  <div>
    <h1>서비스 이용약관</h1>

    <h2>제 1 조 (목적)</h2>

    <p>본 약관은 (주)루트원소프트(이하 “회사”라 합니다.)가 제공하는 루트원소프트 관련 제반 서비스의 이용에 대해 회사와 회원의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>


    <h2>제 2 조 (용어의 정의)</h2>
    <p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br>
      <q>
        1. “서비스”라 함은 회원이 PC, 이동전화, 휴대용 단말기, 차량 단말기 등 각종 유무선 기기 또는 프로그램을 통하여 이용할 수 있도록 회사가 제공하는 비트베리 암호화폐 지갑 서비스 및 이와 관련된 제반 서비스를 말합니다.<br>
        2. “회원”이란 본 약관에 따라 가입신청에 대한 회원의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다.<br>
        3. “비트베리 앱”라 함은 회사에서 제공하는 서비스로 암호화폐를 보관하거나 타인에게 송금할 수 있는 지갑 서비스를 의미합니다.<br>
        4. “비트베리 ID(또는 비트베리 계정)”라 함은 회원이 본 약관 및 개인정보처리방침에 동의한 후 회사가 회원을 식별하고 서비스를 제공하기 위해 회원에게 부여한 문자 또는 숫자의 조합을 말합니다.<br>
        5. “암호화폐”라 함은 서비스에서 보관하거나 입출금 할 수 있는 비트코인, 이더리움 및 기타 암호화된 화폐를 말합니다.<br>
        6  “비트베리 지갑”이라 함은 회원의 비트베리 계정과 연결된 암호화폐를 보관할 수 있는 암호화폐 지갑을 의미합니다.<br>
        7  “지갑 주소”란 암호화폐를 주고받을 수 있는 모든 암호화폐 지갑의 고유 계정을 의미합니다. 비트베리 앱으로 암호화폐를 받기 원할 경우 비트베리 앱 내 지갑별 ‘내 입금 주소’를 이용할 수 있습니다. 단,비트베리 앱 외 서비스의 지갑 주소는 회사에서 소유, 통제 또는 운영하지 않습니다.<br>
        8  “송금 비밀번호”란 서비스에서 암호화폐를 송금하기 위해서 입력해야 하는 암호입니다.<br>
        9. “안전거래”란 서비스에서 회원 간 암호화폐를 직접 교환하기 위한 기능입니다.
      </q>
    </p>

    <h2>제 3 조 (약관의 효력 및 변경)</h2>
    <p>
      ① 본 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을 발생합니다.<br>
      ② 회사는 본 약관의 내용을 서비스 내에 게시하거나 기타의 방법으로 회원에게 공시하고, 이에 동의한 회원이 서비스에 가입함으로써 효력이 발생합니다.<br>
      ③ 회사는 필요한 경우 본 약관을 변경할 수 있으며, 회사가 약관을 변경할 경우에는 적용일자 및 변경 사유를 명시하여 제2항과 같은 방법으로 그 적용일자 7일 전부터 공지하며, 회원에게 불리한 약관의 변경인 경우에는 그 적용일자 30일 전부터 공지하거나, E-mail, SMS, App Push, 카카오톡 플러스친구 등으로 개별 통지합니다.<br>
      ④ 회사가 제3항에 따라 변경 약관을 공지 또는 통지하면서 회원에게 약관 변경 적용 일까지 거부 의사를 표시하지 않을 경우, 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 회원이 명시적으로 약관 변경에 대한 거부 의사를 표시하지 아니하면, 회원이 변경된 약관 내용에 동의한 것으로 봅니다.<br>
      ⑤ 회원은 변경된 약관 사항에 동의하지 않으면 적용일자 전일까지 회사에 거부의사를 표시하고 서비스 이용 계약을 해지할 수 있습니다.<br>
    </p>


    <h2>제 4 조 (약관의 해석)</h2>
    <p>
      ① 회사는 본 약관 외에 별도의 운영정책을 둘 수 있습니다.<br>
      ② 본 약관에서 명시되지 않은 사항에 대해서는 관련법령 및 회사가 정한 개별 운영 정책의 세부 이용 지침 등의 규정에 의합니다.</p>

    <h2>제 5 조 (이용계약의 체결)</h2>
    <p>
      ① 이용계약은 회원이 되고자 하는 자(이하 “가입신청자”라고 합니다.)가 회사에서 제공하는 약관의 내용에 대하여 동의를 한 다음 회사가 정한 절차에 따라 가입신청을 완료하고, 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.<br>
      ② 회사는 가입신청자의 신청에 대하여 아래 각 호에 해당하는 사유가 있는 경우에는 승낙을 하지 않을 수 있으며, 가입 이후에도 아래 각 호의 사유가 확인될 경우에는 승낙을 취소하거나 이용계약을 해지할 수 있습니다.<br>
      <q>
        1. 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우<br>
        2. 실명이 아닌 명의 또는 타인의 명의를 이용한 경우<br>
        3. 허위 또는 잘못된 정보를 기재 또는 제공하거나, 회사가 제시하는 내용을 기재하지 않은 경우<br>
        4. 가입신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 본 약관에서 규정한 제반 사항을 위반하며 신청한 경우<br>
        5. 부정한 용도 또는 별개의 영업 목적으로 서비스를 이용하고자 하는 경우<br>
        6. 관련법령에 위배되거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우<br>
        7. 가입신청자의 나이가 만 14세 미만인 경우<br>
      </q><br>
      ③ 회사는 회원이 제공한 정보가 사실과 일치하는 지 여부를 확인하기 위하여 법령에 의하여 허용된 범위에서 전문기관을 통한 실명확인 또는 본인인증을 요청할 수 있습니다.</p>


    <h2>제 6 조 (회사의 의무)</h2>
    <p>
      ① 회사는 관련 법령과 본 약관을 준수하며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.<br>
      ② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보보호를 위해 보안시스템을 갖출 수 있으며, 개인정보처리방침을 공시하고 준수합니다.<br>
      ③ 회사는 업무와 관련하여 회원의 사전 동의 없이 회원 전체 또는 일부의 개인정보 등을 특정 회원의 정보를 구별할 수 없는 통계자료로 작성하여 이를 사용할 수 있고, 이를 위하여 회원은 사용하는 기기에 쿠키를 전송할 수 있습니다. 다만, 이 경우 회원은 쿠키의 송신을 거부하거나 쿠키의 송신에 대하여 경고하도록 사용하는 기기의 설정을 변경할 수 있으나, 이 경우 쿠키의 설정 변경으로 인해 회사의 귀책사유 없이 서비스 이용이 변경될 수 있습니다.<br>
      ④ 회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 하며, 서비스 내 게시판, E-mail 등을 통하여 회원에게 처리과정 및 결과를 전달할 수 있습니다.<br>
      ⑤ 회사는 정보통신망법, 위치정보보호법, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.
    </p>


    <h2>제 7 조 (회원의 의무)</h2>
    <p>① 회원은 서비스를 이용할 때 다음 각 호의 행위를 하여서는 아니됩니다.<br>
      <q>
        1. 이용 신청 또는 변경 시 허위 사실을 기재하거나, 제3자의 정보를 도용, 부정하게 사용하거나, 이동전화 명의자의 허락없이 실 사용자가 SMS 인증, 본인 인증 등을 수행하는 행위<br>
        2. 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 행위<br>
        3. 회사가 게시한 정보를 변경하는 행위<br>
        4. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시하는 행위<br>
        5. 회사와 기타 제3자의 저작권 등 지적재산권에 대해 침해하는 행위<br>
        6. 회사와 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br>
        7. 외설 또는 폭력적인 메시지, 화상, 음성, 허위사실, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위<br>
        8. 회사의 사전 승낙 없이 에이전트(Agent), 스크립트(Script), 스파이더(Spider), 스파이웨어(Spyware), 툴바(Toolbar) 등의 자동화된 수단, 기타 부정한 방법을 통하여 서비스에 접속하는 행위, 노출 횟수 및 클릭 횟수를 부정하게 생성하거나 증가시키는 행위, 서비스 이용 신청을 하는 행위, 회사의 서버에 부하를 야기하는 행위<br>
        9. 암호화폐의 시세에 부당한 영향을 주는 등의 방법으로 건전한 거래 질서를 교란하는 행위<br>
        10. 다른 회원의 개인정보를 수집, 저장, 공개하는 행위<br>
        11. 기타 불법적이거나 부당한 행위
      </q><br>
      ② 회원은 관계 법령, 본 약관, 이용가이드 및 서비스와 관련하여 회사가 공지하거나 통지한 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.<br>
      ③ 회원이 제1항에 명시된 행위를 한 경우에 회사는 서비스의 일부 또는 전부에 대한 이용제한, 이용계약의 해지 등을 할 수 있으며, 회사에 손해가 발생한 경우에는 손해배상의 청구 등의 조치를 할 수 있습니다.<br>
      ④ 회사가 제3항에 정한 조치를 할 경우 회사는 사전에 회원에게 서비스 내 게재 또는 E-mail, SMS, App Push 등으로 통보하며, 회원의 연락이 두절되거나 긴급을 요하는 건과 같이 부득이한 경우, 선 조치 후 사후 통보할 수 있습니다.<br>
      ⑤ 회사가 제3항에 의한 회사의 조치에 대하여 항변의 사유가 있는 경우 이에 대하여 항변을 할 수 있습니다.
    </p>


    <h2>제 8 조 (회원에 대한 통지)</h2>
    <p>
      ① 회사가 회원에 대하여 통지를 하는 경우 회사는 서비스 내에 게재하거나 회원이 등록한 카카오톡 플러스친구 또는 SMS, E-mail 등으로 할 수 있습니다.<br>
      ② 회사는 회원 전체에 대하여 통지를 하는 경우 7일 이상 서비스 내 게시함으로써 전항의 통지에 갈음할 수 있습니다.
    </p>


    <h2>제 9 조 (회원 정보의 변경)</h2>
    <p>
      ① 회원은 서비스를 통하여 언제든지 본인의 개인정보를 열람하고 수정 또는 수정 요청할 수 있습니다. 다만, 서비스 관리를 위해 필요한 카카오 계정 정보는 수정이 불가능합니다.<br>
      ② 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 서비스에서 직접 수정하거나, 고객센터를 통하여 회사에 변경 사항을 통지하여야 합니다.<br>
      ③ 회원이 전항의 변경사항을 회사에 통지하지 않아 발생한 불이익에 대하여 회사는 책임을 지지 않습니다.
    </p>


    <h2>제 10 조 (회원 정보의 관리)</h2>
    <p>
      ① 회사는 회원의 닉네임이 개인정보 유출 우려가 있거나, 회원 본인이 아닌 타인으로 오해할 소지가 있거나, 반사회적 또는 미풍양속에 어긋나거나, 제3자의 권리를 침해하거나, 회사 및 회사의 운영자로 오인할 우려가 있는 경우, 해당 닉네임의 이용을 제한할 수 있습니다. 또한 회사는 이 외의 경우라도 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우 해당 닉네임 사용을 제한할 수 있습니다.<br>
      ② 회사는 회원이 등록한 카카오 계정 등이 회사에 등록된 것과 일치할 경우에는 별도의 확인절차 없이 이용자를 회원으로 간주합니다.<br>
      ③ 회원은 본인의 허가를 받지 않은 제3자가 회원의 계정에 무단으로 접근하지 않도록 계정 접근을 위해 필요한 일체의 정보를 안전하게 보관할 책임이 있습니다.<br>
      ④ 회원은 계정 접근 정보를 분실했거나, 도용 당했거나, 제3자에게 공개되었음을 인지한 경우 이를 즉시 회사에 통지해야 합니다. 회사는 즉시 계정 이용 중단 등의 조치를 취할 수 있습니다.<br>
      ⑤ 회원의 계정 정보 등 접근 정보가 분실, 도용 혹은 공개되어 회원에게 발생한 손해에 대하여 회사는 책임을 부담하지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.
    </p>


    <h2>제 11 조 (서비스의 제공 등)</h2>
    <p>
      ① 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다. 단, 회사의 업무 또는 기술상의 이유로 서비스가 일시 중지될 수 있고, 또한 운영상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다. 이 경우 회사는 사전 또는 사후에 이를 공지합니다.<br>
      ② 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있으며, 이 경우 그 내용을 공지합니다.<br>
      ③ 회사는 서비스의 제공에 필요한 경우 점검을 실시할 수 있으며, 점검 시간은 서비스 제공 화면에 공지한 바에 따릅니다.
    </p>


    <h2>제 12 조 (서비스의 변경 및 중단)</h2>
    <p>
      ① 회사는 안정적인 서비스 제공을 위하여 서비스의 내용, 운영상, 기술상 사항 등을 변경하 수 있습니다.<br>
      ② 회사는 서비스를 변경할 경우 변경 내용과 적용 일자를 명시하여 사전에 고지합니다. 다만, 회사가 사전에 공지할 수 없는 부득이한 사유가 있는 경우 사후에 공지할 수 있습니다.<br>
      ③ 회원은 서비스 변경에 동의하지 않을 경우 회사에 거부의사를 표시하고 이용계약을 해지할 수 있습니다.<br>
      ④ 회사는 서비스 제공과 관련한 회사 정책의 변경 등 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경 또는 중단할 수 있으며, 이에 대해 관련 법령에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
    </p>


    <h2>제 13 조 (서비스의 이용)</h2>
    <p>
      ① 회원은 ‘서비스’에서 제공하는 입금용 지갑 주소를 통해 ‘암호화폐’를 입금 받아 지갑에 보관할 수 있습니다.<br>
      ② 회원은 ‘비트베리 앱’의 지갑에 보관된 ‘암호화폐’를 타인에게 송금할 수 있습니다.<br>
      <br>
      송금 방법은 아래와 같이 지원합니다.<br>
      <q>
        1. 비트베리 회원 검색하여 송금하기<br>
        2. 암호화폐 지갑 주소로 송금하기<br>
        3. 휴대전화번호로 송금하기<br>
      </q><br>
      단, 회원이 제공하는 암호화폐 지갑 주소 또는 휴대전화번호의 정확성에 대한 책임은 회원에게 있으며, 회사는 회원이 잘못 기재한 암호화폐 지갑 주소 또는 휴대전화번호로 암호화폐를 보내는 것에 대해서 책임을 지지 않습니다.<br>
      <br>
      ③ 회사는 암호화폐 송금 시 회원이 제공한 정보가 사실과 일치하는 지 여부를 확인하기 위해서 법령에 의하여 허용된 범위에서 전문기관을 통한 실명확인 또는 본인인증을 요청할 수 있습니다.<br>
      ④ 암호화폐 송금 시 회사가 정한 보안 등급에 따라 1회 또는 1일 최대 한도가 적용됩니다. 한도에 대한 자세한 기준은 서비스 또는 비트베리 앱 내에 상시 게시합니다.<br>
      ⑤ 회사는 회원이 보유한 암호화폐의 KRW 또는 USD 현재 시세의 정보를 확인하실 수 있습니다. 단, 현재 시세는 접속 시점에 따라 오차가 발생할 수 있으며 암호화폐의 가격 변동에 대해서는 회사가 책임지지 않습니다.<br>
      ⑥ 암호화폐 시장의 중단 또는 불가항력 사건이 발생한 경우 회사는 다음 중 하나 이상의 조치를 수행할 수 있으며 이러한 조치로 인해서 회원에게 발생한 손실에 대해서는 회사는 책임지지 않습니다.<br>
      <q>
        1. 서비스에 대한 접근 중단<br>
        2. 서비스 안에서의 모든 활동 중단<br>
      </q><br>
      ⑦ 회사는 안전한 서비스 이용을 위하여 잠금번호, 송금 비밀번호, 보안 등급 설정 기능을 제공합니다. 잠금번호와 송금 비밀번호의 관리에 대한 일체의 책임은 회원 본인에게 있습니다. 자신의 잠금번호와 송금 비밀번호를 제3자에게 대여 또는 양도하거나 이와 유사한 행위를 하여서는 아니되며, 관리를 소홀히 하여 발생하는 모든 책임은 회원 본인에게 있습니다.<br>
    </p>

    <h2>제 14 조 (안전거래 이용)</h2>
    <p>
      ① 회원은 서비스 내에서 암호화폐 교환을 위해 안전거래를 생성하거나, 생성된 안전거래에 참여할 수 있습니다.<br>
      ② 안전거래 생성이 완료되면 교환 대상 암호화폐는 회사의 안전 거래 주소로 이체되고, 해당 안전거래에 참여하는 회원(이하 “참여자”)이 안전거래를 생성한 회원에게 교환 대상 암호화폐를 이체할 경우 회사의 안전거래 주소에 이체되어 있었던 암호화폐가 “참여자”에게 전달됩니다. “참여자”가 안전거래를 생성한 회원에게 교환 대상 암호화폐를 이체하지 않을 경우에는 회사의 안전거래 주소로 이체되었던 암호화폐가 안전거래를 생성한 회원의 비트베리 주소로 자동 반환됩니다.<br>
      ③ 안전거래는 거래 참여가 진행되지 않은 경우 생성한 회원에 한하여 취소 할 수 있으며, 일정 시간이 지나면 자동으로 거래가 만료됩니다.<br>
      ④ 안전 거래가 완료된 후에는 환불 및 거래 취소가 불가능합니다.<br>
      ⑤ 회사는 단순히 고객님의 안전거래시 자산 보호를 위한 기능을 제공하며, 이와 무관하게 거래중 발생되는 문제에 대해서는 책임지지 않습니다.<br>
      ⑥ 회원은 회사가 제공하는 암호화폐 안전거래를 이용하는 경우 이에 따른 안전거래 수수료를 지급하여야 합니다. 수수료는 비트베리 앱 내 고객센터 수수료 안내에 명시되어 있습니다.<br>
      ⑦ 수수료에 대한 변경은 사전 공지 후 적용합니다.
    </p>


    <h2>제 15 조 (서비스 이용 관련 유의사항)</h2>
    <p>
      ① 회사는 제5조 제2항 각호의 사유, 제19조 제1항 및 제2항 각호의 사유가 있거나 그와 같은 사유로 인한 회원의 피해 발생을 방지하기 위해 필요한 경우 서비스를 통해 요청된 입출금 거래의 처리를 거부하거나 거래 금액 및 기타 다른 거래 조건에 제한을 둘 수 있습니다. 예를 들어, 서비스를 통해 요청된 입출금 거래 건을 제한하거나 특정 회원과의 거래를 제한할 수 있습니다.<br>
      ② 회사는 영업양도, 분할, 합병 등에 따른 영업의 폐지, 중요 제휴사와 계약 만료, 당해 서비스의 현저한 수익 악화 등 경영상의 중대한 사유로 인해 서비스를 지속하기 어려운 경우에는 서비스 전부를 중단할 수 있습니다. 이 경우 다른 불가항력적인 사유가 없는 한 중단 이전까지 중단 일자, 중단 사유, 이용자의 보유 암호화폐 이전 방법 등을 제8조 제1항의 방법으로 회원에게 통지합니다. 단, 회사는 입출금 거래를 중단하는 경우라도 회원이 자신의 자산인 암호화폐를 안전하게 이전할 수 있도록 이전을 지원합니다.<br>
      ③ 회사는 암호화폐 자체의 하자, 중요 제휴사의 파산이나 해산, 개별 암호화폐 입출금 거래 종료 등 합리적인 서비스 제공 중단 사유가 있거나 관련 법령, 감독 당국 등 정부의 방침 또는 이에 준하는 사유가 있을 경우 서비스에서 회원이 이용할 수 있는 개별 암호화폐를 제외할 수 있습니다. 회사는 암호화폐 제외 여부를 사전에 회원에게 알리기 위해 합리적인 노력을 기울일 것입니다. 회사가 제공하는 서비스에서 특정 암호화폐가 제외되는 경우 회사는 암호화폐 입출금 거래 중단 공지일로부터 적어도 30일의 기간 동안 타서비스에서 발급된 개인 지갑 등에 암호화폐를 이전할 수 있도록 이전을 지원합니다.<br>
      ④ 서비스에서 제공하는 콘텐츠는 서비스 이용을 위한 보조 자료이며, 투자 또는 거래의 권유 및 암시가 아닙니다. 회사는 콘텐츠 및 타 정보제공자가 제공하는 정보의 정확성을 보증하지 않습니다.
    </p>


    <h2>제 16 조 (수수료)</h2>
    <p>
      ① 회원은 회사가 제공하는 암호화폐 송금 기능을 이용하는 경우 이에 따른 송금수수료를 지급하여야 합니다. 수수료는 비트베리 앱 내 고객센터 수수료 안내에 명시되어 있습니다.<br>
      ② 송금수수료에 대한 변경은 사전 공지 후 적용합니다.
    </p>


    <h2>제 17 조 (게시물 또는 내용물의 삭제)</h2>
    <p>
      ① 회사는 회원이 게시하거나 전달하는 서비스 내의 모든 내용물(회원간 전달 포함)이 다음 각 호의 경우에 해당한다고 판단되는 경우 사전통지 없이 게시, 전달 등을 중단할 수 있으며, 이에 대해 회사는 귀책사유가 없는 한 책임을 부담하지 않습니다.<br>
      <q>
        1. 회사, 다른 회원 또는 제3자를 비방하거나 명예를 손상시키는 내용인 경우<br>
        2. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등의 유포에 해당하는 경우<br>
        3. 범죄적 행위에 결부된다고 인정되는 내용인 경우<br>
        4. 회사 또는 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우<br>
        5. 제2항에 따른 세부 이용 지침을 통하여 회사에서 규정한 게시기간을 초과한 경우<br>
        6. 불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우<br>
        7. 기타 관계 법령 및 회사의 지침 등에 위반된다고 판단되는 경우<br>
      </q><br>
      ② 회사는 게시물에 관련된 세부 이용 지침을 별도로 정하여 시행할 수 있으며, 회원은 그 지침에 따라 각종 게시물(회원간 전달 포함)을 등록하거나 삭제하여야 합니다.
    </p>



    <h2>제 18 조 (게시물의 저작권)</h2>

    <p>
      ① 회원이 서비스 내에서 게시한 게시물(회원간 전달 포함)의 저작권은 회원이 보유하며, 회사는 서비스의 활성화를 위하여 이를 게시, 기타 활용할 수 있는 권리를 가집니다.<br>
      ② 회사는 서비스 내에 게시한 게시물(회원간 전달 포함)을 회원의 동의 없이 제1항 후단 외에 다른 목적으로 사용할 수 없습니다.<br>
      ③ 회사는 회원이 서비스 내에서 게시한 게시물이 타인의 저작권, 기타 권리를 침해하더라도 회사의 귀책 사유가 없는 한 이에 대한 민.형사상의 책임을 부담하지 않습니다. 만일 회원이 타인의 저작권 기타 권리를 침해하였음을 이유로 회사가 귀책사유 없이 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 회원은 회사의 면책을 위하여 노력하여야 하며, 회원은 그로 인해 회사에 발생한 모든 손해를 부담하여야 합니다.<br>
      ④ 회사는 회원이 이용계약을 해지하거나 본 약관에 의한 적법한 사유로 이용 계약이 해지된 경우, 해당 회원이 게시하였던 게시물을 삭제할 수 있습니다.<br>
      ⑤ 회사가 작성한 저작물에 대한 저작권은 회사에 귀속됩니다.<br>
      ⑥ 회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 타인의 자료를 영리목적으로 이용하거나 제3자에게 이용하게 할 수 없으며, 게시물에 대한 저작권 침해는 관계 법령의 적용을 받습니다.
    </p>



    <h2>제 19 조 (개인 정보 등의 변경 및 보호)</h2>
    <p>
      ① 회원은 이용신청 시 기재한 사항이 변경되었을 경우에는 즉시 변경사항을 최신의 정보로 수정하여야 합니다. 단, 회사가 정한 정보 항목은 회사의 승인을 받아 수정할 수 있습니다.<br>
      ② 수정하지 않은 정보로 인하여 발생하는 손해는 당해 회원이 부담하며, 회사는 이에 대하여 귀책사유가 없는 한 책임을 지지 않습니다.<br>
      ③ 회사는 회원의 정보를 서비스를 제공하기 위한 목적 이외의 용도로 사용하거나 회원의 동의 없이 제3자에게 제공하지 않습니다. 단, 다음의 경우는 예외로 합니다.<br>
      <q>
        1. 법령에서 회원의 개인정보의 이용과 제3자에 대한 정보제공을 허용하고 있는 경우<br>
      </q><br>
      ④ 회사는 관련법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의 개인정보 등을 보호하기 위하여 노력합니다. 회원의 개인정보 등의 보호에 관해서는 관련 법령 및 회사가 정하는 개인정보처리방침에 정한 바에 의합니다.<br>
      ⑤ 회사는 수집된 개인정보 등의 취급 및 관리 등의 업무를 스스로 수행함을 원칙으로 하나, 필요한 경우 회원의 동의를 받거나 통지를 한 후 위 업무의 일부 또는 전부를 회사가 선정한 회사에 위탁할 수 있습니다.</p>



    <h2>제 20 조 (이용제한 등)</h2>

    <p>① 회사는 다음 각 호에 해당하는 경우 회원의 서비스 이용 및 접속을 제한할 수 있습니다.<br>
      <q>
        1. 잠금번호 및 송금 비밀번호 연속 오류의 경우<br>
        2. 해킹 및 사고가 발생한 경우<br>
        3. 명의 도용으로 의심되는 경우<br>
        4. 국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우<br>
        5. 자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여하고 있다고 합리적으로 의심되는 경우<br>
        6. 회원으로 가입된 이용자가 미성년자로 확인된 경우<br>
        7. 본 약관의 제7조를 위반한 경우<br>
        8. 기타 각호에 준하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한 경우<br>
      </q><br>

      ② 회사는 다음 각 호에 해당하는 경우 회원의 입금 및 출금 이용을 제한할 수 있습니다.<br>

      <q>
        1. 회원으로 가입된 이용자가 미성년자로 확인된 경우<br>
        2. 해킹 및 사기 사고가 발생하였거나 발생한 것으로 의심되는 경우<br>
        3. 명의 도용으로 의심되는 경우<br>
        4. 국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우<br>
        5. 자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여하고 있다고 합리적으로 의심되는 경우<br>
        6. 회원 본인의 요청이 있는 경우<br>
        7. 암호화폐가 송금되었으나 회원 계정에 반영되지 않은 경우 또는 실제 입출금 요청과 다르게 입출금이 이루어진 경우<br>
        8. 기타 각호에 준하는 사유에 해당하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한 경우<br>
      </q><br>

      ③ 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 운영정책, 이용가이드 등에서 회사가 정하는 바에 의합니다.<br>
      ④ 본 조에 따라 서비스 이용을 제한하거나 이용계약을 해지하는 경우에는 회사는 제8조에 따른 방법으로 회원에게 통지합니다.<br>
      ⑤ 회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
    </p>


    <h2>제 21 조 (이용계약 해지)</h2>

    <p>
      ① 회원은 언제든지 고객센터를 통하여 이용계약 해지를 신청할 수 있으며, 회사는 관련 법령이 정하는 바에 따라 이를 즉시 처리하여야 합니다.<br>
      ② 회사는 회원에게 다음과 같은 사유가 발생할 경우 시간을 정하여 서비스 이용을 제한함과 동시에 시정요구를 할 수 있습니다. 시정요구에도 불구하고 상당한 기간 내에 시정되지 않거나 반복적으로 동일한 위반행위를 하는 경우에는 이용계약을 해지할 수 있습니다.<br>
      <q>
        1. 본 약관 제7조에 규정된 회원의 의무를 위반하거나 제19조에 규정된 이용제한 사유에 해당하는 경우<br>
        2. 저작권법을 위반한 불법 프로그램의 제공 및 운영 방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법 통신 및 해킹, 악성프로그램의 배포, 접속 권한 초과 행위 등과 같이 관련 법령을 위반한 경우<br>
        3. 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우<br>
        4. 그 밖에 각 호에 준하는 사유로서 본 계약을 유지할 수 없는 사유가 발생하는 경우<br>
      </q><br>
      ③ 전항에 따른 이용계약 해지 시 서비스 이용을 통해 획득한 모든 혜택이 소멸되며 회사는 이에 대해 별도로 보상하지 않습니다.<br>
      ④ 본 조에 따라 서비스 이용계약을 해지하는 경우에는 회사는 제8조에 따른 방법으로 회원에게 통지합니다.<br>
      ⑤ 이용계약 해지가 완료되는 경우 관련 법령 및 개인정보처리방침에 따라 회사가 보유하여야 하는 정보를 제외한 회원의 모든 정보가 삭제됩니다.<br>
      ⑥ 전항에도 불구하고 제2항에 따라 회사가 이용계약을 해지하는 경우 회사는 회원의 이의신청 접수 및 처리 등을 위하여 일정 기간 동안 회원의 정보를 보관할 수 있으며, 해당 기간이 경과한 후에 회원의 정보(단, 입출금 내역은 제외)를 삭제합니다.
    </p>


    <h2>제 22 조 (정보의 제공 및 광고의 게재)</h2>

    <p>
      ① 회사는 서비스를 운영함에 있어서 서비스 관련 각종 정보를 서비스 화면에 게재하거나 E-mail, SMS, 카카오톡 플러스친구, App Push 등의 방법으로 회원에게 제공할 수 있습니다.<br>
      ② 회사는 서비스를 운영함에 있어 회사 또는 제휴사의 각종 광고를 서비스 화면에 게재하거나 회원의 동의를 얻어 E-mail, SMS, 카카오톡 플러스친구, App Push 등의 방법으로 회원에게 제공할 수 있습니다.</p>


    <h2>제 23 조 (책임 제한)</h2>

    <p>
      ① 회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 행위자의 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.<br>
      ② 회사는 전시, 사변, 천재지변 또는 이에 준하는 국가 비상 사태 등 불가항력적인 사유로 회원에게 발생한 손해 및 정부기관의 사실상 또는 법률상 행정처분 및 명령 등을 준수하거나 이행하기 위하여 회원에게 발생한 손해는 배상하지 아니합니다. 단, 회사는 이로 인하여 발생하는 이용자의 손해를 최소화하기 위하여 가능한 합리적인 노력을 다하여야 합니다.<br>
      ③ 회사는 전기통신사업법에 의한 기간통신사업자를 포함한 통신서비스 업체의 서비스 장애, 회사가 관리할 수 없는 외주 시스템의 하자 또는 이용자측 귀책사유로 인한 서비스 장애로 인하여 회사의 귀책사유 없이 회원에게 발생한 손해는 배상하지 아니합니다.<br>
      ④ 순간적인 서비스 접속 증가, 특정 암호화폐의 입출금 거래 폭주 등으로 인한 서버의 장애가 발생하거나, 서비스 내 입출금 시스템, 각 암호화폐의 네트워크(예: 비트코인 네트워크)장애 또는 오류가 발생한 경우, 회사가 회원의 손해 발생을 방지하기 위하여 필요한 다음 사항을 포함한 관리자의 주의를 다하였음을 입증한 때에는 그 손해에 대하여 책임을 지지 아니합니다.<br>
    <q>
        1. 정보보호 책임자 선임 및 관리<br>
        2. 정보보호 교육<br>
        3. 정보보호시스템(정보처리시스템 내 정보를 유출, 위변조, 훼손하거나 정보처리시스템의 정상적인 서비스를 방해하는 행위로부터 정보 등을 보호하기 위한 장비 및 프로그램) 및 정보처리시스템(암호화폐 입출금 거래를 포함하여 정보기술부문에 사용되는 하드웨어, 소프트웨어 및 관련 장비)에 대한 보호 대책<br>
        4. 암호키(암호화폐에 대한 처분 권한을 증명할 때 필요한 서명을 하기 위한 개인키(privatekey)) 관리 방안<br>
        5. 정보보안사고 대응 방안 수립<br>
        6. 정보보호 대책 및 보안사고 대응방안 등에 대한 정기적인 점검<br>
        7. 기타 정보보호 관련 법률에서 암호화폐취급업자가 준수하도록 정하는 사항<br>
      </q>
    </p>

    <h2>제 24 조 (분쟁의 해결과 관할법원)</h2>
    <p>
      ① 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히 협의합니다.<br>
      ② 회사와 회원 사이에 제기된 소송은 대한민국법을 준거법으로 합니다.<br>
      ③ 회사와 회원 사이에 발생한 분쟁에 관한 소송의 관할법원은 민사소송법에 따라 정합니다.
    </p>


    <h2>부칙</h2>

    <p>본 약관은 2021년 8월 30일부터 적용됩니다</p>

    <a href="/app_pages/terms_v1">이전 약관 보기[2018.09.11 ~ 2019.09.22]</a><br>
    <a href="/app_pages/terms_v2">이전 약관 보기[2019.09.23 ~ 2021.08.30]</a>
  </div>
</template>

<script>
  import daumtools from 'lib/daumtools';
  export default {
    props: [''],
    data() {
      return {
      }
    },
    mounted() {
      daumtools.sendDataToMobileApp('서비스 이용 약관');
    }
  }
</script>