<template>
  <div id="about_us">
    <global-nav-header />
    <div class="picture">
      <div class="picture-item-desktop hide-on-mobile">
        <img class="picture-item-desktop-image" v-for="picture in pictures" :src="picture.desktop_src"/>
      </div>
      <div class="picture-item-mobile hide-on-pc">
        <img class="picture-item-mobile-image" v-for="picture in pictures" :src="picture.mobile_src"/>
      </div>
      <span class="arrow-left" v-on:click="previous_picture">
        <img class="arrow" src="../images/home/ic-arrow-left@3x.png" />
      </span>
      <span class="arrow-right" v-on:click="next_picture">
        <img class="arrow" src="../images/home/ic-arrow-right-w@3x.png" />
      </span>
    </div>
    <div class="desc-container">
      <div class="nav">
        <div class="nav-menu" id="rootone-menu" v-on:click="show_rootone" :class="{ bold: menu === 'rootone'}">루트원</div>
        <div class="nav-menu" id="bi-ci-menu"  v-on:click="show_bi_ci" :class="{ bold: menu === 'bi_ci'}">BI/CI</div>
        <div class="nav-menu" id="service-api-menu"  v-on:click="show_service_api" :class="{ bold: menu === 'service_api'}">서비스/API 소개서</div>
      </div>
      <div class="contents-container">
        <!-- rootone -->
        <div class="rootone" v-show="(menu === 'rootone' && windowWidth > 720) || windowWidth <= 720">
          <div class="rootone-title">
            저희는 비트베리를 만든<br/>
            루트원입니다.
          </div>
          <div class="content-title">
            Mission
          </div>
          <div class="mission-desc">
            <span>루트원의 미션은 블록체인 기술을 기반으로 전세계인의 경제 활동을 돕는 것입니다. 디지털 자산을 효율적으로 관리할 수 있는 통합 월렛/뱅킹 서비스를 제공합니다.</span>
          </div>
          <div class="mission-detail-container">
            <div class="mission-detail-item">
              <div class="mission-icon-container">
                <img class="mission-icon lock" src="../images/home/ic-lock@3x.png"/>
              </div>
              <div class="mission-detail-desc">
                쉽고 안전한 보관
              </div>
            </div>
            <div class="mission-detail-item">
              <div class="mission-icon-container">
                <img class="mission-icon mobile" src="../images/home/ic-mobile@3x.png" />
              </div>
              <div class="mission-detail-desc">
                <span>전화번호 기반의</span>
                <span>간편한 송금</span>
              </div>
            </div>
            <div class="mission-detail-item">
              <div class="mission-icon-container">
                <img class="mission-icon swap" src="../images/home/ic-swap@3x.png"/>
              </div>
              <div class="mission-detail-desc">
                <span>가상 화폐간의</span>
                <span>빠른 교환</span>
              </div>
            </div>
            <div class="mission-detail-item">
              <div class="mission-icon-container">
                <img class="mission-icon card" src="../images/home/ic-card@3x.png"/>
              </div>
              <div class="mission-detail-desc">
                <span>실제 사용가능한</span>
                <span>결제기능 지원</span>
              </div>
            </div>
            <div class="mission-detail-item">
              <div class="mission-icon-container">
                <img class="mission-icon ppt" src="../images/home/ic-ppt@3x.png"/>
              </div>
              <div class="mission-detail-desc">
                <span>다양한 투자상품</span>
                <span>제공</span>
              </div>
            </div>
          </div>
          <div class="desktop-divider hide-on-mobile" style="margin-top:60px;margin-bottom:60px;"></div>

          <div class="content-title">
            History
          </div>

          <div class="history-div-container">
            <table class="history-container">
              <tr>
                <td class="year">
                  2019
                </td>
                <td class="month">
                  02
                </td>
                <td class="desc">
                  Bitberry Business 공식 런칭
                </td>
              </tr>
              <tr>
                <td class="year-2018">
                  2018
                </td>
                <td class="month">
                  <div>09</div>
                  <div style="margin-top:14px;">03</div>
                </td>
                <td class="desc">
                  <div>Bitberry 서비스 런칭</div>
                  <div style="margin-top:14px;">두나무 투자 유치 및 자회사 편입</div>
                </td>
              </tr>
              <tr>
                <td class="year">
                  2017
                </td>
                <td class="month">
                  12
                </td>
                <td class="desc">
                  암호화폐 지갑 개발 시작
                </td>
              </tr>
              <tr>
                <td class="year">
                  2016
                </td>
                <td class="month">
                  06
                </td>
                <td class="desc">
                  루트원 창업
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="mobile-divider hide-on-pc"></div>

        <!--BI/CI-->
        <div class="bi-ci-desktop hide-on-mobile" style="margin-bottom:100px;" v-show="(menu === 'bi_ci' && windowWidth > 720) || windowWidth <= 720">
          <div class="title" style="color:#2da252;">
            BITBERRY BI
          </div>

          <div class="mission-desc">
            비트베리 BI 파일입니다. 디자인 가이드라인을 꼭 준수해주세요.
          </div>

          <a class="bi-btn" :href="biFileAIUrl">
            AI
            <img class="download-g" src="../images/home/ic-download-g@3x.png"/>
            <img class="download-w" src="../images/home/ic-download-w@3x.png"/>
          </a>

          <a class="bi-btn" :href="biFilePNGUrl">
            PNG
            <img class="download-g" src="../images/home/ic-download-g@3x.png"/>
            <img class="download-w" src="../images/home/ic-download-w@3x.png"/>
          </a>

          <a class="bi-btn" :href="biFileDesignGuideUrl" target="_blank" style="width:150px;">
            디자인 가이드
            <img class="download-g" src="../images/home/ic-right-g@3x.png"/>
            <img class="download-w" src="../images/home/ic-right-w@3x.png"/>
          </a>

          <div class="desktop-divider hide-on-mobile" style="margin-top:110px;margin-bottom:44px;"></div>

          <div class="title">
            ROOTONE CI
          </div>

          <div class="mission-desc">
            루트원 CI 파일입니다. 디자인 가이드라인을 꼭 준수해주세요.
          </div>

          <a class="ci-btn" :href="ciFileAIUrl">
            AI
            <img class="download-b" src="../images/home/ic-download@3x.png"/>
            <img class="download-w" src="../images/home/ic-download-w@3x.png"/>
          </a>

          <a class="ci-btn" :href="ciFilePNGUrl">
            PNG
            <img class="download-b" src="../images/home/ic-download@3x.png"/>
            <img class="download-w" src="../images/home/ic-download-w@3x.png"/>
          </a>

          <a class="ci-btn" :href="ciFileDesignGuideUrl" target="_blank" style="width:150px;">
            디자인 가이드
            <img class="download-b" src="../images/home/ic-right@3x.png"/>
            <img class="download-w" src="../images/home/ic-right-w@3x.png"/>
          </a>
        </div>
        <div class="bi-ci-mobile hide-on-pc">
          <div class="bi-title">BITBERRY BI</div>
          <img src="../images/home/im-bitberry-bi@3x.png" class="logo" />

          <div class="ci-title">ROOTONE CI</div>
          <img src="../images/home/im-rootone-ci@3x.png" class="logo" />

          <div class="desc">
            *로고파일은 PC 버전에서 다운 받을 수 있습니다.
          </div>
        </div>

        <div class="mobile-divider hide-on-pc"></div>

        <!-- service api -->
        <div class="service-api" style="margin-bottom:100px;" v-show="(menu === 'service_api' && windowWidth > 720) || windowWidth <= 720">
          <div class="title">
           서비스 소개서
          </div>

          <div class="mission-desc">
            비트베리 서비스에 대한 상세 소개서입니다.
          </div>

          <div class="service-btns">
            <a class="service-btn hide-on-mobile" :href="serviceGuideKRUrl" target="_blank" style="margin-top:24px;">
              서비스 소개서 (국문)
              <img class="download-b" src="../images/home/ic-right@3x.png"/>
              <img class="download-w" src="../images/home/ic-right-w@3x.png"/>
            </a>
            <a class="service-btn hide-on-pc" :href="serviceGuideKRUrl" target="_blank" style="margin-top:24px;">
              국문
              <img class="download-b" src="../images/home/ic-right@3x.png"/>
              <img class="download-w" src="../images/home/ic-right-w@3x.png"/>
            </a>

            <a class="service-btn hide-on-mobile" :href="serviceGuideENUrl" target="_blank" style="margin-left:14px;margin-top:24px;">
              서비스 소개서 (영문)
              <img class="download-b" src="../images/home/ic-right@3x.png"/>
              <img class="download-w" src="../images/home/ic-right-w@3x.png"/>
            </a>
            <a class="service-btn hide-on-pc" :href="serviceGuideENUrl" target="_blank" style="margin-left:14px;margin-top:24px;">
              영문
              <img class="download-b" src="../images/home/ic-right@3x.png"/>
              <img class="download-w" src="../images/home/ic-right-w@3x.png"/>
            </a>
          </div>

          <div class="desktop-divider" style="margin-top:44px;margin-bottom:44px;"></div>

          <div class="title">
            API 소개서
          </div>

          <div class="mission-desc">
            비즈니스 플랫폼에 대한 소개서입니다.
          </div>

          <a class="api-btn hide-on-mobile" :href="apiGuideKRUrl" target="_blank" style="margin-top:24px;">
            API 소개서 (국문)
            <img class="download-b" src="../images/home/ic-right@3x.png"/>
            <img class="download-w" src="../images/home/ic-right-w@3x.png"/>
          </a>
          <a class="api-btn hide-on-pc" :href="apiGuideKRUrl" target="_blank" style="margin-top:24px;">
            국문
            <img class="download-b" src="../images/home/ic-right@3x.png"/>
            <img class="download-w" src="../images/home/ic-right-w@3x.png"/>
          </a>
        </div>
      </div>
    </div>

    <home-footer/>
  </div>
</template>


<script>
  import 'slick-carousel';
  import GlobalNavHeader from "./global_nav_header";
  import HomeFooter from "./home_footer";

  export default {
    props: ['initialMenu'],
    components: {GlobalNavHeader, HomeFooter},
    data() {
      return {
        windowWidth: window.innerWidth,
        menu: this.initialMenu,
        firstIndex: 0,
        secondIndex: 1,
        thirdIndex: 2,
        biFileAIUrl: '/shared/homepage/BITBERRY_BI.ai',
        biFilePNGUrl: '/shared/homepage/BITBERRY_BI_png.zip',
        biFileDesignGuideUrl: '/shared/homepage/BITBERRY+BI+Guideline.pdf',
        ciFileAIUrl: '/shared/homepage/RootOne_CI.ai',
        ciFilePNGUrl: '/shared/homepage/RootOne_CI_png.zip',
        ciFileDesignGuideUrl: '/shared/homepage/RootOne+CI+Guideline.pdf',
        serviceGuideKRUrl: '/shared/homepage/Bitberry+service+document_KR.pdf',
        serviceGuideENUrl: '/shared/homepage/Bitberry+service+document_EN.pdf',
        apiGuideKRUrl: '/shared/Bitberry%2BAPI%2Bdocument_KR_190710.pdf',
        pictures: [
          {
            desktop_src: require('../images/home/im-photo-2@3x.png'),
            mobile_src: require('../images/home/im-photo-2-m@3x.png'),
            index: 0,
          },
        ],
        picture_index: 0,
        member_list: [
          require('../images/home/members/im-ross.png'),
          require('../images/home/members/im-enoch.png'),
          require('../images/home/members/im-andrew.png'),
          require('../images/home/members/im-june.png'),
          require('../images/home/members/im-ines.png'),
          require('../images/home/members/im-patrick.png'),
          require('../images/home/members/im-vincent.png'),
          require('../images/home/members/im-lucy.png'),
          require('../images/home/members/im-dexter.png'),
          require('../images/home/members/im-daniel.png'),
          require('../images/home/members/im-buttercup.png'),
          require('../images/home/members/im-mars.png'),
          require('../images/home/members/im-kelly.png'),
          require('../images/home/members/im-young.png'),
          require('../images/home/members/im-brian.png'),
          require('../images/home/members/im-peter.png'),
          require('../images/home/members/im-terry.png'),
          require('../images/home/members/im-victor.png'),
        ],
      }
    },
    methods: {
      show_rootone() {
        this.menu = "rootone";
      },
      show_bi_ci() {
        this.menu = "bi_ci";
      },
      show_service_api() {
        this.menu = "service_api";
      },
      next_picture() {
        this.pause_and_restart_slick_auto_play();
        $('.picture-item-desktop').slick('slickNext');
        $('.picture-item-mobile').slick('slickNext');
      },
      previous_picture() {
        this.pause_and_restart_slick_auto_play();
        $('.picture-item-desktop').slick('slickPrev');
        $('.picture-item-mobile').slick('slickPrev');
      },
      pause_and_restart_slick_auto_play() {
        $('.picture-item-desktop').slick("slickPause");
        $('.picture-item-mobile').slick("slickPause");

        setTimeout(function() {
          $('.picture-item-desktop').slick("slickPlay");
          $('.picture-item-mobile').slick("slickPlay");
        }, 5000);
      },
      getMemberSetIndex: function () {
        return Math.floor(Math.random() * this.member_list.length);
      },
    },
    mounted() {
      this.firstIndex = this.getMemberSetIndex();
      this.secondIndex = (this.firstIndex + 1) %  this.member_list.length;
      this.thirdIndex = (this.firstIndex + 2) %  this.member_list.length;

      window.onresize = () => {
        this.windowWidth = window.innerWidth;

        // refresh 안해주면, 크기를 계산하느라 잠시 slick 영역이 사라짐
        if (this.windowWidth <= 720) {
          $('.picture-item-mobile').slick("refresh")
        } else {
          $('.picture-item-desktop').slick("refresh")
        }
      };

      $('.picture-item-desktop').slick(
        {
          dots: false,
          arrows: false,
          infinite: true,
          useTransform: false,
          autoplay: true,
          autoplaySpeed: 4000,
        }
      );

      $('.picture-item-mobile').slick(
        {
          dots: false,
          arrows: false,
          infinite: true,
          useTransform: false,
          autoplay: true,
          autoplaySpeed: 4000,
        }
      );

      let that = this;

      $('.picture-item-desktop').on('afterChange', function(event, slick, direction) {
        that.picture_index = direction;
      });

      $('.picture-item-desktop').on('swipe', function(event, slick, direction) {
        that.pause_and_restart_slick_auto_play()
      });

      $('.picture-item-mobile').on('afterChange', function(event, slick, direction) {
        that.picture_index = direction;
      });

      $('.picture-item-mobile').on('swipe', function(event, slick, direction) {
        that.pause_and_restart_slick_auto_play()
      });
    }
  }
</script>

<style lang="scss">
  @import '../stylesheets/common';
  @import '../stylesheets/home';

  @mixin respond-to($media) {
    @if $media == tablet {
      @media only screen and (max-width: 1024px) { @content; }
    } @else if $media == mobile {
      @media only screen and (max-width: 720px) { @content; }
    }
  }

  @mixin desktop-page {
    @media only screen and (min-width: 721px) {
      @content;
    }
  }

  body {
    overflow-y: scroll;
  }

  img {
    vertical-align: top;
  }

  #about_us {
    font-family: 'Nanum Barun Gothic', sans-serif;
    margin-top: 57px;

    a {
      text-decoration: none;
    }
    .picture {
      background-color:#f2f2ee;
      width: 100%;
      margin: 0 auto;
      position: relative;
      max-width: 1285px;
      max-height: 600px;

      @include respond-to(mobile) {
        max-width: 720px;
        max-height: 500px;
      }

      .picture-item-desktop-image {
        max-width: 1285px;
        max-height: 700px;
      }

      .picture-item-mobile-image {
        max-width: 720px;
        max-height: 500px;
      }

      .arrow-left {
        position: absolute;
        width:44px;
        height:44px;
        background-color:$green;
        top: 50%;
        left: 0;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-left: 24px;
        cursor: pointer;

        @include respond-to(mobile) {
          margin-left: 0;
          width: 33px;
          height: 33px;
        }
      }
      .arrow-right {
        position: absolute;
        width:44px;
        height:44px;
        background-color:$green;
        top:50%;
        right: 0;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-right: 24px;
        cursor: pointer;

        @include respond-to(mobile) {
          margin-right: 0;
          width: 33px;
          height: 33px;
        }
      }

      .arrow {
        position: absolute;
        top: 13px;
        left: 12px;
        width: 20px;
        height: 18px;

        @include respond-to(mobile) {
          width: 11px;
          height: 10px;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
    .desc-container {
      display: flex;
      margin: 0 auto;
      padding-top: 50px;
      max-width: $body-max-width;
      width: 100%;

      @include respond-to(mobile) {
        padding-top: 40px;
      }

      .nav {
        display: inline-block;
        vertical-align: top;
        width: 160px;
        padding-left: 3px;

        @include respond-to(mobile) {
          display: none;
        }

        .nav-menu {
          height: 36px;
          font-size: 18px;
          font-weight: 300;
          line-height: 38px;
          color: $black2;
          cursor: pointer;
        }

        .nav-menu:hover {
          font-weight: bold;
        }

        .nav-menu:active {
          font-weight: bold;
        }

        .bold {
          font-weight: bold;
        }
      }

      .contents-container {
        display: inline-block;
        vertical-align: top;
        padding-left: 100px;
        padding-top: 3px;
        padding-bottom: 60px;
        flex: 1;

        @include respond-to(mobile) {
          padding-left: 0;
          padding-right: 0;
          padding-top: 0;
        }

        .rootone {
          @include respond-to(mobile) {
            padding-left: 30px;
            padding-right: 30px;
          }
        }

        .rootone-title {
          color: $black2;
          font-size: 24px;
          line-height: 1.42;
          font-weight: bold;

          @include respond-to(mobile) {
            font-size: 20px;
            line-height: 1.2;
          }
        }
        .content-title {
          font-size: 22px;
          color: $black2;
          margin-top: 40px;
          font-weight: bold;

          @include respond-to(mobile) {
            font-size: 16px;
            margin-top: 32px;
          }
        }
        .mission-desc {
          font-size: 16px;
          font-weight: lighter;
          color: #555555;
          line-height: 1.63;
          letter-spacing: -0.2px;
          margin-top: 12px;
          width: 520px;

          span {
            display: block;
          }

          @include respond-to(mobile) {
            font-size: 16px;
            line-height: 1.44;
            letter-spacing: -0.2px;
            width: 100%;

            span {
              display: inline;
            }
          }
        }

        .desktop-divider {
          max-width:760px;
          height:1px;
          background-color:#e1e1de;
          margin-right: 10px;
        }

        .mission-detail-container {
          display: flex;
          flex-direction: row;
          margin-top:20px;
          max-width:750px;
          width: 100%;

          @include respond-to(mobile) {
            flex-direction: column;
            padding: 24px;
            background-color: #fbfbf8;
            margin-top: 18px;
            box-sizing: border-box;
          }

          .mission-detail-item {
            flex: 1;

            @include respond-to(mobile) {
              display: flex;
              flex: none;
              flex-direction: row;
            }

            .mission-icon-container {
              position:relative;
              height:100px;

              @include respond-to(mobile) {
                display: inline-block;
                width: 40px;
                height: 40px;
              }

              .mission-icon {
                position:absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              .lock {
                width: 35px;
                height: 40px;

                @include respond-to(mobile) {
                  width: 17px;
                  height: 20px;
                }
              }

              .mobile {
                width:28px;
                height:46px;

                @include respond-to(mobile) {
                  width: 15px;
                  height: 16px;
                }
              }

              .swap {
                width:36px;
                height:33px;

                @include respond-to(mobile) {
                  width: 17px;
                  height: 15px;
                }
              }

              .card {
                width:38px;
                height:29px;

                @include respond-to(mobile) {
                  width: 20px;
                  height: 15px;
                }
              }

              .ppt {
                width:37px;
                height:40px;

                @include respond-to(mobile) {
                  width: 18px;
                  height: 20px;
                }
              }

            }

            .mission-detail-desc {
              font-size: 16px;
              font-weight: lighter;
              color: #555555;
              line-height: 1.44;
              letter-spacing: -0.2px;
              text-align: center;

              span {
                display: block;
              }

              @include respond-to(mobile) {
                display: inline-block;
                line-height: 40px;
                margin-left: 10px;

                span {
                  display: inline;
                }
              }
            }
          }
        }

        .history-div-container {
          margin-right: 10px;

          @include respond-to(mobile) {
            margin-right: 0;
          }
        }
        .history-container {
          margin-top: 24px;
          margin-bottom: 100px;
          border-top: 1px solid $black2;
          border-bottom: 1px solid $black2;
          max-width: 760px;
          width: 100%;
          border-collapse: collapse;

          @include respond-to(mobile) {
            margin-top: 12px;
            margin-bottom: 32px;
            margin-right: 0;
          }

          td {
            padding-top: 26px;
            padding-bottom: 26px;

            @include respond-to(mobile) {
              padding-top: 18px;
              padding-bottom: 18px;
            }
          }

          tr:not(:last-child) {
            border-bottom: 1px solid #e1e1de;
          }

          .year {
            font-size: 16px;
            color: $black2;
            font-weight: bold;
            width: 24%;

            @include respond-to(mobile) {
              width: 21%;
            }
          }

          .year-2018 {
            font-size: 16px;
            color: $black2;
            font-weight: bold;
            vertical-align: top;
            padding-top: 27px;
            width: 24%;

            @include respond-to(mobile) {
              padding-top: 19px;
              width: 21%;
            }
          }

          .month {
            font-size: 16px;
            color: $black2;
            font-weight: bold;
            max-width: 59px;
            width: 7%;

            @include respond-to(mobile) {
              width: 10%;
            }
          }

          .desc {
            font-size: 15px;
            color: $black2;
            font-weight: lighter;
          }
        }

        .mobile-divider {
          height: 7px;
          width: 100%;
          background-color: #f2f2ee;
        }

        .bi-ci-desktop {
          .title {
            color: $black2;
            font-size: 24px;
            line-height: 1.22;
            font-weight: 900;
            font-family: 'Apple SD Gothic Neo', sans-serif;
          }

          .bi-btn {
            display: inline-block;
            border: 1px solid $green;
            min-width: 100px;
            height: 38px;
            padding-left: 16px;
            padding-right: 16px;
            margin-right: 14px;
            margin-top: 24px;
            vertical-align: middle;
            float: left;
            color: $green;
            font-size: 16px;
            line-height: 40px;
            text-align: left;

            &:hover {
              background-color: $green;
              cursor: pointer;
              color: white;

              .download-g {
                display: none;
              }

              .download-w {
                display: inline;
              }
            }

            .download-g {
              float: right;
              width: 8px;
              height: 12px;
              top: 50%;
              position: relative;
              transform: translateY(-50%);
            }

            .download-w {
              float: right;
              width: 8px;
              height: 12px;
              top: 50%;
              position: relative;
              transform: translateY(-50%);
            }
          }

          .ci-btn {
            display: inline-block;
            border: 1px solid $black2;
            min-width: 100px;
            height: 38px;
            padding-left: 16px;
            padding-right: 16px;
            margin-right: 14px;
            margin-top: 24px;
            vertical-align: middle;
            float: left;
            color: $black2;
            font-size: 16px;
            line-height: 40px;
            text-align: left;

            &:hover {
              background-color: $black2;
              cursor: pointer;
              color: white;

              .download-b {
                display: none;
              }

              .download-w {
                display: inline;
              }
            }

            .download-b {
              float: right;
              width: 8px;
              height: 12px;
              top: 50%;
              position: relative;
              transform: translateY(-50%);
            }

            .download-w {
              display: none;
              float: right;
              width: 8px;
              height: 12px;
              top: 50%;
              position: relative;
              transform: translateY(-50%);
            }
          }
        }

        .bi-ci-mobile {
          padding: 40px 20px 46px 20px;

          @include respond-to(mobile) {
            padding: 40px 30px 46px 30px;
          }

          .bi-title {
            font-size: 17px;
            font-weight: 900;
            color: $green;
            font-family: 'Apple SD Gothic Neo', sans-serif;
          }

          .ci-title {
            font-size: 17px;
            font-weight: 900;
            color: $black2;
            font-family: 'Apple SD Gothic Neo', sans-serif;
            margin-top: 24px;
          }

          .logo {
            width: 100%;
            height: auto;
            margin-top: 10px;
          }

          .desc {
            color: #555555;
            font-size: 14px;
            font-weight: 300;
            margin-top: 16px;
          }
        }

        .service-api {
          @include respond-to(mobile) {
            margin: 0px;
            padding: 40px 30px 0 30px;
          }

          .title {
            font-size: 22px;
            font-weight: bold;
            color: $black2;
            padding-top: 4px;

            @include respond-to(mobile) {
              font-size: 16px;
            }
          }

          .service-btns {
            display: flex;
            flex-direction: row;

            @include respond-to(mobile) {
              width: 90%;
            }

            .service-btn {
              border: 1px solid $black2;
              width: 184px;
              height: 38px;
              padding-left: 16px;
              padding-right: 16px;
              float: left;
              color: $black2;
              font-size: 16px;
              line-height: 40px;
              text-align: left;

              @include respond-to(mobile) {
                width: 100px;
                height: 40px;
              }

              &:hover {
                background-color: #333333;
                cursor: pointer;
                color: white;

                .download-b {
                  display: none;
                }

                .download-w {
                  display: inline;
                }
              }

              .download-b {
                float: right;
                width: 8px;
                height: 12px;
                top: 50%;
                position: relative;
                transform: translateY(-50%);
              }

              .download-w {
                display: none;
                float: right;
                width: 8px;
                height: 12px;
                top: 50%;
                position: relative;
                transform: translateY(-50%);
              }
            }
          }

          .api-btn {
            display: inline-block;
            border: 1px solid $black2;
            width: 184px;
            height: 38px;
            padding-left: 16px;
            padding-right: 16px;
            float: left;
            color: $black2;
            font-size: 16px;
            line-height: 40px;
            text-align: left;

            @include respond-to(mobile) {
              width: 100px;
              height: 40px;
            }

            &:hover {
              background-color: #333333;
              cursor: pointer;
              color: white;

              .download-b {
                display: none;
              }

              .download-w {
                display: inline;
              }
            }

            .download-b {
              float: right;
              width: 8px;
              height: 12px;
              top: 50%;
              position: relative;
              transform: translateY(-50%);
            }

            .download-w {
              display: none;
              float: right;
              width: 8px;
              height: 12px;
              top: 50%;
              position: relative;
              transform: translateY(-50%);
            }
          }

          .desktop-divider {
            @include respond-to(mobile) {
              margin-top: 38px;
              margin-bottom: 32px;
              margin-right: 0;
            }
          }
        }
      }
    }

    .team-container {
      background-color: #f2f2ee;
      padding-top: 102px;
      width: 100%;

      @include respond-to(mobile) {
        padding-top: 28px;
        background-color: $green;
      }

      .sub-title {
        font-size: 22px;
        color: $black2;
        font-weight: lighter;
        text-align: center;
      }

      .title {
        font-size: 34px;
        color: $black2;
        font-weight: bold;
        margin-top: 12px;
        text-align: center;
        margin-bottom: 66px;

        @include respond-to(mobile) {
          font-size: 16px;
          color: #ffffff;
          letter-spacing: -0.1px;
          margin-bottom: 34px;
        }

        .arrow {
          width: 24px;
          height: 16px;
          display: inline-block;
          position: absolute;
          right: 25px;
        }
      }

      .photo-container {
        display: flex;
        flex-direction: row;
        margin: 0 auto;
        justify-content: center;
        max-width: 1086px;

        @include respond-to(mobile) {
          margin-left: 26px;
          margin-right: 26px;
        }

        .second-photo-div {
          @include respond-to(mobile) {
            margin-left: 0;
            margin-right: 0;
          }
        }

        .photo {
          width: 100%;
        }
      }
    }

    .team-container:hover {
      background-color: $green;
      cursor: pointer;

      label {
        color: #ffffff;
        cursor: pointer;
      }
    }

    @include desktop-page {
      .hide-on-pc {
        display: none !important;
      }
    }
    @include mobile-page {
      .hide-on-mobile {
        display: none !important;
      }
    }
  }
</style>