<template>
  <div id="airspot-quiz-box">
    <div class="white-box" :class="{'no-intro-image': !initialEvent.intro_image_url}">
      <div>
        <img class="header-logo" src="../images/airspot/image-logo@3x.png" alt="header logo">
        <hr class="green">
        <img class="coin-upper" src="../images/airspot/image-coins-upper@3x.png" alt="coin"/>
      </div>

      <div class="relative">
        <div class="flash-title" v-bind:style="eventStatusStyle">
          {{eventStatusMessage}}
        </div>
      </div>
      <div class="header-title" v-html="initialEvent.name"></div>

      <div class="coin-lower-wrapper">
        <img src="../images/airspot/image-coins-lower@3x.png" alt="coin">
        <h6 class="subtitle" style="margin-top: 6px">퀴즈를 풀고 에어드랍 이벤트에 참여해보세요!</h6>
      </div>

      <template v-if="initialEvent.intro_image_url">
        <div class="relative">
          <div class="intro-image-box">
            <template v-if="isImage">
              <img :src="initialEvent.intro_image_url" alt="introduction">
            </template>
            <template v-else>
              <div>
                <iframe width="100%" height="100%" :src="initialEvent.intro_image_url"
                        allow="autoplay; encrypted-media" allowfullscreen></iframe>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>

    <div class="green-box" :class="{'no-intro-image': !initialEvent.intro_image_url}">
      <div class="green-box-inner-wrapper">
        <section class="quiz">
          <img class="quiz-header" src="../images/airspot/image-quiz-paper-upper@3x.png" alt="">
          <h6 class="question">{{quizSheet.question}}</h6>
          <div class="btn-wrapper">
            <button class="quiz-btn" :class="{'green': clickedButtonNumber == o.number}" v-for="o in options" v-bind:key="o.number" @click="onButtonClicked(o.number)">
              {{o.text}}
            </button>
          </div>
        </section>

        <div class="privacy">
          <img src="../images/airspot/ic-check@3x.png" alt="">
          <p>
            개인정보 수집 이용 동의
          </p>
          <a href="#" @click.prevent="togglePrivacyCollectionShow">{{privacyCollectionShow ? '내용접기' : '내용보기'}}</a>
        </div>

        <ul v-if="privacyCollectionShow">
          <li>- 목적: 이벤트 안내, 참여 확인 및 코인/토큰 지급 안내, 고객 응대</li>
          <li>- 항목: 휴대폰 번호</li>
          <li>- 보유기간: 이벤트 종료일 기준 3개월 뒤 파기</li>
        </ul>

        <div class="privacy">
          <img src="../images/airspot/ic-check@3x.png" alt="">
          <p>
            개인정보 수집 제3자 제공 동의
          </p>
          <a href="#" @click.prevent="togglePrivacyThirdPartyCollectionShow">{{privacyThirdPartyCollectionShow ? '내용접기' : '내용보기'}}</a>
        </div>

        <ul v-if="privacyThirdPartyCollectionShow">
          <li>- 제공받는 자: 인포뱅크, 넥스모, 트윌리오</li>
          <li>- 제공받는 자의 개인정보 이용 목적: 휴대폰 문자 서비스</li>
          <li>- 항목: 휴대폰 번호</li>
          <li>- 보유기간: 이벤트 종료일 기준 6개월 뒤 파기</li>
        </ul>

        <p class="warning">
          - 코인 지급 시 2단계 인증이 필요할 수 있으며, 안내된 기간 내 인증을 하지 않으실 경우 코인 지급이 취소 될 수 있습니다.<br>
          - 부정 참여가 확인될 경우 당첨 취소 및 코인이 회수 될 수 있습니다.
        </p>

        <input class="phone-number-input" type="tel" ref="phone_number" v-model="airspotUser.phone_number"
               placeholder="휴대폰 번호를 입력하세요!"
               @keydown.enter="submit">
        <button @click.prevent="submit" class="submit-btn">{{ $t('airspot.agreeAndGetAirdrop') }}</button>

        <p class="check-phone-number-text">휴대폰번호가 맞는지 꼭 확인하세요!</p>
      </div>
    </div>

    <div class="open-chat-box" v-if="event.open_chat_url">
      <horizontal-bar text="프로젝트 참여하기"/>

      <h6 class="open-chat-room-encourage">
        오픈채팅방에 참여하고 코인에 대한 정보와 다양한 이벤트 정보를 실시간으로 받아보세요.
      </h6>

      <a :href="event.open_chat_url">
        <button class="enter-open-chat-room-btn">
          <img src="../images/airspot/ic-chatting@3x.png" alt="">
          <span>오픈채팅방 참여하기</span>
        </button>
      </a>
    </div>

    <div class="share-box">
      <div class="share-box-inner-wrapper">
        <horizontal-bar text="친구에게 공유하기!"></horizontal-bar>

        <ul class="share-items">
          <li class="share-item copy-btn" :data-clipboard-text="shareUrl">
            <img src="../images/airspot/ic-linkcopy@3x.png"
                 alt="copy">
          </li>
          <li class="share-item" @click.prevent="kakaotalk">
            <img src="../images/airspot/ic-kakaotalk@3x.png"
                 alt="kakaotalk">
          </li>
          <li class="share-item" @click.prevent="facebook">
            <img src="../images/airspot/ic-facebook@3x.png"
                 alt="facebook">
          </li>
        </ul>
      </div>
    </div>

    <phone-number-popup v-if="showPhoneNumberPopup"
                        :desc="phoneNumberPopupDesc"
                        @confirm="onPhoneNumberPopupConfirm"
                        @close="showPhoneNumberPopup = false"
    ></phone-number-popup>
    <share-popup v-if="showSharePopup"
                 :desc="sharePopupDesc"
                 :share-url="shareUrl"
                 :share-title="shareTitle"
                 :share-desc="shareDesc"
                 :share-image-url="shareImageUrl"
                 :share-kakao-image-url="shareKakaoImageUrl"
                 @close="showSharePopup = false"></share-popup>
    <event-finished-popup v-if="showEventFinishedPopup"
                          @close="showEventFinishedPopup = false"
                          desc="종료된 이벤트입니다."
    ></event-finished-popup>
    <event-finished-popup v-if="showEventSoldOutPopup"
                          @close="showEventSoldOutPopup = false"
                          desc="매진된 이벤트입니다."
    ></event-finished-popup>
    <toast ref="toast"></toast>
  </div>
</template>

<script>
  import axios from 'axios';
  import PhoneNumberPopup from './popups/phone_number_popup';
  import ClipboardJS from 'clipboard';
  import EventFinishedPopup from './popups/event_finished_popup';
  import Toast from './popups/toast';
  import HorizontalBar from './horizontal_bar';
  import BoxButton from './box_button';
  import SharePopup from './popups/share_popup';

  export default {
    props: ['currencyCode', 'initialAirspotUser', 'eventSoldOut', 'lang', 'eventHashId', 'shareUrl',
      'airspotUserPath', 'eventEnded', 'shareTitle', 'shareDesc', 'shareImageUrl',
      'shareKakaoImageUrl', 'currentDateTime', 'initialEvent', 'isImage', 'quizSheet'],
    components: {PhoneNumberPopup, EventFinishedPopup, Toast, HorizontalBar, BoxButton, SharePopup},
    data() {
      return {
        event: {...this.initialEvent},
        airspotUser: {...this.initialAirspotUser},
        options: [
          {number: 1, text: this.quizSheet && this.quizSheet.option1},
          {number: 2, text: this.quizSheet && this.quizSheet.option2},
          {number: 3, text: this.quizSheet && this.quizSheet.option3},
          {number: 4, text: this.quizSheet && this.quizSheet.option4},
        ],
        missionCompleted: false,
        eventStatusMessage: '',
        eventStatusStyle: 'display: none',
        eventAvailable: true,
        showPhoneNumberPopup: false,
        phoneNumberPopupDesc: '',
        showSharePopup: false,
        sharePopupDesc: '적립완료!\n문자 또는 앱 내 알림을 확인해보세요!',
        showEventFinishedPopup: this.eventEnded,
        showEventSoldOutPopup: this.eventSoldOut,
        phoneNumber: null,
        phoneNumberPopupType: 'share',
        isBusy: false,
        privacyCollectionShow: false,
        privacyThirdPartyCollectionShow: false,
        clickedButtonNumber: null,
      }
    },
    methods: {
      onButtonClicked(number) {
        let url = `/airspot/quizzes/${this.quizSheet.id}/solve.json`;
        let that = this;

        axios.get(url, {
          params: {'airspot_quiz_sheet[answer]': number},
          headers: {
            'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content,
            'Content-Type': 'application/json',
          }
        }).then(function (response) {
          console.log(response.data);
          that.clickedButtonNumber = number;

          if (response.data.solved) {
            that.showToast(
              that.$t('airspot.answerIsRight')
            );

            that.$refs.phone_number.focus();
          } else {
            that.showToast(that.$t('airspot.answerIsWrong'), '#ff0000');
          }
          that.missionCompleted = response.data.solved;
        }).catch(function (error) {
          console.log("ERROR: " + error);
        });
      },
      submit() {
        let that = this;
        if (!this.airspotUser.phone_number) {
          this.showToast('번호를 입력하세요.');
          return;
        }

        if (this.quizSheet && !this.missionCompleted) {
          this.showToast(this.$t('airspot.answerQuiz'));
          return;
        }

        if (this.isBusy) {
          return;
        } else {
          this.isBusy = true;
        }

        let url = `/airspot/events/${this.eventHashId}/airspot_users.json`;
        axios.post(url, {airspot_user: this.airspotUser}).
        then(
          function (response) {
            that.isBusy = false;
            that.airspotUser.phone_number = '';
            that.missionCompleted = false;
            that.showSharePopup = true;
            that.clickedButtonNumber = null;
            //alert("적립 완료!\n문자 메시지 또는 비트베리 앱 내 알림을 통해 내역을 확인하세요.");
          }).
        catch(function (error) {
          that.isBusy = false;
          that.clickedButtonNumber = null;
          alert(error.response.data.message);
        });
      },
      kakaotalk() {
        Kakao.Link.sendDefault({
          objectType: 'feed',
          content: {
            title: this.shareTitle,
            description: this.shareDesc,
            imageUrl: this.shareKakaoImageUrl,
            link: {
              webUrl: this.shareUrl,
              mobileWebUrl: this.shareUrl,
            }
          },
          buttons: [
            {
              title: '에어스팟 열기',
              link: {
                webUrl: this.shareUrl,
                mobileWebUrl: this.shareUrl,
              }
            },
          ]
        });
      },
      facebook() {
        FB.ui({
          method: 'share',
          href: this.shareUrl,
        }, function (response) {
        });
      },
      sendToGa() {
        gtag('event', 'ClickOpenChat', {'event_label': this.initialEvent.name});
      },
      updateEventStatus() {
        if (Date.now() > Date.parse(this.event.end_at)) {
          this.eventStatusMessage = this.$t('airspot.eventEnded');
          this.eventStatusStyle = 'background-color: #003f14';
          this.eventAvailable = false;
        } else if (this.eventSoldOut) {
          this.eventStatusMessage = this.$t('airspot.eventSoldOut');
          this.eventStatusStyle = 'background-color: #003f14';
          this.eventAvailable = false;
        } else if (Date.now() < Date.parse(this.event.start_at)) {
          this.eventStatusMessage = `${this.remainTime()}`;
          this.eventStatusStyle = 'background-color: #003f14';
          this.eventAvailable = false;
        } else {
          this.eventAvailable = true;
        }
      },
      remainTime() {
        let seconds = (Date.parse(this.event.start_at) - Date.now()) / 1000;
        let pad = function (x) {
          return (x < 10) ? "0" + x : x;
        };
        if (this.lang === 'ko') {
          return pad(parseInt(seconds / (60 * 60))) + "시간" +
            pad(parseInt(seconds / 60 % 60)) + "분" +
            pad(Math.ceil(seconds % 60)) + '초 후 시작'
        } else {
          return 'Start in ' + pad(parseInt(seconds / (60 * 60))) + ":" +
            pad(parseInt(seconds / 60 % 60)) + ":" +
            pad(Math.ceil(seconds % 60))
        }
      },
      togglePrivacyCollectionShow() {
        this.privacyCollectionShow = !this.privacyCollectionShow;
      },
      togglePrivacyThirdPartyCollectionShow() {
        this.privacyThirdPartyCollectionShow = !this.privacyThirdPartyCollectionShow;
      },
      showToast(desc, color) {
        this.$refs.toast.show(desc, color);
      },
    },
    mounted() {
      let that = this;
      let clipboard = new ClipboardJS('.copy-btn');
      clipboard.on('success', function (e) {
        e.clearSelection();
        that.$refs.toast.show('복사 완료');
      });

      setInterval(this.updateEventStatus, 1000);
    },
    created() {
      this.updateEventStatus();
    },
  }
</script>

<style scoped lang="scss">
  @import "../stylesheets/airspot";

  #airspot-quiz-box {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 0 50px 0;
    text-align: center;

    .relative {
      position: relative;
    }

    h1, h2, h3, h4, h5, h6, p {
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $green;
      margin: 0;
      font-family: $s-core-4;

      .bold {
        font-weight: bold;
      }
    }

    @mixin wrapper-attr {
      padding: 0 16px;
      box-sizing: border-box;
      margin: 0 auto;
      max-width: $content-wrapper-max-width;
      word-break: keep-all;
    }

    .white-box {
      @include wrapper-attr;
      background-color: $ivory;
      padding: 0 16px 115px 16px;

      &.no-intro-image {
        padding-bottom: 60px;
      }

      .header-logo {
        margin: 16px auto 6px auto;
        width: 90px;
      }

      hr.green {
        width: 100%;
        position: absolute;
        height: 1px;
        left: 0;
        border: 0;
        border-top: 1px solid $green;
      }

      .flash-title {
        max-width: $content-max-width;
        margin: 0 auto;
        position: absolute;
        top: 18px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        opacity: 0.8;
        border-radius: 5px;
        background-color: #003f14;
        font-family: $s-core-5;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        color: white;

        span {
          font-family: $s-core-5;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.17;
          letter-spacing: normal;
          text-align: center;
          color: white;

          &:first-of-type {
            color: white;
          }

          &:last-of-type {
            color: #00ff79;
            margin-left: 6px;
          }
        }
      }

      .coin-upper {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 59px;
        width: 288px;
        img {
          width: 100%;
        }
      }

      .header-title {
        margin-top: 69px;
        font-family: $jalnan;
        font-size: 27px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: center;
        color: $green;
        white-space: pre-wrap;
      }

      .subtitle {
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $green;
        margin: 0;
        font-family: $s-core-4;
        font-size: 14px;
        .bold {
          font-weight: bold;
        }
      }
      .coin-lower-wrapper {
        position: relative;
        margin: 0 auto;
        width: 288px;
        img {
          position: absolute;
          top: 0;
          left: 0;
          margin: 0 auto;
          width: 100%;
        }
      }

      .intro-image-box {
        position: absolute;
        top: 48px;
        margin: 0 auto;
        left: 0;
        right: 0;

        iframe {
          margin: 16px auto 0 auto;
          display: block;
          border-radius: 9px;
          -webkit-border-radius: 9px;
          -moz-border-radius: 9px;
          border: solid 4px #0e7a40;
          box-sizing: border-box;

          max-width: 288px;
          max-height: 192px;
          min-height: 150px;
          width: auto;
          height: auto;
        }
      }
    }

    .green-box {
      background-color: $green;
      padding: 126px 0 50px 0;

      &.no-intro-image {
        padding-top: 36px;
      }

      .green-box-inner-wrapper {
        @include wrapper-attr;

        section {
          &.quiz {
            display: block;
            margin: 0 auto;
            border-radius: 5px;
            background-color: #fbfbf8;
            max-width: 288px;
            text-align: center;
            padding-bottom: 10px;

            @include mobile-page{

            }

            .quiz-header {
              width: 288px;
              height: 57px;
              text-align: center;
              box-sizing: border-box;
              background-color: $green;
              margin: 0;
              border: none;
              padding: 0;
            }

            .btn-wrapper {
              margin: 16px auto;
              width: 288px;
              background-color: #fbfbf8;

              .quiz-btn {
                width: 256px;
                height: 44px;
                border-radius: 2px;
                border: solid 1px #e3e3e3;
                background-color: #ffffff;
                margin: 2px auto;
                font-family: $s-core-4, sans-serif;
                font-size: 14px;
              }

              .green {
                background-color: rgba(45, 162, 82, 0.15);
                color: $green;
              }
            }

            .question {
              font-family: $jalnan, sans-serif;
              font-size: 16px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: center;
              color: #2da252;
              margin: 0 auto;
              width: 256px;
            }
          }
        }

        .privacy {
          display: flex;
          align-items: center;
          margin-top: 10px;
          max-width: 256px;

          &:first-of-type {
            margin-top: 40px;
          }

          img {
            width: 9px;
            height: 8px;
          }

          p {
            margin-left: 6px;
            font-family: $s-core-5, sans-serif;
            font-size: 12px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: 0;
            color: #ffffff;
          }

          a {
            margin-left: 8px;
            font-family: $s-core-6, sans-serif;
            text-decoration: underline;
            font-size: 10px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #0e7a40;
          }
        }

        ul {
          li {
            list-style-type: none;
            text-indent: -25px;
            font-family: $s-core-4, sans-serif;
            text-align: left;
            font-size: 9px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.67;
            letter-spacing: normal;
            color: #ebebeb;
          }
        }

        p.warning {
          text-align: left;
          margin-top: 10px;
          opacity: 0.5;
          font-family: $s-core-4, sans-serif;
          font-size: 10px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.7;
          letter-spacing: normal;
          color: #ffffff;
        }

        .phone-number-input {
          width: 100%;
          height: 50px;
          border-radius: 2px;
          border: solid 1px #ffffff;
          margin: 15px auto 0 auto;
          display: block;
          box-sizing: border-box;
          font-size: 28px;
          line-height: normal;
          letter-spacing: 1.44px;
          caret-color: $green;
          color: $green;
          text-align: center;

          @include placeholder {
            opacity: 0.17;
            font-family: $s-core-3, sans-serif;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            text-align: center;
            color: #333333;
            line-height: 36px;
          }
        }

        .submit-btn, .share-and-get-cpt-more-btn {
          width: 100%;
          height: 50px;
          border-radius: 2px;
          background-color: #ffe000;
          border: none;
          display: block;
          margin: 8px auto 0 auto;

          font-family: $s-core-6, sans-serif;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0;
          color: $green;
          cursor: pointer;
        }

        .check-phone-number-text {
          font-family: $s-core-5, sans-serif;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.7;
          letter-spacing: normal;
          color: #ffffff;
          margin-top: 14px;
        }
      }
    }

    .open-chat-box {
      @include wrapper-attr;

      padding: 40px 24px;

      .open-chat-room-encourage {
        margin-top: 20px;
        font-family: $s-core-4;
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: center;
        color: #686868;
      }

      .enter-open-chat-room-btn {
        &:hover {
          cursor: pointer;
        }

        display: flex;
        margin-top: 10px;
        width: 100%;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        background-color: #2da252;
        border: none;

        img {
          width: 16px;
          height: 13px;
        }

        span {
          margin-left: 5px;
          font-family: $s-core-6;
          font-size: 12px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0;
          text-align: center;
          color: #ffffff;
          margin-top: 2px;
        }
      }
    }

    .share-box {
      padding: 40px 16px;
      background-color: #f2f2ee;

      .share-box-inner-wrapper {
        @include wrapper-attr;
        padding: 0 8px;

        .share-items {
          padding: 0;
          list-style: none;
          text-align: center;
          margin: 20px 0 -10px;

          .share-item {
            display: inline-block;
            margin-left: 24px;

            &:hover {
              cursor: pointer;
            }

            &:first-child {
              margin-left: 0;
            }

            img {
              width: 44px;
              height: 44px;
              display: block;
              margin: 0 auto;
            }

            span {
              font-size: 13px;
              font-weight: bold;
              color: $green;
              letter-spacing: -0.3px;
              margin-top: 6px;
              display: inline-block;
            }
          }
        }
      }
    }
  }
</style>

