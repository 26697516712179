<template>
  <div id="event">
    <global-nav-header/>

      <div class="event-one">
        <div class="content">
          <img src="../images/event/bitberry-event01.jpg" class="eventImg"/>
        </div>
      </div>

      <div class="event-two">
        <div class="content">
          <img src="../images/event/bitberry-event02.jpg" class="eventImg"/>
        </div>
      </div>

      <div class="event-three">
        <div class="content">
          <img src="../images/event/bitberry-event03.jpg" class="eventImg"/>
        </div>
      </div>

      <div class="event-four">
        <div class="content">
          <a href="#" v-on:click="showFinance">
            <img src="../images/event/bitberry-event-btn01.jpg" class="eventImg"/>
          </a>
        </div>
      </div>

      <div class="event-five">
        <div class="content">
          <img src="../images/event/bitberry-event04.jpg" class="eventImg"/>
        </div>
      </div>

      <div class="event-six">
        <div class="content">
          <a href="#" v-on:click="bitberryDownload">
            <img src="../images/event/bitberry-event-btn02.jpg" class="eventImg"/>
          </a>
        </div>
      </div>

      <div class="event-seven">
        <div class="content">
          <img src="../images/event/bitberry-event05.jpg" class="eventImg"/>
        </div>
      </div>

      <div class="event-eight">
        <div class="content">
          <img src="../images/event/bitberry-event06.jpg" class="eventImg"/>
        </div>
      </div>

      <div class="event-nine">
        <div class="content">
          <img src="../images/event/bitberry-event07.jpg" class="eventImg"/>
        </div>
      </div>

      <div class="event-ten">
        <div class="content">
          <img src="../images/event/bitberry-event08.jpg" class="eventImg"/>
        </div>
      </div>

      <div class="event-eleven">
        <div class="content">
          <img src="../images/event/bitberry-event09.jpg" class="eventImg"/>
        </div>
      </div>

      <div class="event-twelve">
        <div class="content">
          <a href="#" v-on:click="sodaplayDownload">
            <img src="../images/event/bitberry-event-btn03.jpg" class="eventImg"/>
          </a>
        </div>
      </div>

      <div class="event-thirteen">
        <div class="content">
          <img src="../images/event/bitberry-event10.jpg" class="eventImg"/>
        </div>
      </div>

      <div class="event-fourteen">
        <div class="content">
          <img src="../images/event/bitberry-event11.jpg" class="eventImg"/>
        </div>
      </div>

      <div class="event-fifteen">
        <div class="content">
          <img src="../images/event/bitberry-event12.jpg" class="eventImg"/>
        </div>
      </div>

      <div class="event-sixteen">
        <div class="content">
          <img src="../images/event/bitberry-event13.jpg" class="eventImg"/>
        </div>
      </div>
      <home-footer/>
  </div>
</template>

<script>
  import GlobalNavHeader from "./global_nav_header";
  import HomeFooter from "./home_footer";

  export default {
    components: {GlobalNavHeader, HomeFooter},
    props: [],
    computed: {},
    methods: {
        bitberryDownload: function() {
            var userAgent = window.navigator.userAgent;

            if (userAgent.match(/iPhone|iPad|iPod/)) {
                window.location.href = "https://itunes.apple.com/kr/app/id1411817291?l=ko&ls=1&mt=8";
            } else {
                window.location.href = "https://play.google.com/store/apps/details?id=com.rootone.wallet";
            }
        },
        sodaplayDownload: function() {
            var userAgent = window.navigator.userAgent;

            if (userAgent.match(/iPhone|iPad|iPod/)) {
                window.location.href = "https://itunes.apple.com/kr/app/id1480468235?l=ko&ls=1&mt=8";
            } else {
                window.location.href = "https://play.google.com/store/apps/details?id=com.skt.tmaphot";
            }
        },
        showFinance : function() {
          window.location.href="https://bbr.finance/";
        }
    },
    mounted() {
        window.onresize = () => {
            this.windowWidth = window.innerWidth;
        };
    },
  }
</script>

<style lang="scss">
  @import '../stylesheets/common';
  @import '../stylesheets/home';

  @mixin respond-to($media) {
    @if $media == tablet {
      @media only screen and (max-width: 1024px) { @content; }
    } @else if $media == mobile {
      @media only screen and (max-width: 720px) { @content; }
    }
  }

  @mixin desktop-page {
    @media only screen and (min-width: 721px) {
      @content;
    }
  }

  @include desktop-page {
    .hide-on-pc {
      display: none !important;
    }
  }
  @include respond-to(mobile) {
    .hide-on-mobile {
      display: none !important;
    }
  }

  body {
    overflow-y: scroll;
  }

  img {
    vertical-align: top;
  }

  #event {
    margin-top: 57px;
    font-family: 'Nanum Barun Gothic', sans-serif;

    .event-one {
      position: relative;
      width: 100%;
      height: 1148px;
      background: #f4fde8;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:750px;

          @include mobile-page {
            width:100%;
          }
        }
      }
    }

    .event-two {
      position: relative;
      width: 100%;
      height: 300px;
      background: #2da253;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:750px;

          @include mobile-page {
            width:100%;
          }
        }
      }
    }

    .event-three {
      position: relative;
      width: 100%;
      height: 1063px;
      background: #f4fde8;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:750px;

          @include mobile-page {
            width:100%;
          }
        }
      }
    }

    .event-four {
      position: relative;
      width: 100%;
      height: 190px;
      background: #f4fde8;
      background-size: auto;

      @include mobile-page {
        height: 130px;
      }

      .content {
        text-align: center;

        .eventImg{
          width:306px;

          @include mobile-page {
            width:40%;
          }
        }
      }
    }

    .event-five {
      position: relative;
      width: 100%;
      height: 317px;
      background: #2da253;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:750px;

          @include mobile-page {
            width:100%;
          }
        }
      }
    }

    .event-six {
      position: relative;
      width: 100%;
      height: 72px;
      background: #2da253;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:306px;

          @include mobile-page {
            width:40%;
          }
        }
      }
    }

    .event-seven {
      position: relative;
      width: 100%;
      height: 1471px;
      background: #2da253;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:750px;

          @include mobile-page {
            width:100%;
          }
        }
      }
    }

    .event-eight {
      position: relative;
      width: 100%;
      height: 1325px;
      background: #ffffff;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:750px;

          @include mobile-page {
            width:100%;
          }
        }
      }
    }

    .event-nine {
      position: relative;
      width: 100%;
      height: 1325px;
      background: #ffffff;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:750px;

          @include mobile-page {
            width:100%;
          }
        }
      }
    }

    .event-ten {
      position: relative;
      width: 100%;
      height: 1409px;
      background: #ffffff;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:750px;

          @include mobile-page {
            width:100%;
          }
        }
      }
    }

    .event-eleven {
      position: relative;
      width: 100%;
      height: 315px;
      background: #46c8bc;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:750px;

          @include mobile-page {
            width:100%;
          }
        }
      }
    }

    .event-twelve {
      position: relative;
      width: 100%;
      height: 72px;
      background: #46c8bc;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:306px;

          @include mobile-page {
            width:40%;
          }
        }
      }
    }

    .event-thirteen {
      position: relative;
      width: 100%;
      height: 1494px;
      background: #46c8bc;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:750px;

          @include mobile-page {
            width:100%;
          }
        }
      }
    }

    .event-fourteen {
      position: relative;
      width: 100%;
      height: 1312px;
      background: #ffffff;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:750px;

          @include mobile-page {
            width:100%;
          }
        }
      }
    }

    .event-fifteen {
      position: relative;
      width: 100%;
      height: 1324px;
      background: #ffffff;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:750px;

          @include mobile-page {
            width:100%;
          }
        }
      }
    }

    .event-sixteen {
      position: relative;
      width: 100%;
      height: 1367px;
      background: #ffffff;
      background-size: auto;

      @include mobile-page {
        height: auto;
      }

      .content {
        text-align: center;

        .eventImg{
          width:750px;

          @include mobile-page {
            width:100%;
          }
        }
      }
    }
  }
</style>