export default {
  ko: {
    limit: {
      title: '한도 안내',
      heading: '1일 송금 한도',
      level: '등급',
      limit: '한도',
      level1Amount: '500만원',
      level2Amount: '1억원',
      level3Amount: '10억원',
      description: `* 송금 한도는 보안 등급 기준으로 적용되며, 보안 등급 메뉴에서 등급을 올리실 수 있습니다.
        * 1일 송금 한도는 한국 시간 기준 매일 오전 9시에 초기화됩니다.
        * 송금 한도는 원화 환산 금액을 기준으로 적용되며, 암호화폐 송금 요청 시점에 적용됩니다.
        * 부정 거래가 의심되는 경우 암호화폐 입출금이 제한될 수 있습니다.
        * 비트베리 지갑으로 입금 한도는 무제한입니다.`
    },
    fee: {
      title: '수수료 안내',
      heading: '외부 주소로 송금시 코인별 수수료',
      coin: '코인명',
      fee: '송금 수수료 (VAT 포함)',
      free: '무료',
      description: `* 회원 간 송금, 휴대폰번호 송금은 수수료가 면제됩니다.
        * 송금 1건당 수수료가 발생하며 수수료는 송금 요청 시점에 암호화폐로 청구됩니다.
        * 수수료 정책은 회사의 정책에 따라 상시 달라질 수 있습니다.
        * 이더리움 기반 코인은 메인넷 가스비를 기준으로 실시간 변동됩니다. 과도한 송금 수수료 발생시 메인넷 가스비가 안정된 후 송금하시기 바랍니다.`,
    },
    guide: {
      title: '이용 가이드',
      heading: '안전한 암호화폐 지갑, 비트베리는 이렇게 사용할 수 있어요.',
      subTitle1: '하나. 가입은 카카오 계정만 있으면 끝!',
      desc1: '카카오계정으로 시작하고 폰 번호 인증을 하면 가입이 끝납니다.',
      subTitle2: '둘. 세상에서 제일 쉬운 지갑 만들기',
      desc2: '암호화폐를 찾아다니며 매번 가입하고 별도의 지갑 주소를 일일이 만들 필요가 없어요. 원하는 암호화폐를 선택만 하면 됩니다. 이제는 비트베리 앱 하나로 관리하세요.',
      subTitle3: '셋. 간편하고 안전한 송금 인증하기',
      desc3: '스마트폰 생체인식 기능을 통해 원터치로 간편하게, 보낼 금액에 따라 강화된 인증 방식으로 안전하게 송금을 할 수 있어요.',
      subTitle4: '넷. 전화번호로 쉽게 송금할 수 있어요.',
      desc4: '복잡한 암호화폐 주소는 더이상 필요 없어요. 보낼이의 전화번호를 입력만하면 쉽게 송금할 수 있습니다.'
    },
    airspot: {
      privacyPolicy: '개인정보 수집 이용 동의',
      needAgreement: '이벤트 참여를 위해서 아래 동의가 필요합니다.',
      inputPhoneNumber: '휴대폰번호를 입력하세요!',
      agreeAndGetAirdrop: '동의하고 즉시 에어드랍 받기',
      checkYourPhoneNumber: '휴대폰번호가 맞는지 꼭 확인하세요!',
      eventEnded: '종료된 이벤트입니다.',
      eventSoldOut: '매진된 이벤트입니다.',
      joinAirdropEvent: '퀴즈를 풀고 에어드랍 이벤트에 참여해보세요!',
      answerIsRight: '딩동댕!',
      answerIsWrong: '땡!',
      answerQuiz: '퀴즈의 정답을 맞춰주세요.',
      companyName: '(주)루트원소프트',
      contactEmail: '제휴 문의',
      dataPolicy: '이용약관 및 정보 처리방침',
      helpCenter: '고객 센터',
      eventPurpose: '목적: 이벤트 안내, 참여 확인 및 코인/토큰 지급 안내, 고객 응대',
      itemPhoneNumber: '항목: 휴대폰 번호',
      retention: '보유기간: 이벤트 종료일 기준 3개월 뒤 파기',
      disclosure: '개인정보 제3자 제공 동의',
      provideTo: '제공받는 자: 인포뱅크, 넥스모, 트윌리오',
      purposeOfUse: '제공받는 자의 개인정보 이용 목적: 휴대폰 문자 서비스',
      retention6: '보유기간: 이벤트 종료일 기준 6개월 뒤 파기',
      termOfService: '비트베리 서비스 이용약관',
      privacy: '개인정보 처리방침',
      confirmed: '확인했어요',
    },
    keypad: {
      desc1: '입력하신 핸드폰번호로 토큰이 적립됩니다.',
      desc2: '적립된 토큰은',
      desc3: '에서 확인할 수 있습니다.',
      bitberry: '비트베리',
      agreement: '이용약관과 개인정보 수집 및 이용에 동의하시면 핸드폰번호 입력후 받기 버튼을 누르세요.',
      receive: '%{amount} %{currencyCode} 토큰 받기'
    },
    escrow: {
      title: '비트베리 안전거래',
      selling_desc: '%{seller_name}님이 아래 토큰을 판매합니다.',
      buy: '구매하기',
      need_token_amount: '필요한 토큰',
      more_bitberry: '비트베리 더 알아보기',
      need_latest_version: '* 안전거래는 비트베리 최신 버전에서 사용할 수 있어요'
    }
  },
  en: {
    limit: {
      title: 'Transfer Limits',
      heading: 'Daily Transfer Limits',
      level: 'Level',
      limit: 'Transfer Limits',
      level1Amount: '500 USD',
      level2Amount: '10,000 USD',
      level3Amount: '10,000,000 USD',
      description: `* Your security level determines transfer limits. Please go to Security Level page to increase your limits.
        * Your daily tranfer limits are updated at 9 AM KST everyday
        * Your limits are applied at the time of request for transfer after the cryptocurrency is converted into USD
        * If fraudulent activities are suspected, we may impose deposits and withdrawal limitations.
        * Deposits are unlimited.`
    },
    fee: {
      title: 'Transaction Fees',
      heading: 'The transaction fee for transfers of cryptocurrency off the Bitberry platform',
      coin: 'Type',
      fee: 'Transaction Fee (VAT Included)',
      free: 'Free',
      description: `* There is no transaction fee for transferring cryptocurrency from one Bitberry wallet to another.
        * The transaction fee will be charged in cryptocurrency before transfer per transaction.
        * When you do not have enough ETH, the transaction fee will be charged in the type of token that you are transferring if the value of that token can be properly calculated.
        * Depending on Bitberry’s fee policy, for some ERC20 tokens, you may be charged in ETH instead of the token, regardless of its market value. (Applicable from June 10, 2019)`
    },
    guide: {
      title: 'How to use',
      heading: 'Bitberry, An easy and safe multi-cryptocurrency wallet',
      subTitle1: '1. Fast and simple sign-up',
      desc1: 'Start with your Kakao or Google account and phone number.',
      subTitle2: '2. Simplest way to create wallets',
      desc2: 'Bitberry is the simplest and easiest way and safeway to make cryptocurrency wallets. All you need to do is: just add! You don\'t have to worry about private keys or mnemonics anymore.',
      subTitle3: '3. Multi-factor authentication for transfers',
      desc3: 'Protect your wallets with a combination of passcodes, fingerprint and face recognition, KakaoPay(KR) or Google Authenticator.',
      subTitle4: '4. Make transaction only with a phone number',
      desc4: 'Bitberry makes it easy for you to make transaction. All you need is a recipient\'s phone number. '
    },
    airspot: {
      privacyPolicy: 'Privacy Policy',
      needAgreement: 'By using our service you agree to the following Terms of Service.',
      inputPhoneNumber: 'Enter your mobile phone number.',
      agreeAndGetAirdrop: 'Agree and receive airdrop now',
      checkYourPhoneNumber: 'Make sure your phone number includes your country code.',
      eventEnded: 'This event has ended.',
      eventSoldOut: 'This event has sold out.',
      joinAirdropEvent: 'Answer a quiz and get a free airdrop!',
      answerIsRight: 'Right!',
      answerIsWrong: 'Wrong!',
      answerQuiz: 'Please answer the quiz.',
      companyName: 'Rootone Corp',
      contactEmail: 'Partnership Email',
      dataPolicy: 'Data Policy',
      helpCenter: 'Help Center',
      eventPurpose: 'Purpose: Event Introduction, Confirmation of participation and Coin distribution, Customer Service',
      itemPhoneNumber: 'Item: Phone number',
      retention: 'Retention Period: After 3 months of the event.',
      disclosure: 'Disclosure of personal information to third parties',
      provideTo: 'Provide to: InfoBank',
      purposeOfUse: 'Purpose of use by the recipient: SMS service',
      retention6: 'Retention Period: After 6 months of the event.',
      termOfService: 'Terms of Use',
      privacy: 'Privacy Policy',
      confirmed: 'Confirmed!',
    },
    keypad: {
      desc1: "SMS will be sent to you with an URL of Bitberry's free airdrop",
      desc2: 'You can check your account balance in ',
      desc3: '',
      bitberry: 'Bitberry',
      agreement: 'Once you enter your phone number and click receive, you automatically agree with our terms of services and privacy policy',
      receive: 'Receive %{amount} %{currencyCode}'
    },
    escrow: {
      title: 'Bitberry Escrow',
      selling_desc: '%{seller_name} is selling the token below.',
      buy: 'Trade',
      need_token_amount: 'for',
      more_bitberry: 'More information about Bitberry',
      need_latest_version: '* Must be updated to its latest version to trade.'
    }
  }
}